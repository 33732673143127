import React, { Component } from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Hr, Span } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import { useState } from "react";
export default () => {
  const [counterOn, setCounterOn] = useState("false");
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"extra"} />
      <Helmet>
        <title>Black-Frames-Studio</title>
        <meta
          name={"description"}
          content={"Web site created using quarkly.io"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://uploads.quarkly.io/readme/cra/favicon-32x32.ico"}
          type={"image/x-icon"}
        />
      </Helmet>
      <Components.NavabarHeader />
      <Section
        background="--backgroundDark"
        position="static"
        z-index="0"
        sm-align-items="center"
        sm-justify-content="center"
        padding="0px 0 0px 0"
      >
        <Override
          slot="SectionContent"
          width="100%"
          height="340px"
          align-items="center"
          justify-content="center"
          margin="80px 0 80px 0"
          max-width="100%"
          padding="0px 80px 0px 80px"
          sm-padding="0px 12px 0px 12px"
          sm-margin="26px 0 26px 0"
          sm-height="max-content"
          md-padding="0px 40px 0px 40px"
          md-align-items="center"
          md-justify-content="center"
          md-margin="24px 0 24px 0"
          lg-padding="0px 40px 0px 40px"
          lg-margin="16px 0 16px 0"
          md-height="fit-content"
        />
        <Box
          min-height="100px"
          width="100%"
          display="grid"
          grid-template-columns="repeat(2, 1fr)"
          grid-gap="16px"
          height="100%"
          sm-grid-template-columns="repeat(1, 1fr)"
          md-align-items="center"
          md-justify-items="center"
          md-grid-template-columns="repeat(1, 1fr)"
          md-grid-gap="40px"
          sm-grid-gap="16px"
        >
          <Box
            display="flex"
            align-items="center"
            justify-content="center"
            sm-flex-direction="column"
            sm-margin="0px 0px 24px 0px"
            md-height="max-content"
            lg-width="400px"
          >
            <Text
              margin="0px 24px 0px 0px"
              font="--headline2"
              text-align="right"
              color="--primary"
              sm-font="--headline4"
              sm-margin="0px 24px 16px 0px"
              md-font="--headline3"
              md-min-height={0}
              md-min-width={0}
              lg-font="--headline3"
              id="0339239371"
              width="260px"
              sm-text-align="center"
            >
              <div>
                <ScrollTrigger
                  onEnter={() => setCounterOn(true)}
                  onExit={() => setCounterOn(false)}
                >
                  {counterOn && (
                    <CountUp start={0} end={700} duration={4} delay={0} />
                  )}
                  +
                </ScrollTrigger>
              </div>
              {/* 700+ */}
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              font="--p2"
              text-align="left"
              color="--darkL1"
              height="max-content"
              sm-font="--p4"
              sm-text-align="center"
              md-font="--p4"
              lg-font="--p4"
              id="3423413554"
              width="260px"
            >
              PROJECT COMPLETED
              <br />
              WITH 100% DEDICATION.
            </Text>
          </Box>
          <Box
            display="flex"
            align-items="center"
            justify-content="center"
            sm-flex-direction="column"
            sm-margin="0px 0px 24px 0px"
            md-height="max-content"
            lg-width="400px"
          >
            <Text
              margin="0px 24px 0px 0px"
              font="--headline2"
              color="--primary"
              sm-font="--headline4"
              sm-margin="0px 24px 16px 0px"
              md-font="--headline3"
              lg-font="--headline3"
              id="4444130875"
              width="260px"
              text-align="right"
              sm-text-align="center"
            >
              <div>
                <ScrollTrigger
                  onEnter={() => setCounterOn(true)}
                  onExit={() => setCounterOn(false)}
                >
                  {counterOn && (
                    <CountUp start={0} end={10} duration={4} delay={0} />
                  )}
                  +
                </ScrollTrigger>
              </div>
              {/* 10+ */}
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              font="--p2"
              text-align="left"
              color="--darkL1"
              height="max-content"
              sm-font="--p4"
              sm-text-align="center"
              md-font="--p4"
              lg-font="--p4"
              id="8895137710"
              width="260px"
            >
              PROESIONAL PHOTOGRAPHY <br />
              EXPRIENCE  .
            </Text>
          </Box>
          <Box
            display="flex"
            align-items="center"
            justify-content="center"
            sm-flex-direction="column"
            sm-margin="0px 0px 24px 0px"
            md-height="max-content"
            lg-width="400px"
          >
            <Text
              margin="0px 24px 0px 0px"
              font="--headline2"
              text-align="right"
              color="--primary"
              sm-font="--headline4"
              sm-margin="0px 24px 16px 0px"
              md-font="--headline3"
              lg-font="--headline3"
              width="260px"
              sm-text-align="center"
            >
              <div>
                <ScrollTrigger
                  onEnter={() => setCounterOn(true)}
                  onExit={() => setCounterOn(false)}
                >
                  {counterOn && (
                    <CountUp start={0} end={500} duration={4} delay={0} />
                  )}
                  +
                </ScrollTrigger>
              </div>
              {/* 500+ */}
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              font="--p2"
              text-align="left"
              color="--darkL1"
              height="max-content"
              sm-font="--p4"
              sm-text-align="center"
              md-font="--p4"
              lg-font="--p4"
              width="260px"
            >
              HAPPY CIENTS
              <br />
              AND COUNTING
            </Text>
          </Box>
          <Box
            display="flex"
            align-items="center"
            justify-content="center"
            sm-flex-direction="column"
            md-height="max-content"
            lg-width="400px"
          >
            <Text
              margin="0px 24px 0px 0px"
              font="--headline2"
              text-align="right"
              color="--primary"
              sm-font="--headline4"
              sm-margin="0px 24px 16px 0px"
              md-font="--headline3"
              lg-font="--headline3"
              width="260px"
              sm-text-align="center"
            >
              <div>
                <ScrollTrigger
                  onEnter={() => setCounterOn(true)}
                  onExit={() => setCounterOn(false)}
                >
                  {counterOn && (
                    <CountUp start={0} end={100} duration={4} delay={0} />
                  )}
                  k+
                </ScrollTrigger>
              </div>
              {/* 100k+ */}
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              font="--p2"
              text-align="left"
              color="--darkL1"
              height="max-content"
              sm-font="--p4"
              sm-text-align="center"
              md-font="--p4"
              lg-font="--p4"
              width="260px"
            >
              FOLLOWER ON
              <br /> INSTAGRAM
            </Text>
          </Box>
        </Box>
      </Section>
      <Section
        position="relative"
        align-items="center"
        justify-content="center"
        padding="0px 0 0px 0"
        sm-padding="0px 0px 0px 0px"
        md-padding="0px 0px 0px 0px"
      >
        <Override
          slot="SectionContent"
          margin="80px 80px 80px 80px"
          sm-margin="18px 20px 18px 20px"
          sm-align-items="center"
          sm-justify-content="center"
          lg-margin="16px auto 16px auto"
          sm-width="100%"
          md-margin="40px 0px 40px 0px"
        />
        <Box
          margin="0px 0px 0px 0px"
          sm-padding="0px 24px 0px 24px"
          md-min-height="max-content"
          md-margin="0px 0px 32px 0px"
          align-items="center"
          display="flex"
          flex-direction="column"
          justify-content="center"
        >
          <Text
            margin="0px 0px 6px 0px"
            font="--subheadline2"
            color="--dark"
            text-align="center"
            sm-font="--subheadline6"
            md-font="--subheadline5"
            lg-font="--subheadline5"
            id="5748486445"
            border-color="--color-secondery"
          >
            HOW WE WORK
          </Text>
          <Hr
            min-height={0}
            min-width="5%"
            margin="0px 0px 8px 0px"
            width="5%"
            background="--primaryGradient"
            height="2px"
            padding="0px 0px 2px 0px"
            border-radius="4px"
          />
          <Text
            margin="0px 0px 0px 0px"
            font="--subheadline3"
            text-align="center"
            sm-font="--subheadline4"
            md-font="--subheadline4"
            lg-font="--subheadline4"
            id="5803582221"
            display="none"
          >
            UNLEASHING THE POWER OF VISUAL STORYTELLING
          </Text>
        </Box>
        <Box
          grid-template-columns="repeat(6, 1fr)"
          sm-grid-template-columns="repeat(1, 1fr)"
          lg-margin="0px auto 0px auto"
          grid-gap="20px"
          margin="60px 0px 0px 0px"
          display="flex"
          overflow-x="scroll"
          align-items="center"
        >
          <Box
            flex-direction="column"
            justify-content="flex-start"
            padding="24px 24px 24px 24px"
            sm-padding="16px 16px 16px 16px"
            md-padding="8px 1px 8px 16px"
            md-margin="0px 0 0px 0px"
            lg-margin="0px 0 0px 0px"
            border-width="1px"
            border-style="solid"
            border-color="--color-dark"
            width="500px"
            align-items="flex-start"
            flex="0 0 auto"
          >
            <Text
              margin="0px 0px 14px 0px"
              font="--subheadline4"
              color="--secondery"
              sm-font="--subheadline5"
              md-font="--subheadline5"
              lg-font="--subheadline5"
              id="2937271862"
              border-color="--color-secondery"
            >
              CONCEPT
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              font="--p2"
              color="--grey"
              text-align="left"
              sm-font="--p5"
              md-font="--p4"
              lg-font="--p4"
              id="4522771128"
              letter-spacing="0.3px"
            >
              <Span
                overflow-wrap="normal"
                word-break="normal"
                white-space="normal"
                text-indent="0"
                text-overflow="clip"
                hyphens="manual"
                user-select="auto"
                pointer-events="auto"
                letter-spacing="0.5px"
              >
                Unleash the power of imagination and creativity with our
                exceptional conceptualization services. We thrive on turning
                ideas into visual masterpieces, ensuring every photograph
                resonates with a unique and captivating essence.
              </Span>
              <br />
            </Text>
          </Box>
          <Box
            margin="0px 0px 0px 0px"
            flex-direction="column"
            align-items="flex-start"
            justify-content="flex-start"
            padding="24px 24px 24px 24px"
            sm-padding="16px 16px 16px 16px"
            md-padding="8px 1px 8px 16px"
            md-margin="0px 0 0px 0px"
            lg-margin="0px 0 0px 0px"
            border-width="1px"
            border-style="solid"
            border-color="--color-dark"
            width="500px"
            flex="0 0 auto"
          >
            <Text
              margin="0px 0px 14px 0px"
              font="--subheadline4"
              color="--secondery"
              sm-font="--subheadline5"
              md-font="--subheadline5"
              lg-font="--subheadline5"
              id="6746111559"
            >
              PRODUCTION
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              font="--p2"
              color="--grey"
              text-align="left"
              sm-font="--p5"
              md-font="--p4"
              lg-font="--p4"
              id="7268375693"
              letter-spacing="0.3px"
            >
              <Span
                overflow-wrap="normal"
                word-break="normal"
                white-space="normal"
                text-indent="0"
                text-overflow="clip"
                hyphens="manual"
                user-select="auto"
                pointer-events="auto"
              >
                <Span
                  overflow-wrap="normal"
                  word-break="normal"
                  white-space="normal"
                  text-indent="0"
                  text-overflow="clip"
                  hyphens="manual"
                  user-select="auto"
                  pointer-events="auto"
                  letter-spacing="0.5px"
                >
                  Seamlessly orchestrate the entire production process with our
                  meticulous attention to detail. From planning and coordination
                  to on-set execution, our team ensures that every photoshoot
                  flows effortlessly, resulting in extraordinary and compelling
                  imagery.
                </Span>
              </Span>
              <br />
            </Text>
          </Box>
          <Box
            flex-direction="column"
            align-items="flex-start"
            justify-content="flex-start"
            padding="24px 24px 24px 24px"
            sm-padding="16px 16px 16px 16px"
            md-padding="8px 1px 8px 16px"
            md-margin="0px 0 0px 0px"
            lg-margin="0px 0 0px 0px"
            border-width="1px"
            border-style="solid"
            border-color="--color-dark"
            width="500px"
            flex="0 0 auto"
          >
            <Text
              margin="0px 0px 14px 0px"
              font="--subheadline4"
              color="--secondery"
              sm-font="--subheadline5"
              md-font="--subheadline5"
              lg-font="--subheadline5"
              id="6746111559"
            >
              PHOTOGRAPHY
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              font="--p2"
              color="--grey"
              text-align="left"
              sm-font="--p5"
              md-font="--p4"
              lg-font="--p4"
              id="7268375693"
              letter-spacing="0.3px"
            >
              <Span
                overflow-wrap="normal"
                word-break="normal"
                white-space="normal"
                text-indent="0"
                text-overflow="clip"
                hyphens="manual"
                user-select="auto"
                pointer-events="auto"
                letter-spacing="0.5px"
              >
                Embrace the artistry of our talented photographers, who
                skillfully wield their cameras to capture fleeting moments and
                transform them into timeless memories. With an eye for beauty
                and a passion for storytelling, they weave narratives through
                their lens.
              </Span>
            </Text>
          </Box>
          <Box
            margin="0px 0px 0px 0px"
            flex-direction="column"
            align-items="flex-start"
            justify-content="flex-start"
            padding="24px 24px 24px 24px"
            sm-padding="16px 16px 16px 16px"
            md-padding="8px 1px 8px 16px"
            md-margin="0px 0 0px 0px"
            lg-margin="0px 0 0px 0px"
            border-width="1px"
            border-style="solid"
            border-color="--color-dark"
            width="500px"
            flex="0 0 auto"
          >
            <Text
              margin="0px 0px 14px 0px"
              font="--subheadline4"
              color="--secondery"
              sm-font="--subheadline5"
              md-font="--subheadline5"
              lg-font="--subheadline5"
              id="6746111559"
            >
              POST PROCESSING
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              font="--p2"
              color="--grey"
              text-align="left"
              sm-font="--p5"
              md-font="--p4"
              lg-font="--p4"
              id="7268375693"
              letter-spacing="0.3px"
            >
              <Span
                overflow-wrap="normal"
                word-break="normal"
                white-space="normal"
                text-indent="0"
                text-overflow="clip"
                hyphens="manual"
                user-select="auto"
                pointer-events="auto"
                letter-spacing="0.5px"
              >
                Elevate your photographs to the pinnacle of perfection with our
                expert post-processing techniques. Our team of editing virtuosos
                meticulously refines each image, adding the finishing touches
                that breathe life into every frame.
              </Span>
            </Text>
          </Box>
        </Box>
      </Section>
      <Section
        position="relative"
        align-items="center"
        justify-content="center"
        padding="0px 0 0px 0"
        sm-padding="0px 0px 0px 0px"
        md-padding="0px 0px 0px 0px"
      >
        <Override
          slot="SectionContent"
          margin="80px 80px 80px 80px"
          sm-margin="18px 20px 18px 20px"
          sm-align-items="center"
          sm-justify-content="center"
          lg-margin="16px auto 16px auto"
          sm-width="100%"
          md-margin="40px 0px 40px 0px"
        />
        <Box
          margin="0px 0px 0px 0px"
          sm-padding="0px 24px 0px 24px"
          md-min-height="max-content"
          md-margin="0px 0px 32px 0px"
          align-items="center"
          display="flex"
          flex-direction="column"
          justify-content="center"
        >
          <Text
            margin="0px 0px 6px 0px"
            font="--subheadline2"
            color="--dark"
            text-align="center"
            sm-font="--subheadline6"
            md-font="--subheadline5"
            lg-font="--subheadline5"
            id="5748486445"
            border-color="--color-secondery"
          >
            HOW WE WORK
          </Text>
          <Hr
            min-height={0}
            min-width="5%"
            margin="0px 0px 8px 0px"
            width="5%"
            background="--primaryGradient"
            height="2px"
            padding="0px 0px 2px 0px"
            border-radius="4px"
          />
          <Text
            margin="0px 0px 0px 0px"
            font="--subheadline3"
            text-align="center"
            sm-font="--subheadline4"
            md-font="--subheadline4"
            lg-font="--subheadline4"
            id="5803582221"
            display="none"
          >
            UNLEASHING THE POWER OF VISUAL STORYTELLING
          </Text>
        </Box>
        <Box
          grid-template-columns="repeat(6, 1fr)"
          sm-grid-template-columns="repeat(1, 1fr)"
          lg-margin="0px auto 0px auto"
          grid-gap="20px"
          margin="60px 0px 0px 0px"
          display="flex"
          overflow-x="scroll"
          align-items="center"
        >
          <Box
            flex-direction="column"
            justify-content="flex-start"
            padding="24px 24px 24px 24px"
            sm-padding="16px 16px 16px 16px"
            md-padding="8px 1px 8px 16px"
            md-margin="0px 0 0px 0px"
            lg-margin="0px 0 0px 0px"
            border-width="1px"
            border-color="--color-dark"
            width="500px"
            align-items="flex-start"
            flex="0 0 auto"
            box-shadow="0 0px 12px 0px rgba(0, 0, 0, 0.24)"
            border-radius="14px"
          >
            <Text
              margin="0px 0px 14px 0px"
              font="--subheadline4"
              color="--secondery"
              sm-font="--subheadline5"
              md-font="--subheadline5"
              lg-font="--subheadline5"
              id="2937271862"
              border-color="--color-secondery"
              text-align="center"
            >
              CONCEPT
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              font="--p2"
              color="--grey"
              text-align="left"
              sm-font="--p5"
              md-font="--p4"
              lg-font="--p4"
              id="4522771128"
              letter-spacing="0.3px"
            >
              <Span
                overflow-wrap="normal"
                word-break="normal"
                white-space="normal"
                text-indent="0"
                text-overflow="clip"
                hyphens="manual"
                user-select="auto"
                pointer-events="auto"
                letter-spacing="0.5px"
                text-align="center"
              >
                Unleash the power of imagination and creativity with our
                exceptional conceptualization services. We thrive on turning
                ideas into visual masterpieces, ensuring every photograph
                resonates with a unique and captivating essence.
              </Span>
              <br />
            </Text>
          </Box>
          <Box
            margin="0px 0px 0px 0px"
            flex-direction="column"
            align-items="flex-start"
            justify-content="flex-start"
            padding="24px 24px 24px 24px"
            sm-padding="16px 16px 16px 16px"
            md-padding="8px 1px 8px 16px"
            md-margin="0px 0 0px 0px"
            lg-margin="0px 0 0px 0px"
            border-width="1px"
            border-color="--color-dark"
            width="500px"
            flex="0 0 auto"
            box-shadow="0 0px 12px 0px rgba(0, 0, 0, 0.24)"
            border-radius="14px"
          >
            <Text
              margin="0px 0px 14px 0px"
              font="--subheadline4"
              color="--secondery"
              sm-font="--subheadline5"
              md-font="--subheadline5"
              lg-font="--subheadline5"
              id="6746111559"
              text-align="center"
            >
              PRODUCTION
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              font="--p2"
              color="--grey"
              text-align="left"
              sm-font="--p5"
              md-font="--p4"
              lg-font="--p4"
              id="7268375693"
              letter-spacing="0.3px"
            >
              <Span
                overflow-wrap="normal"
                word-break="normal"
                white-space="normal"
                text-indent="0"
                text-overflow="clip"
                hyphens="manual"
                user-select="auto"
                pointer-events="auto"
              >
                <Span
                  overflow-wrap="normal"
                  word-break="normal"
                  white-space="normal"
                  text-indent="0"
                  text-overflow="clip"
                  hyphens="manual"
                  user-select="auto"
                  pointer-events="auto"
                  letter-spacing="0.5px"
                  text-align="center"
                >
                  Seamlessly orchestrate the entire production process with our
                  meticulous attention to detail. From planning and coordination
                  to on-set execution, our team ensures that every photoshoot
                  flows effortlessly, resulting in extraordinary and compelling
                  imagery.
                </Span>
              </Span>
              <br />
            </Text>
          </Box>
          <Box
            flex-direction="column"
            align-items="flex-start"
            justify-content="flex-start"
            padding="24px 24px 24px 24px"
            sm-padding="16px 16px 16px 16px"
            md-padding="8px 1px 8px 16px"
            md-margin="0px 0 0px 0px"
            lg-margin="0px 0 0px 0px"
            border-width="1px"
            border-color="--color-dark"
            width="500px"
            flex="0 0 auto"
            box-shadow="0 0px 12px 0px rgba(0, 0, 0, 0.24)"
            border-radius="14px"
          >
            <Text
              margin="0px 0px 14px 0px"
              font="--subheadline4"
              color="--secondery"
              sm-font="--subheadline5"
              md-font="--subheadline5"
              lg-font="--subheadline5"
              id="6746111559"
              text-align="center"
            >
              PHOTOGRAPHY
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              font="--p2"
              color="--grey"
              text-align="left"
              sm-font="--p5"
              md-font="--p4"
              lg-font="--p4"
              id="7268375693"
              letter-spacing="0.3px"
            >
              <Span
                overflow-wrap="normal"
                word-break="normal"
                white-space="normal"
                text-indent="0"
                text-overflow="clip"
                hyphens="manual"
                user-select="auto"
                pointer-events="auto"
                letter-spacing="0.5px"
                text-align="center"
              >
                Embrace the artistry of our talented photographers, who
                skillfully wield their cameras to capture fleeting moments and
                transform them into timeless memories. With an eye for beauty
                and a passion for storytelling, they weave narratives through
                their lens.
              </Span>
            </Text>
          </Box>
          <Box
            margin="0px 0px 0px 0px"
            flex-direction="column"
            align-items="flex-start"
            justify-content="flex-start"
            padding="24px 24px 24px 24px"
            sm-padding="16px 16px 16px 16px"
            md-padding="8px 1px 8px 16px"
            md-margin="0px 0 0px 0px"
            lg-margin="0px 0 0px 0px"
            border-width="1px"
            border-color="--color-dark"
            width="500px"
            flex="0 0 auto"
            box-shadow="0 0px 12px 0px rgba(0, 0, 0, 0.24)"
            border-radius="14px"
          >
            <Text
              margin="0px 0px 14px 0px"
              font="--subheadline4"
              color="--secondery"
              sm-font="--subheadline5"
              md-font="--subheadline5"
              lg-font="--subheadline5"
              id="6746111559"
              text-align="center"
            >
              POST PROCESSING
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              font="--p2"
              color="--grey"
              text-align="center"
              sm-font="--p5"
              md-font="--p4"
              lg-font="--p4"
              id="7268375693"
              letter-spacing="0.3px"
            >
              <Span
                overflow-wrap="normal"
                word-break="normal"
                white-space="normal"
                text-indent="0"
                text-overflow="clip"
                hyphens="manual"
                user-select="auto"
                pointer-events="auto"
                letter-spacing="0.5px"
              >
                Elevate your photographs to the pinnacle of perfection with our
                expert post-processing techniques. Our team of editing virtuosos
                meticulously refines each image, adding the finishing touches
                that breathe life into every frame.
              </Span>
            </Text>
          </Box>
        </Box>
        <Box min-width="100px" min-height="100px" margin="60px 0px 0px 0px">
          <Box display="flex" margin="0px 0px 10px 0px">
            <Text
              margin="0px 0px 0px 0px"
              font="--p2"
              width="280px"
              color="--grey"
              sm-font="--p4"
              sm-width="200px"
            >
              MUSE
            </Text>
            <Text margin="0px 10px 0px 0px" font="--p2" sm-font="--p4">
              AMY
            </Text>
          </Box>
          <Box display="flex" margin="0px 0px 10px 0px">
            <Text
              margin="0px 0px 0px 0px"
              font="--p2"
              width="280px"
              color="--grey"
              sm-font="--p4"
              sm-width="200px"
            >
              MAKE-UP ARTIST AND HAIR{"  "}
            </Text>
            <Text margin="0px 10px 0px 0px" font="--p2" sm-font="--p4">
              SYMONE NEILESON
            </Text>
          </Box>
          <Box display="flex" margin="0px 0px 10px 0px">
            <Text
              margin="0px 0px 0px 0px"
              font="--p2"
              width="280px"
              color="--grey"
              sm-font="--p4"
              sm-width="200px"
            >
              STYLIST{" "}
            </Text>
            <Text margin="0px 10px 0px 0px" font="--p2" sm-font="--p4">
              SURAJ SINGH
            </Text>
          </Box>
          <Box display="flex" margin="0px 0px 10px 0px">
            <Text
              margin="0px 0px 0px 0px"
              font="--p2"
              width="280px"
              color="--grey"
              sm-font="--p4"
              sm-width="200px"
            >
              WARDROBE{" "}
            </Text>
            <Text margin="0px 10px 0px 0px" font="--p2" sm-font="--p4">
              TROVE
            </Text>
          </Box>
          <Box display="flex" margin="0px 0px 10px 0px">
            <Text
              margin="0px 0px 0px 0px"
              font="--p2"
              width="280px"
              color="--grey"
              sm-font="--p4"
              sm-width="200px"
            >
              PHOTOGRAPHY{" "}
            </Text>
            <Text margin="0px 10px 0px 0px" font="--p2" sm-font="--p4">
              BLACK FRAME
            </Text>
          </Box>
          <Box display="flex">
            <Text
              margin="0px 0px 0px 0px"
              font="--p2"
              width="280px"
              color="--grey"
              sm-font="--p4"
              sm-width="200px"
            >
              LOCATION{" "}
            </Text>
            <Text margin="0px 10px 0px 0px" font="--p2" sm-font="--p4">
              BLACK FRAME STUDIO
            </Text>
          </Box>
        </Box>
      </Section>
      <RawHtml>
        <style place={"endOfHead"} rawKey={"6459d5984b38c40020065810"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
};
