import apis from "../../apis/testimonials";
export const getAllTestimonial = (payload) => {
  return async (dispatch) => {
    try {
      let payload = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
      };
      //   let { data } = await apis.user.getProject(payload);
      let { data } = await apis.getAllTestimonial(payload);

      console.log(data.data.data, "testimonal");
      if (data) dispatch(setTestimonal(data.data.data));
    } catch (error) {
      console.log(error);
    }
  };
};

export const setTestimonal = (data) => {
  return {
    type: "setTestimonal",
    payload: data,
  };
};

export const addSingleCourse = (data) => {
  console.log(data, "sumit");
  return {
    type: "ADD_SINGLE_Course",
    payload: data,
  };
};
