import React, { useEffect, useState } from "react";
import theme from "theme";
import {
  Theme,
  Span,
  Text,
  Hr,
  Box,
  Icon,
  Image,
  LinkBox,
  Section,
  Link,
} from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import Skeleton from "components/common/Skeleton";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, SocialMedia } from "@quarkly/components";
import * as Components from "components";
import { IoMdHeartEmpty, IoMdShare } from "react-icons/io";

import Footer from "./Footer";
import { useDispatch, useSelector } from "react-redux/es/exports";

import { useParams } from "react-router-dom";
import ContentUpdate from "../components/CMS/ContentUpdate";

import { getSubCategory } from "redux/Product/product-action";
export default () => {
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [selectedData, updateSelectedData] = useState("update");
  const BrandDetails = useSelector((state) => state.brandDetails.brandProfile);
  const [loading, setLoading] = useState(false);
  console.log(BrandDetails, "outside in");
  let project = useSelector((state) => state.userDetails.project);
  let data = useSelector((state) => state.cms.pages["Fashion"]);
  let userData = useSelector((state) => state.cms.userData);

  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);

  let SingleSubCategory = useSelector((state) => state.product.Subcategory);

  //-----------hide/show-------
  const { token, category } = useParams();

  if (token) {
    let cmsStyle = document.getElementsByClassName("hoverText");
    console.log(cmsStyle, "cmsStyle");
    for (let i = 0; i < cmsStyle.length; i++) {
      cmsStyle[i].onClick = function () {
        return false;
      };
      cmsStyle[i].classList.add("hoverText1");
    }
  }
  //----------------hide/show-----------

  useEffect(() => {
    setMounted(true);
    setLoading(true);

    async function mount() {
      let data = await dispatch(
        getSubCategory({
          categoryName: category.toUpperCase(),
        })
      );
      console.log(data, "chex");
      if (data.code === 200) {
        setLoading(false);
      }
    }
    mount();
  }, [dispatch, setMounted]);

  async function isSideBarOpen(id) {
    if (token) {
      await updateSelectedData(id);
      setIsShow(true);
      console.log(isShow, selectedData, id, "show");
    }
  }

  function closeSidebar(value) {
    setIsShow(value);
    updateSelectedData("");
    // selectedData = "";

    console.log(selectedData, "show");
  }

  function getFavicon(value) {
    return value
      ? value.favIcon
        ? process.env.REACT_APP_STORAGE_URL_2 + brandProfile.favIcon
        : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/logo.png"
      : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/logo.png";
  }
  const baseURL = process.env.REACT_APP_STORAGE_URL;
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index213"} />
      <Helmet>
        <title>Black-Frames-Studio</title>
        <meta
          name={"description"}
          content={"Web site created using quarkly.io"}
        />
        <link
          rel={"shortcut icon"}
          href={getFavicon(brandProfile)}
          type={"image/x-icon"}
        />
      </Helmet>
      {/* <Components.Nav>
        <Override slot="link3" />
        <Override slot="link4" />
        <Override slot="image" />
      </Components.Nav> */}
      <Components.NavabarHeader>
        <Override slot="link3" />
        <Override slot="link4" />
        <Override slot="image" />
      </Components.NavabarHeader>
      <Section
        background="--backgroundLight"
        padding="160px 0 0px 0"
        sm-padding="40px 0 80px 0"
        sm-margin-top="70px"
      >
        {loading ? (
          <Skeleton />
        ) : (
          <>
            <Box
              display="flex"
              align-items="center"
              justify-content="center"
              flex-direction="column"
              margin="40px 0px 40px 40px"
              sm-margin="0px 0px 20px 0px"
            >
              <Text
                margin="0px 0px 6px 0px"
                font="--subheadline4"
                color="--dark"
                text-align="center"
                sm-font="--subheadline6"
                lg-font="--subheadline5"
                id="9694374279"
                border-color="--color-secondery"
                className="hoverText"
              >
                <Span
                  font-size="40px"
                  overflow-wrap="normal"
                  word-break="normal"
                  white-space="normal"
                  text-indent="0"
                  text-overflow="clip"
                  hyphens="manual"
                  user-select="auto"
                  pointer-events="auto"
                  sm-font="--subheadline4"
                >
                  {category.toUpperCase()}
                </Span>
              </Text>
              <Hr
                min-width="5%"
                margin="0px 0px 8px 0px"
                width="5%"
                background="--primaryGradient"
                height="2px"
                min-height={0}
                padding="0px 0px 2px 0px"
                border-radius="4px"
              />
            </Box>
            <Box className="">
              <Box className="lg:grid md:grid lg:grid-cols-3 md:grid-cols-3 gap-4">
                {SingleSubCategory
                  ? SingleSubCategory.map((e, index) => (
                      <LinkBox
                        id="1148533771"
                        className=""
                        href={`/portfolio/${category.toLowerCase()}/${e.name
                          .replace(/ /g, "-")
                          .toLowerCase()}`}
                      >
                        <Box
                          height="fit-content"
                          width="100%"
                          position="relative"
                          margin="0px 0px 6px 0px"
                          overflow="hidden"
                        >
                          <Box
                            flex-direction="column"
                            justify-content="center"
                            padding="12px 12px 12px 12px"
                            background="linear-gradient(450deg,rgba(0, 0, 0, 0.4) 0%,rgba(0, 0, 0, 0.4) 100%) 0 0 no-repeat"
                            top="auto"
                            right="auto"
                            bottom="0px"
                            left="0px"
                            z-index="4"
                            position="absolute"
                            width="100%"
                            height="100%"
                            opacity="0"
                            hover-opacity="1"
                            transition="opacity 0.3s ease-in 0s"
                            // display="flex"
                            display="none"
                            align-items="center"
                          >
                            <Box display="flex">
                              <Box
                                display="flex"
                                justify-content="center"
                                align-items="center"
                                margin="0px 20px 0px 0px"
                              >
                                <Text
                                  margin="0px 4px 0px 0px"
                                  font="--subheadline5"
                                  color="--darkL1"
                                  text-align="left"
                                  sm-font="--subheadline6"
                                  md-font="--subheadline5"
                                  lg-font="--subheadline5"
                                  id="5748486445"
                                  border-color="--color-secondery"
                                  letter-spacing="4px"
                                  display="none"
                                >
                                  28
                                </Text>
                                <Icon
                                  category="io"
                                  icon={IoMdHeartEmpty}
                                  size="36px"
                                  color="--darkL1"
                                />
                              </Box>
                              <Hr
                                margin="0px 20px 0px 0px"
                                display="flex"
                                width="1px"
                                border-width="0 1px 0 0"
                                border-color="#8a8a8a"
                              />
                              <Icon
                                category="io"
                                icon={IoMdShare}
                                size="36px"
                                color="--darkL1"
                              />
                            </Box>
                          </Box>
                          <Image
                            src={process.env.REACT_APP_STORAGE_URL_2 + e.image}
                            display="block"
                            object-fit="cover"
                            margin="0px 0px 0px 0px"
                            height="530px"
                            width="100%"
                            className="hoverText zoom"
                          />
                        </Box>
                        <Text
                          margin="10px 0px 6px 0px"
                          sm-margin="10px 0px 40px 0px"
                          font="--subheadline5"
                          color="--dark"
                          text-align="left"
                          sm-font="--subheadline6"
                          md-font="--subheadline5"
                          lg-font="--subheadline5"
                          id="0940094372"
                          border-color="--color-secondery"
                          letter-spacing="4px"
                          className="hoverText"
                          onClick={() => isSideBarOpen("0940094372")}
                        >
                          {e.name}
                        </Text>
                      </LinkBox>
                    ))
                  : "no-data"}
              </Box>
            </Box>
          </>
        )}
      </Section>
      <Footer />
      {isShow ? (
        <ContentUpdate
          selectedElement={selectedData}
          isShow={isShow}
          closeSidebar={closeSidebar}
        />
      ) : (
        ""
      )}
      <RawHtml>
        <style place={"endOfHead"} rawKey={"6459d5984b38c40020065810"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
};
