import React, { useEffect, useState } from "react";
import Reveal from "react-reveal/Reveal";
import Slide from "react-reveal/Slide";
import theme from "theme";
import Skeleton from "components/common/Skeleton";
import {
  Theme,
  Image,
  Text,
  Box,
  Section,
  LinkBox,
  Link,
} from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import {
  getAllCategory,
  addSingleSubCategory,
} from "redux/Product/product-action";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, SocialMedia } from "@quarkly/components";
import * as Components from "components";

import Footer from "./Footer";
import { useDispatch, useSelector } from "react-redux/es/exports";

import { useParams } from "react-router-dom";
import ContentUpdate from "../components/CMS/ContentUpdate";

export default () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [selectedData, updateSelectedData] = useState("update");
  const BrandDetails = useSelector((state) => state.brandDetails.brandProfile);

  console.log(BrandDetails, "outside in");
  let project = useSelector((state) => state.userDetails.project);
  let data = useSelector((state) => state.cms.pages["Portfolio"]);
  let userData = useSelector((state) => state.cms.userData);
  let productData = useSelector((state) => state.product.product);
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  let allCategory = useSelector((state) => state.product.Category);

  //-----------hide/show-------
  const { token } = useParams();

  if (token) {
    let cmsStyle = document.getElementsByClassName("hoverText");
    console.log(cmsStyle, "cmsStyle");
    for (let i = 0; i < cmsStyle.length; i++) {
      cmsStyle[i].onClick = function () {
        return false;
      };
      cmsStyle[i].classList.add("hoverText1");
    }
  }
  //----------------hide/show-----------

  useEffect(() => {
    setLoading(true);

    async function mount() {
      let data = await dispatch(getAllCategory());

      if (data.code === 200) {
        setLoading(false);
      }
      console.log(data, "chex");
    }
    mount();
    console.log(data, "Home");
    setMounted(true);
  }, [dispatch, setMounted]);
  console.log(data, "outside");
  console.log(productData, "hii");

  console.log(allCategory, "portfolio-cat");

  async function isSideBarOpen(id) {
    if (token) {
      await updateSelectedData(id);
      setIsShow(true);
      console.log(isShow, selectedData, id, "show");
    }
  }

  function closeSidebar(value) {
    setIsShow(value);
    updateSelectedData("");
    // selectedData = "";
  }

  function getFavicon(value) {
    return value
      ? value.favIcon
        ? process.env.REACT_APP_STORAGE_URL_2 + brandProfile.favIcon
        : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/logo.png"
      : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/logo.png";
  }
  const baseURL = process.env.REACT_APP_STORAGE_URL;
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index21"} />
      <Helmet>
        <title>Black-Frames-Studio</title>
        <meta
          name={"description"}
          content={"Web site created using quarkly.io"}
        />
        <link
          rel={"shortcut icon"}
          href={getFavicon(brandProfile)}
          type={"image/x-icon"}
        />
      </Helmet>
      {/* <Components.Nav /> */}
      <Components.NavabarHeader />

      <Section
        position="relative"
        md-height="max-content"
        padding="0px 0 0px 0"
        className="cust-mt"
      >
        <Override
          slot="SectionContent"
          position="static"
          align-items="center"
          justify-content="center"
          height="500px"
          max-width="100%"
          width="100%"
          sm-margin="0px auto 0px auto"
          md-margin="20px auto 20px auto"
          md-height="300px"
          lg-height="400px"
          sm-height="350px"
        />

        <Image
          src={
            data
              ? data["2083663215"]
              : "https://images.unsplash.com/photo-1682686580433-2af05ee670ad?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
          }
          display="block"
          position="absolute"
          bottom="auto"
          top="0px"
          left="0px"
          right="auto"
          width="100%"
          z-index="-2"
          height="491.172px"
          object-fit="cover"
          sm-bottom="139.59555555555553px"
          sm-height="351.51944444444445px"
          sm-top="0px"
          md-bottom="179.059px"
          md-height="312.094px"
          md-top="0px"
          id="2083663215"
          lg-bottom="1.02878546258224px"
          lg-height="498.9421245374178px"
          lg-top="0px"
          className="hoverText filter-b5"
          onClick={() => isSideBarOpen("2083663215")}
        />
        <Box
          margin="90px 0px 90px 0px"
          md-min-height="max-content"
          md-margin="48px 0px 60px 0px"
          color="--darkL1"
        >
          <Text
            margin="0px 0px 6px 0px"
            font="--headline3"
            color="--secondery"
            text-align="center"
            sm-font="--subheadline2"
            md-font="--headline5"
            lg-font="--headline5"
            id="5172018544"
            className="hoverText"
            onClick={() => isSideBarOpen("5172018544")}
          >
            <Reveal effect="fadeInUp">
              {data ? data["5172018544"] : "Portfolio"}
            </Reveal>
          </Text>
          <Text
            margin="0px 20px 0px 20px"
            sm-margin="0px 10px 0px 10px"
            font="--subheadline4"
            text-align="center"
            sm-font="--subheadline12"
            md-font="--subheadline4"
            lg-font="--subheadline4"
            color="--darkL1"
            md-color="--darkL1"
            id="3565408458"
            className="hoverText"
            onClick={() => isSideBarOpen("3565408458")}
          >
            <Reveal>
              <Reveal effect="fadeInUp">
                {" "}
                {data ? data["3565408458"] : "Capturing Every precious moments"}
              </Reveal>
            </Reveal>
          </Text>
        </Box>
      </Section>

      <Section
        background="--backgroundLight"
        padding="80px 0 80px 0"
        md-padding="40px 0 40px 0"
      >
        {loading ? (
          <Skeleton />
        ) : (
          <>
            <Box
              display="grid"
              sm-display="block"
              grid-template-columns="repeat(3, 1fr)"
              grid-gap="40px"
              md-grid-gap="26px"
              sm-grid-template-columns="repeat(1, 1fr)"
              className="cust-w100 "
              // className="cust-grid"
            >
              {/* className={index % 2 == 0 ? "" : "cols-span-2"} */}
              {allCategory &&
              allCategory.result &&
              allCategory.result.length > 0
                ? allCategory.result.map((e, index) => (
                    <Box className="item">
                      <LinkBox
                        align-items="flex-start"
                        href={`/portfolio/${e.name.toLowerCase()}`}
                      >
                        <Box width="100%" height="360px" overflow="hidden">
                          <Image
                            src={[
                              process.env.REACT_APP_STORAGE_URL_2 + e.image,
                            ]}
                            display="block"
                            object-fit="cover"
                            width="100%"
                            height="360px"
                            margin="0px 0px 6px 0px"
                            object-position="50%10%"
                            className="hovereText zoom"
                          />
                        </Box>

                        <Text
                          margin="10px 0px 6px 0px"
                          sm-margin="10px 0px 40px 0px"
                          font="--subheadline5"
                          color="--dark"
                          text-align="left"
                          sm-font="--subheadline6"
                          md-font="--subheadline5"
                          lg-font="--subheadline5"
                          id="4433429294"
                          border-color="--color-secondery"
                          letter-spacing="4px"
                          className="hoverText"
                          onClick={() => isSideBarOpen("4433429294")}
                        >
                          {e.name}
                        </Text>
                      </LinkBox>
                    </Box>
                  ))
                : "no-data"}
            </Box>
          </>
        )}
      </Section>

      <Footer />
      {isShow ? (
        <ContentUpdate
          selectedElement={selectedData}
          isShow={isShow}
          closeSidebar={closeSidebar}
        />
      ) : (
        ""
      )}
      <RawHtml>
        <style place={"endOfHead"} rawKey={"6459d5984b38c40020065810"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
};
