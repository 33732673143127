import React, { useEffect, useState } from "react";
import Reveal from "react-reveal/Reveal";

import theme from "theme";
import Footer from "./Footer";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { createContact } from "redux/marcom/marcom-action";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import "swiper/swiper.min.css";
import { PhotoProvider, PhotoView } from "react-photo-view";
import {
  Theme,
  Text,
  Box,
  Image,
  Section,
  Strong,
  Icon,
  Button,
  Hr,
  LinkBox,
  Span,
  Input,
  Link,
} from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, SocialMedia } from "@quarkly/components";
import * as Components from "components";
import { MdStar } from "react-icons/md";

import { useDispatch, useSelector } from "react-redux/es/exports";

import { useParams } from "react-router-dom";
import ContentUpdate from "../components/CMS/ContentUpdate";

import { InstagramEmbed } from "react-social-media-embed";
import validator from "validator";
export default () => {
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [errPhone, setErrPhone] = useState("");
  const [errEmail, setErrEmail] = useState("");
  const [errName, setErrName] = useState("");
  const [selectedData, updateSelectedData] = useState("update");
  const BrandDetails = useSelector((state) => state.brandDetails.brandProfile);

  console.log(BrandDetails, "outside in");
  let project = useSelector((state) => state.userDetails.project);
  let data = useSelector((state) => state.cms.pages["Studio"]);
  let userData = useSelector((state) => state.cms.userData);
  let productData = useSelector((state) => state.product.product);
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);

  const handleClick = () => {
    const element = document.getElementById("contact");
    element.scrollIntoView();
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
    if (event.target.value) {
      setErrName("");
    }
  };

  const handlephoneChange = (event) => {
    setPhone(event.target.value);
    if (event.target.value) {
      if (event.target.value.length != 10) {
        if (/^\d*$/.test(event.target.value) === false) {
          setErrPhone("Please enter valid number!");
        } else {
          setErrPhone("Valid only 10 digit!");
        }
      } else {
        if (/^\d*$/.test(event.target.value)) {
          setErrPhone("");
        } else {
          setErrPhone("Please enter valid number!");
        }
      }
    }
  };

  const handleemailChange = (event) => {
    setEmail(event.target.value);
    if (event.target.value) {
      if (!validator.isEmail(event.target.value)) {
        setErrEmail("Invalid email address!");
      } else {
        setErrEmail("");
      }
    } else {
      if (validator.isEmail(event.target.value)) {
        setErrEmail("");
      } else {
        setErrEmail("Please enter email address!");
      }
    }
  };

  const handlemessageChange = (event) => {
    setMessage(event.target.value);
    if (event.target.value) {
      setErrMsg("");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (message == "") {
      setErrMsg("Please enter message!");
      return false;
    } else {
      setErrMsg("");
    }
    if (phone == "") {
      setErrPhone("Please enter phone!");
      return false;
    } else {
      setErrPhone("");
    }
    if (email == "") {
      setErrEmail("Please enter email!");
      return false;
    } else {
      if (!validator.isEmail(email)) {
        setErrEmail("Invalid email address!");
        return false;
      } else {
        setErrEmail("");
      }
    }
    if (name == "") {
      setErrName("Please enter name!");
      return false;
    } else {
      setErrName("");
    }
    let payload = {
      name: name,
      mobile: phone,
      email: email,
      description: message,
      type: "Studio",
    };
    if (errName === "" && errEmail === "" && errMsg === "" && errPhone === "") {
      let data = await dispatch(createContact(payload));
      console.log(data, "DATA");

      if (data.code === 200) {
        setEmail("");
        setMessage("");
        setName("");
        setPhone("");
        Swal.fire({
          // title: "",
          text: "Thank you for contacting us.",
          icon: "success",
        });

        console.log(data.code, "registercode");
      } else {
        // alert(data.message);
        Swal.fire({
          title: "Errow",
          text: "Please enter valid credential",
          icon: "error",
        });
        console.log(data.message, "datamessage");
      }
    }
  };

  //-----------hide/show-------
  const { token } = useParams();

  if (token) {
    let cmsStyle = document.getElementsByClassName("hoverText");
    console.log(cmsStyle, "cmsStyle");
    for (let i = 0; i < cmsStyle.length; i++) {
      cmsStyle[i].onClick = function () {
        return false;
      };
      cmsStyle[i].classList.add("hoverText1");
    }
  }
  //----------------hide/show-----------

  useEffect(() => {
    console.log(data, "Home");
    setMounted(true);
  }, [dispatch, setMounted]);
  console.log(data, "outside");
  console.log(productData, "hii");

  async function isSideBarOpen(id) {
    if (token) {
      await updateSelectedData(id);
      setIsShow(true);
      console.log(isShow, selectedData, id, "show");
    }
  }

  function closeSidebar(value) {
    setIsShow(value);
    updateSelectedData("");
    // selectedData = "";

    console.log(selectedData, "show");
  }

  function getFavicon(value) {
    return value
      ? value.favIcon
        ? process.env.REACT_APP_STORAGE_URL_2 + brandProfile.favIcon
        : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/logo.png"
      : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/logo.png";
  }
  const baseURL = process.env.REACT_APP_STORAGE_URL;
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index212"} />
      <Helmet>
        <title>Black-Frames-Studio</title>
        <meta
          name={"description"}
          content={"Web site created using quarkly.io"}
        />
        <link
          rel={"shortcut icon"}
          href={getFavicon(brandProfile)}
          type={"image/x-icon"}
        />
      </Helmet>
      {/* <Components.Nav /> */}
      <Components.NavabarHeader />
      {/* <Section
        position="relative"
        quarkly-title="hero"
        padding="106px 0 60px 0"
      >
        <Override
          slot="SectionContent"
          flex-direction="row"
          margin="40px auto 40px auto"
          align-items="center"
          justify-content="center"
          height="300px"
        />
        <Image
          display="block"
          position="absolute"
          width="100%"
          top="auto"
          left="auto"
          bottom="auto"
          right="auto"
          height="100%"
          object-fit="cover"
          src="https://nimbuscluster.blob.core.windows.net/server01/black-frames-studio/blackframes/image/studiobanner.jpg"
          background="linear-gradient(450deg,#ffdeae 0%,#cfb545 100%) 0 0 no-repeat"
          id="2083663215"
          className="hoverText"
          onClick={() => isSideBarOpen("2083663215")}
        />
        <Box
          min-width="100px"
          min-height="100px"
          position="absolute"
          display="flex"
          align-items="center"
          justify-content="center"
          flex-direction="row"
          width="100%"
          height="100%"
          z-index="-1"
          opacity="0.9"
          background="linear-gradient(450deg,rgba(0, 0, 0, 0.8) 0%,rgba(0, 0, 0, 0.8) 100%) 0 0 no-repeat"
        />
        <Box
          width="100%"
          display="flex"
          align-items="center"
          justify-content="space-between"
        >
          <Text
            margin="0px 0px 0px 0px"
            font="--headline5"
            height="max-content"
            color="--darkL1"
            display="flex"
            flex-direction="row"
            id="2455175645"
            className="hoverText"
            onClick={() => isSideBarOpen("2455175645")}
          >
            <Slide left>{data ? data["2455175645"] : "Studio"}</Slide>
          </Text>
          <Box
            width="50%"
            display="flex"
            flex-direction="column"
            height="max-content"
          >
            <Text
              margin="0px 0px 0px 0px"
              font="--p2"
              color="--darkL1"
              sm-font="--p5"
              sm-margin="0px 0px 16px 0px"
              id="3582416938"
              className="hoverText"
              onClick={() => isSideBarOpen("3582416938")}
            >
              <Reveal>
                <Slide right>
                  {" "}
                  {data
                    ? data["3582416938"]
                    : "Capturing Every precious moments"}
                </Slide>
              </Reveal>
            </Text>
          </Box>
        </Box>
      </Section> */}
      <Section
        position="relative"
        md-height="max-content"
        padding="0px 0 0px 0"
        id="8267400290"
        className="hoverText cust-mt"
        background="https://images.unsplash.com/flagged/photo-1576575856634-3b2477c9b960?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
      >
        <Override
          slot="SectionContent"
          position="static"
          align-items="center"
          justify-content="center"
          height="500px"
          max-width="100%"
          width="100%"
          sm-margin="0px auto 0px auto"
          md-margin="20px auto 20px auto"
          md-height="300px"
          sm-height="260px"
        />
        <Box
          sm-padding="0px 24px 0px 24px"
          md-min-height="max-content"
          md-margin="48px 0px 60px 0px"
          color="--darkL1"
        >
          <Text
            margin="0px 0px 22px 0px"
            font="--headline3"
            color="--secondery"
            text-align="center"
            sm-font="--subheadline2"
            md-font="--headline5"
            lg-font="--headline5"
            id="2455175645"
            className="hoverText"
            onClick={() => isSideBarOpen("2455175645")}
          >
            <Reveal effect="fadeInUp">
              {data ? data["2455175645"] : "Studio"}
            </Reveal>
          </Text>
          <Text
            margin="0px 20px 0px 20px"
            sm-margin="0px 10px 0px 10px"
            font="--subheadline4"
            text-align="center"
            sm-font="--subheadline12"
            md-font="--subheadline4"
            lg-font="--subheadline4"
            color="--darkL1"
            md-color="--darkL1"
            id="3582416938"
            className="hoverText"
            onClick={() => isSideBarOpen("3582416938")}
          >
            <Reveal>
              <Reveal effect="fadeInUp">
                {" "}
                {data ? data["3582416938"] : "Capturing Every precious moments"}
              </Reveal>
            </Reveal>
          </Text>
        </Box>
        <Image
          src="https://nimbuscluster.blob.core.windows.net/server01/black-frames-studio/blackframes/Black%20frames%20images/BFS7159.webp"
          display="block"
          position="absolute"
          bottom="-1.0431794466145643px"
          top="0px"
          left="0px"
          right="auto"
          width="100%"
          z-index="-2"
          height="501.0403494466146px"
          object-fit="cover"
          sm-bottom="229.43541924579327px"
          sm-height="261.7125807542067px"
          sm-top="0px"
          md-bottom="-1.1203673307291853px"
          md-height="341.1018473307292px"
          md-top="0px"
          id="7998298559"
          md-left="0px"
          md-right="auto"
          className="filter-b5"
        />
      </Section>
      <Section
        position="static"
        align-items="center"
        justify-content="center"
        padding="80px 0 60px 0"
        sm-padding="40px 0 60px 0"
      >
        <Override
          slot="SectionContent"
          sm-margin="8px auto 8px auto"
          sm-align-items="center"
          sm-justify-content="center"
          md-margin="24px auto 24px auto"
          lg-margin="16px auto 16px auto"
          display="flex"
          flex-direction="row"
        />
        <Box
          display="flex"
          justify-content="space-between"
          sm-flex-direction="column"
          className="cust-w100"
        >
          <Reveal effect="fadeInUp">
            <Box
              margin="0px 40px 0px 0px"
              sm-padding="0px 0px 0px 0px"
              md-min-height="max-content"
              md-margin="0px 26px 0px 0px"
              align-items="flex-start"
              display="flex"
              flex-direction="column"
              justify-content="center"
              width="100%"
              sm-width="100%"
              sm-margin="0px 26px 22px 0px"
            >
              <Text
                margin="0px 0px 12px 0px"
                font="--subheadline3"
                text-align="center"
                sm-font="--subheadline6"
                md-font="--subheadline4"
                lg-font="--subheadline4"
                id="1532116865"
                md-text-align="left"
                lg-text-align="left"
                className="hoverText"
                onClick={() => isSideBarOpen("1532116865")}
              >
                <Strong font="--subheadline2" sm-font="--subheadline6">
                  {data ? data["1532116865"] : "RENT OUR STUDIO"}
                </Strong>
                <Hr
                  margin="0px auto 0px auto"
                  height="2px"
                  padding="0px 0px 2px 0px"
                  background="--primaryGradient"
                  width="50%"
                />
              </Text>
              <Text
                margin="0px 0px 26px 0px"
                font="--p2"
                color="--grey"
                text-align="left"
                sm-font="--p5"
                md-font="--p4"
                lg-font="--p4"
                id="6206187872"
                className="hoverText"
                onClick={() => isSideBarOpen("6206187872")}
              >
                {data
                  ? data["6206187872"]
                  : "Welcome to our creative studio, a heaven designed for both photographers and videographers. We provide a host of amenities to enhance your creative experience."}
                <br />
              </Text>
              <Box display="flex" align-items="center" margin="0px 0px 6px 0px">
                <Icon
                  category="md"
                  icon={MdStar}
                  size="22px"
                  color="--primary"
                  margin="0px 8px 0px 0px"
                  sm-padding="3px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--p1"
                  color="--grey"
                  text-align="left"
                  sm-font="--p5"
                  md-font="--p4"
                  lg-font="--p4"
                  id="4603532927"
                  className="hoverText"
                  onClick={() => isSideBarOpen("4603532927")}
                >
                  <Strong
                    font="--subheadline7"
                    overflow-wrap="normal"
                    word-break="normal"
                    white-space="normal"
                    text-indent="0"
                    text-overflow="clip"
                    hyphens="manual"
                    user-select="auto"
                    pointer-events="auto"
                    color="--secondary"
                    sm-font="--subheadline12"
                  >
                    {data
                      ? data["4603532927"]
                      : "Spacious Shooting Areas: Our studio boasts ample shooting spaces to accommodate various setups and concepts."}
                  </Strong>
                </Text>
              </Box>
              <Box display="flex" align-items="center" margin="0px 0px 6px 0px">
                <Icon
                  category="md"
                  icon={MdStar}
                  size="22px"
                  color="--primary"
                  margin="0px 8px 0px 0px"
                  sm-padding="3px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--p1"
                  color="--grey"
                  text-align="left"
                  sm-font="--p5"
                  md-font="--p4"
                  lg-font="--p4"
                  id="2863863855"
                  className="hoverText"
                  onClick={() => isSideBarOpen("2863863855")}
                >
                  <Strong
                    font="--subheadline7"
                    color="--secondary"
                    sm-font="--subheadline12"
                  >
                    {data
                      ? data["2863863855"]
                      : "Professional Lighting Equipment: Access a wide range of high-quality lighting tools to achieve the perfect ambiance in your shots."}
                  </Strong>
                  <br />
                </Text>
              </Box>
              <Box display="flex" align-items="center" margin="0px 0px 6px 0px">
                <Icon
                  category="md"
                  icon={MdStar}
                  size="22px"
                  color="--primary"
                  margin="0px 8px 0px 0px"
                  sm-padding="3px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--p1"
                  color="--grey"
                  text-align="left"
                  sm-font="--p5"
                  md-font="--p4"
                  lg-font="--p4"
                  id="3506739759"
                  className="hoverText"
                  onClick={() => isSideBarOpen("3506739759")}
                >
                  <Strong
                    font="--subheadline7"
                    color="--secondary"
                    overflow-wrap="normal"
                    word-break="normal"
                    white-space="normal"
                    text-indent="0"
                    text-overflow="clip"
                    hyphens="manual"
                    user-select="auto"
                    pointer-events="auto"
                    sm-font="--subheadline12"
                  >
                    {data
                      ? data["3506739759"]
                      : "Versatile Backdrops: Choose from a diverse collection of backdrops to complement your creative vision."}
                  </Strong>
                </Text>
              </Box>
              <Box display="flex" align-items="center" margin="0px 0px 6px 0px">
                <Icon
                  category="md"
                  icon={MdStar}
                  size="22px"
                  color="--primary"
                  margin="0px 8px 0px 0px"
                  sm-padding="3px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--p1"
                  color="--grey"
                  text-align="left"
                  sm-font="--p5"
                  md-font="--p4"
                  lg-font="--p4"
                  id=""
                  className="hoverText"
                  onClick={() => isSideBarOpen("")}
                >
                  <Strong
                    font="--subheadline7"
                    overflow-wrap="normal"
                    word-break="normal"
                    white-space="normal"
                    text-indent="0"
                    text-overflow="clip"
                    hyphens="manual"
                    user-select="auto"
                    pointer-events="auto"
                    color="--secondary"
                    sm-font="--subheadline12"
                  >
                    Makeup and Dressing Room: A dedicated area for models and
                    clients to prepare and ensure they look their best.
                  </Strong>
                </Text>
              </Box>
              <Box display="flex" align-items="center" margin="0px 0px 6px 0px">
                <Icon
                  category="md"
                  icon={MdStar}
                  size="22px"
                  color="--primary"
                  margin="0px 8px 0px 0px"
                  sm-padding="3px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--p1"
                  color="--grey"
                  text-align="left"
                  sm-font="--p5"
                  md-font="--p4"
                  lg-font="--p4"
                  id=""
                  className="hoverText"
                  onClick={() => isSideBarOpen("")}
                >
                  <Strong
                    font="--subheadline7"
                    color="--secondary"
                    sm-font="--subheadline12"
                  >
                    High-speed Internet: Stay connected and effortlessly share
                    your creative journey with our fast internet connection.
                  </Strong>
                  <br />
                </Text>
              </Box>
              <Box display="flex" align-items="center" margin="0px 0px 6px 0px">
                <Icon
                  category="md"
                  icon={MdStar}
                  size="22px"
                  color="--primary"
                  margin="0px 8px 0px 0px"
                  sm-padding="3px"
                  sm-sm-padding="3px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--p1"
                  color="--grey"
                  text-align="left"
                  sm-font="--p5"
                  md-font="--p4"
                  lg-font="--p4"
                  id=""
                  className="hoverText"
                  onClick={() => isSideBarOpen("")}
                >
                  <Strong
                    font="--subheadline7"
                    color="--secondary"
                    overflow-wrap="normal"
                    word-break="normal"
                    white-space="normal"
                    text-indent="0"
                    text-overflow="clip"
                    hyphens="manual"
                    user-select="auto"
                    pointer-events="auto"
                    sm-font="--subheadline12"
                  >
                    On-site Assistance: Our friendly team is available to offer
                    guidance and support throughout your shoot.
                  </Strong>
                </Text>
              </Box>
              <Box
                display="flex"
                align-items="center"
                margin="0px 0px 26px 0px"
                sm-margin="0px 0px 22px 0px"
              >
                <Icon
                  category="md"
                  icon={MdStar}
                  size="22px"
                  color="--primary"
                  margin="0px 8px 0px 0px"
                  sm-padding="3px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--p1"
                  color="--grey"
                  text-align="left"
                  sm-font="--p5"
                  md-font="--p4"
                  lg-font="--p4"
                  id=""
                  className="hoverText"
                  onClick={() => isSideBarOpen("")}
                >
                  <Strong
                    font="--subheadline7"
                    color="--secondary"
                    overflow-wrap="normal"
                    word-break="normal"
                    white-space="normal"
                    text-indent="0"
                    text-overflow="clip"
                    hyphens="manual"
                    user-select="auto"
                    pointer-events="auto"
                    sm-font="--subheadline12"
                  >
                    Convenient Location: Our Studio is conveniently located for
                    easy accessibility.
                  </Strong>
                </Text>
              </Box>
              <Button
                Id="5748094947"
                color="--primary"
                font="--btn1"
                padding="14px 54px 14px 54px"
                sm-font="--p5"
                sm-display="flex"
                sm-flex-direction="row"
                sm-margin="0px 8px 0px 0px"
                md-display="block"
                width="fit-content"
                md-padding="14px 34px 14px 34px"
                sm-align-items="center"
                sm-justify-content="center"
                className="hoverText skinButton"
                id="9361033273"
                lg-padding="16px 24px 16px 24px"
                background="--backgroundDark"
                sm-padding="12px 34px 12px 34px"
                onClick={handleClick}
              >
                Rent
              </Button>
            </Box>
          </Reveal>
          <Reveal effect="fadeInUp">
            <Box
              width="100%"
              height="500px"
              display="flex"
              justify-content="flex-end"
              sm-width="100%"
              sm-height="228px"
              position="relative"
            >
              {/* <Box
                display="flex"
                margin="0px 0px 26px 0px"
                position="absolute"
                bottom="auto"
                height="56.538349060639874px"
                left="auto"
                right="74px"
                top="209px"
                width="286.23944382440476px"
                z-index="1"
                lg-bottom="auto"
                lg-left="auto"
                lg-right="121px"
                lg-top="268px"
                md-bottom="auto"
                md-left="auto"
                md-right="56px"
                md-top="274px"
                sm-bottom="136.15384615384616px"
                sm-left="205.8px"
                sm-right="137.46153846153845px"
                sm-top="459.663px"
                sm-height="54.38365384615385px"
                sm-width="232.73846153846154px"
                padding="0px 0px 0px 8px"
              >
                <Icon
                  category="md"
                  icon={MdLocationOn}
                  size="42px"
                  color="--red"
                  margin="4px 2px 0px 0px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--p41"
                  color="--dark"
                  text-align="left"
                  sm-font="--p3"
                  md-font="--p4"
                  lg-font="--p4"
                  id="7692935729"
                  background="--color-darkL1"
                  display="flex"
                  align-items="center"
                  padding="0px 0px 0px 12px"
                  className="hoverText"
                >
                  Black Frame Studio 456 Elm Avenue Townsville, ABC 123
                </Text>
              </Box> */}
              {/* <Image
                src="https://uploads.quarkly.io/649bdc8a8bd7e600185a1390/images/Screenshot%202023-07-11%20165958.png?v=2023-07-11T11:30:47.154Z"
                display="block"
                height="100%"
                width="500px"
                object-fit="cover"
                border-radius="0px"
                lg-width="100%"
                sm-border-radius="0px"
                position="relative"
              /> */}
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7538.703221945182!2d72.82672353006636!3d19.136080229992732!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b73ab030530d%3A0x281a1a6299fc5dc9!2sBlack%20Frames%20Studios!5e0!3m2!1sen!2sin!4v1691127354597!5m2!1sen!2sin"
                style={{ border: 0 }}
                loading="lazy"
                className="iframe"
              ></iframe>
            </Box>
          </Reveal>
        </Box>
      </Section>
      <Section
        padding="60px 80px 80px 80px"
        margin="0 0 0 0"
        sm-align-items="center"
        sm-justify-content="center"
        lg-padding="60px 18px 18px 18px"
        md-padding="50px 18px 18px 18px"
        sm-padding="64px 18px 18px 18px"
      >
        <Override
          slot="SectionContent"
          width="100%"
          max-width="100%"
          className="cust-w100"
        />
        <Reveal>
          <Box
            margin="0px 0px 28px 0px"
            sm-padding="0px 24px 0px 24px"
            md-min-height="max-content"
            md-margin="0px 0px 32px 0px"
            padding="0px 24px 0px 24px"
            display="flex"
            align-items="center"
            flex-direction="column"
          >
            <Text
              margin="0px 0px 8px 0px"
              font="--subheadline2"
              color="--dark"
              text-align="center"
              sm-font="--subheadline6"
              md-font="--subheadline5"
              lg-font="--subheadline5"
              id="3590032521"
              className="hoverText"
              onClick={() => isSideBarOpen("3590032521")}
            >
              {data ? data["3590032521"] : "BLACK FRAMES STUDIO"}
            </Text>
            <Hr
              margin="0px 0px 0px 0px"
              height="2px"
              padding="0px 0px 2px 0px"
              background="--primaryGradient"
              width="80px"
            />
          </Box>
        </Reveal>
        <Reveal>
          <PhotoProvider className="cust-mt5rem ">
            <Box
              max-width="100%"
              display="grid"
              grid-template-columns="repeat(4, 1fr)"
              grid-template-rows="repeat(2, 1fr)"
              sm-grid-template-columns="repeat(1, 1fr)"
              sm-grid-template-rows="repeat(6, 1fr)"
              md-grid-template-rows="repeat(4, 1fr)"
              height="fit-content"
              grid-gap="18px"
              className="cust-w100"
            >
              <PhotoView
                src={
                  data
                    ? data["9820542626"]
                    : "https://images.unsplash.com/photo-1612242879330-cd06b2696e56?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
                }
              >
                <LinkBox
                  grid-column="1/ span 1"
                  grid-row="1/ span 1"
                  position="relative"
                  sm-grid-column="1/ span 2"
                  id="4905036638"
                  height="350px"
                  md-height="240px"
                  sm-height="200px"
                  width="100%"
                  overflow="hidden"
                >
                  <Image
                    src={
                      data
                        ? data["9820542626"]
                        : "https://images.unsplash.com/photo-1612242879330-cd06b2696e56?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
                    }
                    display="block"
                    width="100%"
                    height="100%"
                    object-fit="cover"
                    border-radius="0px"
                    className="hoverText zoom"
                    onClick={() => isSideBarOpen("9820542626")}
                    id="9820542626"
                  />
                </LinkBox>
              </PhotoView>

              <PhotoView
                src={
                  data
                    ? data["9270844583"]
                    : "https://images.unsplash.com/photo-1603425013520-e0b30e6e37dc?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
                }
              >
                <LinkBox
                  grid-column="2/ span 2"
                  grid-row="1/ span 1"
                  position="relative"
                  sm-grid-column="auto / auto"
                  sm-grid-row="auto / auto"
                  sm-align-items="center"
                  sm-display="flex"
                  sm-justify-content="center"
                  sm-margin="0px 0px 0px 0px"
                  md-grid-column="2/ span 4"
                  id="7976884335"
                  height="350px"
                  md-height="240px"
                  sm-height="200px"
                  overflow="hidden"
                >
                  <Image
                    src={
                      data
                        ? data["9270844583"]
                        : "https://images.unsplash.com/photo-1603425013520-e0b30e6e37dc?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
                    }
                    display="block"
                    width="100%"
                    object-fit="cover"
                    height="100%"
                    border-radius="0px"
                    className="hoverText zoom"
                    onClick={() => isSideBarOpen("9270844583")}
                    id="9270844583"
                  />
                </LinkBox>
              </PhotoView>

              <PhotoView
                src={
                  data
                    ? data["1425521573"]
                    : "https://images.unsplash.com/photo-1664806223123-c3d01da422da?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
                }
              >
                <LinkBox
                  grid-column="4/ span 1"
                  grid-row="1/ span 1"
                  position="relative"
                  sm-grid-column="1/ span 2"
                  sm-grid-row="3/ span 1"
                  md-grid-column="1/ span 2"
                  md-grid-row="2/ span 1"
                  id="7255625391"
                  height="350px"
                  md-height="240px"
                  sm-height="200px"
                  overflow="hidden"
                >
                  <Image
                    src={
                      data
                        ? data["1425521573"]
                        : "https://images.unsplash.com/photo-1664806223123-c3d01da422da?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
                    }
                    display="block"
                    width="100%"
                    height="100%"
                    object-fit="cover"
                    border-radius="0px"
                    className="hoverText zoom"
                    onClick={() => isSideBarOpen("1425521573")}
                    id="1425521573"
                  />
                </LinkBox>
              </PhotoView>

              <PhotoView
                src={
                  data
                    ? data["3044609476"]
                    : "https://images.unsplash.com/photo-1502920917128-1aa500764cbd?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
                }
              >
                <LinkBox
                  grid-column="1/span 1"
                  grid-row="2/ span 1"
                  position="relative"
                  sm-grid-column="1/ span 2"
                  sm-grid-row="4 /span 1"
                  md-grid-column="3/ span 2"
                  md-grid-row="2/ span 2"
                  id="9022543077"
                  height="350px"
                  md-height="240px"
                  overflow="hidden"
                  sm-height="200px"
                >
                  <Image
                    src={
                      data
                        ? data["3044609476"]
                        : "https://images.unsplash.com/photo-1502920917128-1aa500764cbd?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
                    }
                    display="block"
                    width="100%"
                    object-fit="cover"
                    height="100%"
                    border-radius="0px"
                    className="hoverText zoom"
                    onClick={() => isSideBarOpen("3044609476")}
                    id="3044609476"
                  />
                </LinkBox>
              </PhotoView>

              <PhotoView
                src={
                  data
                    ? data["1472089232"]
                    : "https://images.unsplash.com/photo-1598006839649-5588feb1bae0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
                }
              >
                <LinkBox
                  grid-column="2/ span 1"
                  grid-row="2/ span 1"
                  position="relative"
                  sm-grid-column="1/span 2"
                  sm-grid-row="5 /span 1"
                  md-grid-column="1/span 4"
                  md-grid-row="3 /span 1"
                  id="2968682648"
                  height="350px"
                  md-height="240px"
                  overflow="hidden"
                  sm-height="200px"
                >
                  <Image
                    src={
                      data
                        ? data["1472089232"]
                        : "https://images.unsplash.com/photo-1598006839649-5588feb1bae0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
                    }
                    display="block"
                    width="100%"
                    height="100%"
                    object-fit="cover"
                    border-radius="0px"
                    className="hoverText zoom"
                    onClick={() => isSideBarOpen("1472089232")}
                    id="1472089232"
                  />
                </LinkBox>
              </PhotoView>

              <PhotoView
                src={
                  data
                    ? data["5424455560"]
                    : "https://images.unsplash.com/photo-1614779603758-8d6d2b240e39?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
                }
              >
                <LinkBox
                  background="--primaryGradient"
                  grid-column="3/ span 2"
                  grid-row="2/ span 1"
                  position="relative"
                  sm-grid-column="1 /span 2"
                  sm-grid-row="6/span 1"
                  md-grid-column="1 / span 4"
                  id="9028491335"
                  height="350px"
                  md-background="url() 0 0 no-repeat"
                  md-grid-row="4 / span 1 "
                  md-height="240px"
                  overflow="hidden"
                  sm-height="200px"
                >
                  <Image
                    src={
                      data
                        ? data["5424455560"]
                        : "https://images.unsplash.com/photo-1614779603758-8d6d2b240e39?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
                    }
                    display="block"
                    width="100%"
                    height="100%"
                    object-fit="cover"
                    border-radius="0px"
                    className="hoverText zoom"
                    onClick={() => isSideBarOpen("5424455560")}
                    id="5424455560"
                  />
                </LinkBox>
              </PhotoView>
            </Box>
          </PhotoProvider>
        </Reveal>
        <Reveal>
          <PhotoProvider className="cust-mt5rem ">
            <Box
              max-width="100%"
              display="grid"
              grid-template-columns="repeat(4, 1fr)"
              grid-template-rows="repeat(2, 1fr)"
              sm-grid-template-columns="repeat(1, 1fr)"
              sm-grid-template-rows="repeat(6, 1fr)"
              md-grid-template-rows="repeat(4, 1fr)"
              height="fit-content"
              grid-gap="18px"
              className="cust-w100 mt-4"
            >
              <PhotoView src="https://nimbuscluster.blob.core.windows.net/server01/black-frames-studio/blackframes/s7.jpg">
                <LinkBox
                  grid-column="1/ span 1"
                  grid-row="1/ span 1"
                  position="relative"
                  sm-grid-column="1/ span 2"
                  id="4905036638"
                  height="350px"
                  md-height="240px"
                  sm-height="200px"
                  overflow="hidden"
                  width="100%"
                >
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/black-frames-studio/blackframes/s7.jpg"
                    display="block"
                    width="100%"
                    height="100%"
                    object-fit="cover"
                    border-radius="0px"
                    className="hoverText zoom"
                    onClick={() => isSideBarOpen("9820542626")}
                    id="9820542626"
                  />
                </LinkBox>
              </PhotoView>

              <PhotoView src="https://nimbuscluster.blob.core.windows.net/server01/black-frames-studio/blackframes/s8.jpg">
                <LinkBox
                  grid-column="2/ span 2"
                  grid-row="1/ span 1"
                  position="relative"
                  sm-grid-column="auto / auto"
                  sm-grid-row="auto / auto"
                  sm-align-items="center"
                  sm-display="flex"
                  sm-justify-content="center"
                  sm-margin="0px 0px 0px 0px"
                  md-grid-column="2/ span 4"
                  id="7976884335"
                  height="350px"
                  md-height="240px"
                  overflow="hidden"
                  sm-height="200px"
                >
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/black-frames-studio/blackframes/s8.jpg"
                    display="block"
                    width="100%"
                    object-fit="cover"
                    height="100%"
                    border-radius="0px"
                    className="hoverText zoom"
                    onClick={() => isSideBarOpen("9270844583")}
                    id="9270844583"
                  />
                </LinkBox>
              </PhotoView>

              <PhotoView src="https://nimbuscluster.blob.core.windows.net/server01/black-frames-studio/blackframes/s9.jpg">
                <LinkBox
                  grid-column="4/ span 1"
                  grid-row="1/ span 1"
                  position="relative"
                  sm-grid-column="1/ span 2"
                  sm-grid-row="3/ span 1"
                  md-grid-column="1/ span 2"
                  md-grid-row="2/ span 1"
                  id="7255625391"
                  height="350px"
                  md-height="240px"
                  overflow="hidden"
                  sm-height="200px"
                >
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/black-frames-studio/blackframes/s9.jpg"
                    display="block"
                    width="100%"
                    height="100%"
                    object-fit="cover"
                    border-radius="0px"
                    className="hoverText zoom"
                    onClick={() => isSideBarOpen("1425521573")}
                    id="1425521573"
                  />
                </LinkBox>
              </PhotoView>

              <PhotoView src="https://nimbuscluster.blob.core.windows.net/server01/black-frames-studio/blackframes/s10.jpg">
                <LinkBox
                  grid-column="1/span 1"
                  grid-row="2/ span 1"
                  position="relative"
                  sm-grid-column="1/ span 2"
                  sm-grid-row="4 /span 1"
                  md-grid-column="3/ span 2"
                  md-grid-row="2/ span 2"
                  id="9022543077"
                  height="350px"
                  md-height="240px"
                  overflow="hidden"
                  sm-height="200px"
                >
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/black-frames-studio/blackframes/s10.jpg"
                    display="block"
                    width="100%"
                    object-fit="cover"
                    height="100%"
                    border-radius="0px"
                    className="hoverText zoom"
                    onClick={() => isSideBarOpen("3044609476")}
                    id="3044609476"
                  />
                </LinkBox>
              </PhotoView>

              <PhotoView src="https://nimbuscluster.blob.core.windows.net/server01/black-frames-studio/blackframes/s11.jpg">
                <LinkBox
                  grid-column="2/ span 1"
                  grid-row="2/ span 1"
                  position="relative"
                  sm-grid-column="1/span 2"
                  sm-grid-row="5 /span 1"
                  md-grid-column="1/span 4"
                  md-grid-row="3 /span 1"
                  id="2968682648"
                  height="350px"
                  md-height="240px"
                  overflow="hidden"
                  sm-height="200px"
                >
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/black-frames-studio/blackframes/s11.jpg"
                    display="block"
                    width="100%"
                    height="100%"
                    object-fit="cover"
                    border-radius="0px"
                    className="hoverText zoom"
                    onClick={() => isSideBarOpen("1472089232")}
                    id="1472089232"
                  />
                </LinkBox>
              </PhotoView>

              <PhotoView src="https://nimbuscluster.blob.core.windows.net/server01/black-frames-studio/blackframes/s12.jpg">
                <LinkBox
                  background="--primaryGradient"
                  grid-column="3/ span 2"
                  grid-row="2/ span 1"
                  position="relative"
                  sm-grid-column="1 /span 2"
                  sm-grid-row="6/span 1"
                  md-grid-column="1 / span 4"
                  id="9028491335"
                  height="350px"
                  md-background="url() 0 0 no-repeat"
                  md-grid-row="4 / span 1 "
                  md-height="240px"
                  overflow="hidden"
                  sm-height="200px"
                >
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/black-frames-studio/blackframes/s12.jpg"
                    display="block"
                    width="100%"
                    height="100%"
                    object-fit="cover"
                    border-radius="0px"
                    className="hoverText zoom"
                    onClick={() => isSideBarOpen("5424455560")}
                    id="5424455560"
                  />
                </LinkBox>
              </PhotoView>
            </Box>
          </PhotoProvider>
        </Reveal>
      </Section>

      {/* <Section
        background="--backgroundDark"
        position="static"
        z-index="0"
        sm-align-items="center"
        sm-justify-content="center"
        padding="0px 0 0px 0"
        lg-padding="40px 0 40px 0"
        sm-padding="20px 0 20px 0"
      >
        <Override
          slot="SectionContent"
          width="100%"
          align-items="center"
          justify-content="center"
          margin="80px 0 80px 0"
          max-width="100%"
          padding="0px 80px 0px 80px"
          sm-padding="0px 20px 0px 20px"
          sm-margin="26px 0 26px 0"
          sm-height="max-content"
          md-padding="0px 20px 0px 20px"
          md-align-items="center"
          md-justify-content="center"
          md-margin="24px 0 24px 0"
          lg-padding="0px 40px 0px 40px"
          lg-margin="16px 0 16px 0"
          md-height="fit-content"
          flex-direction="row"
          className="cust-w100"
        />
        <Reveal>
          <Swiper
            spaceBetween={35}
            slidesPerView={4}
            observer={true}
            observeParents={true}
            modules={[Navigation]}
            className="mySwiper"
            autoplay={{
              delay: "1000",
            }}
            loop
            navigation={{
              nextEl: ".image-swiper-button-next",
              prevEl: ".image-swiper-button-prev",
            }}
            breakpoints={{
              1536: {
                slidesPerView: 1.1,
                // spaceBetween: 20,
              },
              1280: {
                slidesPerView: 1.1,
                // spaceBetween: 20,
              },
              1024: {
                slidesPerView: 1.1,
                // spaceBetween: 20,
              },
              991: {
                slidesPerView: 1.1,
                // spaceBetween: 30,
              },
              640: {
                slidesPerView: 1,
                // spaceBetween: 30,
              },
              425: {
                slidesPerView: 1,
                // spaceBetween: 50,
              },
              325: {
                slidesPerView: 1,
                // spaceBetween: 50,
              },
            }}
          >
            <div class="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer z-10">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div class="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer z-10">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>

            <SwiperSlide>
              <Image
                src={
                  data
                    ? data["0332066174"]
                    : "https://images.unsplash.com/photo-1601506521937-0121a7fc2a6b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
                }
                display="block"
                width="100%"
                height="560px"
                object-fit="cover"
                margin="0px 28px 0px 28px"
                lg-height="460px"
                md-margin="0px 12px 0px 12px"
                md-height="400px"
                sm-height="290px"
                sm-margin="0px 10px 0px 10px"
                className="hoverText"
                id="0332066174"
                onClick={() => isSideBarOpen("0332066174")}
              />
            </SwiperSlide>

            <SwiperSlide>
              <Image
                src={
                  data
                    ? data["0332066174"]
                    : "https://images.unsplash.com/photo-1601506521937-0121a7fc2a6b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
                }
                display="block"
                width="100%"
                height="560px"
                object-fit="cover"
                margin="0px 28px 0px 28px"
                lg-height="460px"
                md-margin="0px 12px 0px 12px"
                md-height="400px"
                sm-height="290px"
                sm-margin="0px 10px 0px 10px"
                className="hoverText"
                id="0332066174"
                onClick={() => isSideBarOpen("0332066174")}
              />
            </SwiperSlide>
          </Swiper>
        </Reveal>
      </Section> */}
      <Section
        align-items="center"
        justify-content="center"
        sm-align-items="center"
        sm-justify-content="center"
        lg-font="--subheadline5"
        sm-padding="40px 0 40px 0"
        padding="0px 0 80px 0"
        lg-padding="30px 0 60px 0"
        md-padding="20px 0 60px 0"
      >
        <Override
          slot="SectionContent"
          margin="8px 80px 8px 80px"
          md-margin="24px 40px 24px 40px"
          lg-margin="40px 60px 40px 60px"
          max-width="100%"
          width="100%"
          sm-margin="0px 20px 0px 20px"
        />
        <Box
          margin="0px 0px 0px 0px"
          sm-height="max-content"
          sm-min-height="max-content"
          md-height="max-content"
          md-min-height="max-content"
          lg-min-height="max-content"
          align-items="center"
          display="flex"
          flex-direction="column"
          justify-content="center"
        >
          <Text
            margin="0px 0px 6px 0px"
            font="--subheadline4"
            color="--dark"
            text-align="center"
            sm-font="--subheadline6"
            lg-font="--subheadline5"
            id="0291698864"
            border-color="--color-secondery"
            className="hoverText"
            onClick={() => isSideBarOpen("0291698864")}
          >
            <Span
              font-weight="normal"
              overflow-wrap="normal"
              word-break="normal"
              white-space="normal"
              text-indent="0"
              text-overflow="clip"
              hyphens="manual"
              user-select="auto"
              pointer-events="auto"
              font="--subheadline2"
              sm-font="--subheadline6"
            >
              <Reveal>
                {" "}
                {data ? data["0291698864"] : "FOLLOW US ON INSTAGRAM"}{" "}
              </Reveal>
              <Hr
                margin="0px auto 0px auto"
                height="2px"
                padding="0px 0px 2px 0px"
                background="--primaryGradient"
                width="25%"
              />
            </Span>
          </Text>
          <Reveal>
            <Hr
              margin="0px 0px 0px 0px"
              height="2px"
              padding="0px 0px 2px 0px"
              background="--primaryGradient"
              width="50%"
            />
          </Reveal>
          <Text
            margin="0px 0px 0px 0px"
            font="--subheadline3"
            text-align="center"
            sm-font="--subheadline5"
            lg-font="--subheadline4"
            id="6841189597"
            display="none"
          >
            CAPTURING MOMENTS IN TIME
          </Text>
          {/* <div
            className=""
            style={{ display: "flex", justifyContent: "center" }}
          >
            <FacebookEmbed
              url="https://www.facebook.com/andrewismusic/posts/45191596293956"
              width={328}
            />
            <InstagramEmbed
              url="https://www.instagram.com/p/CUbHfhpswxt/"
              width={328}
            />
          </div> */}
          <Box
            // display="grid"
            // grid-template-columns="repeat(4, 1fr)"
            width="100%"
            // grid-gap="32px"
            margin="40px 0px 0px 0px"
            // lg-grid-gap="18px"
            // md-grid-template-columns="repeat(2, 1fr)"
            // sm-grid-gap="20px"
            sm-margin="20px 0px 0px 0px"
            // sm-grid-template-columns="repeat(1, 1fr)"
            className="cust-w100"
          >
            <Reveal>
              {/* <Swiper
                spaceBetween={35}
                slidesPerView={3}
                observer={true}
                observeParents={true}
                modules={[Navigation]}
                className="mySwiper"
                autoplay={{
                  delay: "1000",
                }}
                loop
                navigation={{
                  nextEl: ".image-swiper-button-next",
                  prevEl: ".image-swiper-button-prev",
                }}
                breakpoints={{
                  1536: {
                    slidesPerView: 3,
                    // spaceBetween: 20,
                  },
                  1280: {
                    slidesPerView: 3,
                    // spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 3,
                    // spaceBetween: 20,
                  },
                  991: {
                    slidesPerView: 2.5,
                    // spaceBetween: 30,
                  },
                  640: {
                    slidesPerView: 2.5,
                    // spaceBetween: 30,
                  },
                  425: {
                    slidesPerView: 1.2,
                    // spaceBetween: 50,
                  },
                  325: {
                    slidesPerView: 1.2,
                    // spaceBetween: 50,
                  },
                }}
              >
                <div class="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer z-10">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
                  </svg>
                </div>
                <div class="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer z-10">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
                  </svg>
                </div>

                <SwiperSlide>
                  <div className="w-full">
                    <InstagramEmbed
                      height={580}
                      url="https://www.instagram.com/reel/CveMDOgINz-/?igshid=MzRlODBiNWFlZA=="
                    />
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="w-full">
                    <InstagramEmbed
                      height={580}
                      url="https://www.instagram.com/reel/CvKnIVEo03Y/?igshid=MzRlODBiNWFlZA=="
                    />
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="w-full">
                    <InstagramEmbed
                      height={580}
                      url="https://www.instagram.com/reel/Cu_yemCIpOx/?igshid=MzRlODBiNWFlZA=="
                    />
                  </div>
                </SwiperSlide>
              </Swiper> */}
              <iframe
                src="https://widgets.sociablekit.com/instagram-feed/iframe/219327"
                frameborder="0"
                width="100%"
                height="1000"
              ></iframe>
            </Reveal>
          </Box>
        </Box>
      </Section>
      <Section
        id="contact"
        background="--backgroundDark"
        position="relative"
        z-index="0"
        align-items="center"
        justify-content="center"
        md-padding="24px 0px 24px 0px"
      >
        <Override
          slot="SectionContent"
          align-items="center"
          justify-content="center"
          margin="40px 0 40px 0"
          max-width="100%"
          padding="0px 0px 0px 0px"
          md-margin="40px 20px 40px 20px"
          className="cust-w100"
        />

        <Box
          min-height="100px"
          display="flex"
          justify-content="space-between"
          sm-flex-direction="column"
          align-items="center"
          width="70%"
          md-width="100%"
        >
          <Reveal>
            <Box
              width="100%"
              sm-width="100%"
              display="flex"
              flex-direction="column"
            >
              <Text
                margin="0px 0px 38px 0px"
                font="--subheadline4"
                color="--darkL1"
                padding="0px 0px 0 0px"
                letter-spacing="4px"
                sm-font="--subheadlined4"
                md-font="--subheadlined4"
                md-display="none"
                id="7081002794"
                className="hoverText uppercase"
                onClick={() => isSideBarOpen("7081002794")}
                text-align="center"
              >
                {data ? data["7081002794"] : "CONNECT US"}
              </Text>
              <Box
                display="flex"
                align-items="center"
                margin="0 0 42px 0"
                sm-margin="0px 0px 16px 0px"
                lg-margin="0px 0px 24px 0px"
              >
                <Box display="content" width="100%">
                  <Input
                    display="block"
                    placeholder-color="rgba(211, 211, 211, 0.41)"
                    width="100%"
                    margin="0px 0px 0px 0px"
                    height="60px"
                    border-width="0 0 1px 0"
                    border-style="solid"
                    placeholder="FULL NAME"
                    id="3174789627"
                    border-color="--color-darkL1"
                    background="rgba(255, 255, 255, 0)"
                    placeholder-font="--base"
                    font="--base"
                    color="--darkL1"
                    value={name}
                    onChange={handleNameChange}
                  />
                  {errName == "" ? (
                    ""
                  ) : (
                    <div
                      style={{
                        color: "red",
                        margin: "0",

                        padding: "0",
                      }}
                      className="errClass"
                    >
                      {errName}
                    </div>
                  )}
                </Box>
              </Box>
              <Box
                display="flex"
                align-items="center"
                margin="0 0 42px 0"
                sm-display="flex"
                sm-flex-direction="column"
                sm-margin="0px 0px 16px 0px"
                lg-margin="0px 0px 24px 0px"
              >
                <Box display="content" width="100%">
                  <Input
                    placeholder-color="rgba(211, 211, 211, 0.41)"
                    width="100%"
                    margin="0px 24px 0px 0px"
                    height="60px"
                    border-width="0 0 1px 0"
                    border-style="solid"
                    placeholder="PHONE NUMBER"
                    display="flex"
                    sm-margin="0px 0 16px 0px"
                    id="4868732435"
                    border-color="--color-darkL1"
                    background="rgba(255, 255, 255, 0)"
                    color="--darkL1"
                    value={phone}
                    onChange={handlephoneChange}
                  />
                  {errPhone == "" ? (
                    ""
                  ) : (
                    <div
                      style={{
                        color: "red",
                        margin: "0",

                        padding: "0",
                      }}
                      className="errClass"
                    >
                      {errPhone}
                    </div>
                  )}
                </Box>
                <Box display="block" width="100%">
                  <Input
                    display="block"
                    placeholder-color="rgba(211, 211, 211, 0.41)"
                    width="100%"
                    height="60px"
                    margin="0px 0px 0px 10px"
                    sm-margin="0px 0px 0px 00px"
                    border-width="0 0 1px 0"
                    border-style="solid"
                    placeholder="EMAIL "
                    id="2926016427"
                    border-color="--color-darkL1"
                    background="rgba(255, 255, 255, 0)"
                    color="--darkL1"
                    value={email}
                    type="email"
                    onChange={handleemailChange}
                  />
                  {errEmail === "" ? (
                    ""
                  ) : (
                    <div
                      style={{
                        color: "red",
                        margin: "0",

                        padding: "0",
                      }}
                      className="errClass"
                    >
                      {errEmail}
                    </div>
                  )}
                </Box>
              </Box>
              <Box
                display="flex"
                align-items="center"
                margin="0px 0px 48px 0px"
                lg-margin="0px 0px 24px 0px"
                md-margin="0px 0px 60px 0px"
              >
                <Input
                  display="block"
                  placeholder-color="rgba(211, 211, 211, 0.41)"
                  width="100%"
                  margin="0px 0px 0px 0px"
                  height="60px"
                  border-width="0 0 1px 0"
                  border-style="solid"
                  placeholder="ENTER MESSAGE"
                  id="5791018233"
                  border-color="--color-darkL1"
                  background="rgba(255, 255, 255, 0)"
                  color="--darkL1"
                  value={message}
                  required="true"
                  onChange={handlemessageChange}
                />
                {errMsg == "" ? (
                  ""
                ) : (
                  <div
                    style={{
                      position: "absolute",
                      color: "red",
                      margin: "0",
                      marginTop: "100px",
                      padding: "0",
                    }}
                    className="errClass"
                  >
                    {errMsg}
                  </div>
                )}
              </Box>
              <Box display="flex" align-items="center" justify-content="center">
                <Button
                  Id="5748094947"
                  color="--dark"
                  font="--p2"
                  padding="20px 24px 20px 24px"
                  sm-font="--bt2"
                  sm-display="flex"
                  sm-flex-direction="row"
                  sm-margin="0px 8px 0px 0px"
                  md-display="block"
                  width="24%"
                  sm-width="50%"
                  md-padding="16px 24px 16px 24px"
                  sm-align-items="center"
                  sm-justify-content="center"
                  className="hoverText skinButton uppercase"
                  id="2641719228"
                  lg-padding="16px 24px 16px 24px"
                  background="--color-primary "
                  lg-width="40%"
                  // onClick={() => isSideBarOpen("2641719228")}
                  onClick={handleSubmit}
                >
                  <Strong>{data ? data["2641719228"] : "ENQUIRE NOW"}</Strong>
                </Button>
              </Box>
            </Box>
          </Reveal>
        </Box>

        <Image
          src="https://uploads.quarkly.io/6459d5984b38c40020065812/images/13802681_5337446-ai%20%281%29.png?v=2023-05-11T06:00:07.833Z"
          display="none"
          position="absolute"
          width="100%"
          height="100%"
          object-fit="cover"
          z-index="-1"
          opacity="0.05"
          filter="invert(500%)"
        />
      </Section>
      <Footer />
      {isShow ? (
        <ContentUpdate
          selectedElement={selectedData}
          isShow={isShow}
          closeSidebar={closeSidebar}
        />
      ) : (
        ""
      )}
      <RawHtml>
        <style place={"endOfHead"} rawKey={"6459d5984b38c40020065810"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
};
