import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import QAPI from "qapi";
import "assets/css/index.css";
import "../src/assets/styles/tailwind.css";
import "./assets/scss/Swiper.scss";

import "./assets/scss/Font.scss";
import "../src/assets/styles/tailwind.generated.css";
import "../src/assets/scss/Index.scss";
import "react-photo-view/dist/react-photo-view.css";
import { Provider } from "react-redux";
import { store } from "redux/store";
window.QAPI = QAPI;
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,

  document.getElementById("root")
);
