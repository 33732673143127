export default {
	"pages": {
		"root": {
			"id": "root",
			"pageUrl": "root",
			"name": "root",
			"children": [
				"649bdc8b8bd7e600185a13cc",
				"649bdc8b8bd7e600185a13d0",
				"649bdc8b8bd7e600185a13d4",
				"649bdc8b8bd7e600185a13e4",
				"649bdc8b8bd7e600185a13dc",
				"649e7a8e72a6d3001824b7f6",
				"649ea6d3098f07001997c675",
				"649bdc8b8bd7e600185a13e0",
				"64a3d75872a6d3001824ea95"
			]
		},
		"649bdc8b8bd7e600185a13cc": {
			"id": "649bdc8b8bd7e600185a13cc",
			"name": "404",
			"pageUrl": "404"
		},
		"649bdc8b8bd7e600185a13d0": {
			"id": "649bdc8b8bd7e600185a13d0",
			"name": "index",
			"pageUrl": "index"
		},
		"649bdc8b8bd7e600185a13d4": {
			"id": "649bdc8b8bd7e600185a13d4",
			"pageUrl": "index1",
			"name": "About Us"
		},
		"649bdc8b8bd7e600185a13d8": {
			"id": "649bdc8b8bd7e600185a13d8",
			"pageUrl": "index2",
			"name": "Services"
		},
		"649bdc8b8bd7e600185a13dc": {
			"id": "649bdc8b8bd7e600185a13dc",
			"pageUrl": "index21",
			"name": "Portfolio"
		},
		"649bdc8b8bd7e600185a13e0": {
			"id": "649bdc8b8bd7e600185a13e0",
			"pageUrl": "index211",
			"name": "Contact Us"
		},
		"649bdc8b8bd7e600185a13e4": {
			"id": "649bdc8b8bd7e600185a13e4",
			"pageUrl": "index212",
			"name": "Studio"
		},
		"649e7a8e72a6d3001824b7f6": {
			"pageUrl": "index213",
			"id": "649e7a8e72a6d3001824b7f6",
			"name": "Category Overview"
		},
		"649ea6d3098f07001997c675": {
			"pageUrl": "index2131",
			"id": "649ea6d3098f07001997c675",
			"name": "Project Overview"
		},
		"64a3d75872a6d3001824ea95": {
			"id": "64a3d75872a6d3001824ea95",
			"pageUrl": "extra",
			"name": "Extra",
			"children": [
				"649bdc8b8bd7e600185a13d8"
			]
		}
	},
	"mode": "production",
	"projectType": "create-react-app",
	"site": {
		"styles": {},
		"seo": {}
	}
}