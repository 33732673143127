import React, { useCallback } from "react";
import theme from "theme";
import {
  Theme,
  Span,
  Text,
  Hr,
  Box,
  Image,
  LinkBox,
  Section,
  Link,
} from "@quarkly/widgets";
import FeatherIcon from "feather-icons-react";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, SocialMedia } from "@quarkly/components";
import * as Components from "components";
import Skeleton from "components/common/Skeleton";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { setSelectedData } from "redux/CMS/cms-actions";
import { useParams, useHistory } from "react-router-dom";
import ContentUpdate from "../components/CMS/ContentUpdate";
import Footer from "./Footer";
import { useState } from "react";
import { useEffect } from "react";
import { getProduct } from "redux/Product/product-action";
import { PhotoProvider, PhotoSlider, PhotoView } from "react-photo-view";
import { Feather } from "react-feather";

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isClosePopup, setisClosePopup] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [selectedData, updateSelectedData] = useState("update");
  const BrandDetails = useSelector((state) => state.brandDetails.brandProfile);
  const [loading, setLoading] = useState(false);
  console.log(BrandDetails, "outside in");
  let project = useSelector((state) => state.userDetails.project);
  let data = useSelector((state) => state.cms.pages["Imressions"]);
  let userData = useSelector((state) => state.cms.userData);

  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  let SingleSubCategoryAlbum = useSelector(
    (state) => state.product.addSingleSubCategoryAlbum
  );
  let Album = useSelector((state) => state.product.Product);
  //-----------hide/show-------
  const { token, subcategory } = useParams();

  const closePhotoView = () => {
    console.log("click back close");
    setisClosePopup(true);
  };

  if (token) {
    let cmsStyle = document.getElementsByClassName("hoverText");
    console.log(cmsStyle, "cmsStyle");
    for (let i = 0; i < cmsStyle.length; i++) {
      cmsStyle[i].onClick = function () {
        return false;
      };
      cmsStyle[i].classList.add("hoverText1");
    }
  }

  //----------------hide/show-----------

  useEffect(() => {
    setMounted(true);
    setLoading(true);
    async function mount() {
      let data = await dispatch(
        getProduct({
          subcategoryName: subcategory.replace(/-/g, " ").toUpperCase(),
        })
      );
      if (data.code === 200) {
        setLoading(false);
      }
      console.log(data, "chex");
    }
    mount();
  }, [dispatch, setMounted]);

  async function isSideBarOpen(id) {
    if (token) {
      await updateSelectedData(id);
      setIsShow(true);
    }
  }

  window.addEventListener("popstate", () => {
    console.log("click back button");
    setisClosePopup(false);
  });

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      //Do whatever when esc is pressed
      history.goBack();
      setisClosePopup(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, true);

    return () => {
      document.removeEventListener("keydown", escFunction, true);
    };
  }, [escFunction]);

  console.log(isClosePopup, "backcheckk");
  function closeSidebar(value) {
    setIsShow(value);
    updateSelectedData("");
    // selectedData = "";
    dispatch(setSelectedData({}));
    console.log(selectedData, "show");
  }
  console.log(document.getElementById("photo"), "events");
  function getFavicon(value) {
    return value
      ? value.favIcon
        ? process.env.REACT_APP_STORAGE_URL_2 + brandProfile.favIcon
        : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/logo.png"
      : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/logo.png";
  }
  const baseURL = process.env.REACT_APP_STORAGE_URL;
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index2131"} />
      <Helmet>
        <title>Black-Frames-Studio</title>
        <meta
          name={"description"}
          content={"Web site created using quarkly.io"}
        />
        <link
          rel={"shortcut icon"}
          href={getFavicon(brandProfile)}
          type={"image/x-icon"}
        />
      </Helmet>
      {/* <Components.Nav>
        <Override slot="image" />
      </Components.Nav> */}
      <Components.NavabarHeader>
        <Override slot="image" />
      </Components.NavabarHeader>

      <Section
        background="--backgroundLight"
        padding="60px 0 80px 0"
        sm-padding="40px 0 50px 0"
        className="cust-mt"
      >
        {loading ? (
          <Skeleton />
        ) : (
          <>
            <Box
              display="flex"
              align-items="center"
              justify-content="center"
              flex-direction="column"
              margin="0px 0px 40px 0px"
              sm-margin="0px 0px 20px 0px"
            >
              <Text
                margin="0px 0px 6px 0px"
                font="--subheadline4"
                color="--dark"
                text-align="center"
                sm-font="--subheadline6"
                lg-font="--subheadline5"
                id="9567370249"
                border-color="--color-secondery"
                className="hoverText"
              >
                <Span
                  font-size="40px"
                  overflow-wrap="normal"
                  word-break="normal"
                  white-space="normal"
                  text-indent="0"
                  text-overflow="clip"
                  hyphens="manual"
                  user-select="auto"
                  pointer-events="auto"
                  font-weight="normal"
                  sm-font="--subheadline4"
                >
                  {subcategory.replace(/-/g, " ").toUpperCase()}
                </Span>
              </Text>
              <Hr
                min-width="5%"
                margin="0px 0px 8px 0px"
                width="5%"
                background="--primaryGradient"
                height="2px"
                min-height={0}
                padding="0px 0px 2px 0px"
                border-radius="4px"
                sm-width="10%"
              />
            </Box>
            <Box>
              <LinkBox
                align-items="flex-start"
                height="fit-content"
                display="grid"
                sm-display="block"
                grid-template-columns="repeat(3, 1fr)"
                grid-gap="30px"
                margin="0px 0px 0px 0px"
                lg-grid-gap="20px"
                sm-grid-template-columns="repeat(1, 1fr)"
              >
                <PhotoProvider
                  className="cust-mt5rem hidecross "
                  visible={isClosePopup}
                >
                  {Album && Album.album && Album.album.length > 0
                    ? Album.album.map((e) => (
                        <div className="overflow-hidden">
                          <PhotoView
                            id="photo"
                            src={process.env.REACT_APP_STORAGE_URL_2 + e}
                            onclose={closePhotoView}
                          >
                            <Image
                              src={process.env.REACT_APP_STORAGE_URL_2 + e}
                              display="block"
                              object-fit="cover"
                              width="100%"
                              height="530px"
                              sm-margin-bottom="20px"
                              margin="0px 0px 0px 0px"
                              lg-height="530px"
                              className="hoverText zoom"
                              onClick={() => {
                                // console.log(setisClosePopup(true));
                                setisClosePopup(true);
                              }}
                              id="2703553991"
                            />
                          </PhotoView>
                        </div>
                      ))
                    : "no-data"}
                </PhotoProvider>
              </LinkBox>
            </Box>
          </>
        )}
      </Section>

      <Footer />
      {isShow ? (
        <ContentUpdate
          selectedElement={selectedData}
          isShow={isShow}
          closeSidebar={closeSidebar}
        />
      ) : (
        ""
      )}
      <RawHtml>
        <style place={"endOfHead"} rawKey={"6459d5984b38c40020065810"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
};
