import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import {
  Image,
  LinkBox,
  Link,
  Icon,
  Box,
  Section,
  Span,
} from "@quarkly/widgets";
import { MdCamera } from "react-icons/md";

import { useDispatch, useSelector } from "react-redux/es/exports";
import { useEffect } from "react";
import { getAllMenu, setLoading } from "redux/Menu/menu-actions";

const defaultProps = {
  background: "--backgroundDark",
  position: "sticky",
  "z-index": "2",
  height: "fit-content",
  "md-align-items": "center",
  "md-justify-content": "center",
  "sm-top": "0px",
  "sm-bottom": "7159.294117647059px",
  "sm-height": "70.70588235294117px",
  "quarkly-title": "Nav",
  color: "--dark",
  "sm-z-index": "2",
  padding: "18px 0px 18px 0px",
};
const overrides = {
  box: {
    kind: "Box",
    props: {
      display: "flex",
      "justify-content": "space-between",
      "align-items": "center",
      height: "max-content",
      "md-align-items": "center",
      "sm-width": "100%",
      "sm-justify-content": "space-between",
      "md-justify-content": "space-between",
    },
  },
  linkBox: {
    kind: "LinkBox",
    props: {
      filter: "invert(0%)",
      href: "/index",
    },
  },
  image: {
    kind: "Image",
    props: {
      display: "block",
      "object-fit": "contain",
      filter: "invert(0)",
      width: "100px",
      height: "70px",
      src: "https://uploads.quarkly.io/649bdc8a8bd7e600185a1390/images/BFwhiteyellow.png?v=2023-07-04T07:36:58.503Z",
    },
  },
  linkBox1: {
    kind: "LinkBox",
    props: {
      "overflow-x": "hidden",
      "overflow-y": "hidden",
      "align-items": "flex-end",
      "active-width": "max-content",
      "visited-width": "max-content",
      "sm-width": "52px",
      "sm-height": "54px",
      "md-width": "9%",
    },
  },
  box1: {
    kind: "Box",
    props: {
      width: "100%",
      display: "flex",
      "justify-content": "flex-end",
      "sm-display": "flex",
      "md-display": "flex",
      "align-items": "center",
      "border-radius": "40px",
      padding: "0px 0px 0px 40px",
      font: "--subheadline5",
      "sm-height": "54px",
      "lg-width": "9%",
      "lg-overflow-x": "hidden",
      "lg-overflow-y": "hidden",
      "md-height": "54px",
      "md-width": "54px",
      color: "--dark",
      background: "--backgroundDark",
    },
  },
  link: {
    kind: "Link",
    props: {
      "text-decoration-line": "initial",
      margin: "0px 40px 0px 0px",
      font: "--subheadline6",
      href: "/index1",
      color: "--grey",
      children: (
        <Span font-weight="normal" color="--lightD2">
          ABOUT
        </Span>
      ),
    },
  },
  link1: {
    kind: "Link",
    props: {
      "text-decoration-line": "initial",
      margin: "0px 40px 0px 0px",
      font: "--subheadline6",
      href: "/index2",
      color: "--grey",
      children: (
        <Span font-weight="normal" color="--lightD2">
          SERVICES
        </Span>
      ),
      display: "none",
    },
  },
  link2: {
    kind: "Link",
    props: {
      "text-decoration-line": "initial",
      margin: "0px 40px 0px 0px",
      font: "--subheadline6",
      href: "/index212",
      color: "--grey",
      children: (
        <Span font-weight="normal" color="--lightD2">
          STUDIO
        </Span>
      ),
    },
  },
  link3: {
    kind: "Link",
    props: {
      "text-decoration-line": "initial",
      margin: "0px 40px 0px 0px",
      font: "--subheadline6",
      href: "/index21",
      color: "--grey",
      children: (
        <Span
          font-weight="normal"
          color="--lightD2"
          overflow-wrap="normal"
          word-break="normal"
          white-space="normal"
          text-indent="0"
          text-overflow="clip"
          hyphens="manual"
          user-select="auto"
          pointer-events="auto"
        >
          PORTFOLIO
        </Span>
      ),
    },
  },
  link4: {
    kind: "Link",
    props: {
      "text-decoration-line": "initial",
      font: "--subheadline6",
      href: "/index211",
      color: "--grey",
      children: (
        <Span font-weight="normal" color="--lightD2">
          CONTACT US
        </Span>
      ),
      margin: "0px 0px 0px 0px",
    },
  },
  linkBox2: {
    kind: "LinkBox",
    props: {
      "active-transform": "--rotate90",
      transform: "rotate(90deg)",
      "sm-height": "54px",
      display: "none",
    },
  },
  icon: {
    kind: "Icon",
    props: {
      category: "md",
      icon: MdCamera,
      size: "64px",
      color: "--primary",
      transform: "--rotate90",
      "sm-height": "50px",
      "sm-width": "50px",
      "md-width": "54px",
      "md-height": "50% content-box",
    },
  },
};

const Nav = (props) => {
  const dispatch = useDispatch();
  let loading = useSelector((state) => state.menu.loading);
  let data = useSelector((state) => state.menu.menu);
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  let project = useSelector((state) => state.userDetails.project);
  useEffect(() => {
    console.log("Header");
    async function mount() {
    
      // await dispatch(getUser({ projectId: project._id }));

      await dispatch(getAllMenu());
      await dispatch(setLoading(true));
  
  
    

      console.log(getAllMenu, "cmsss");
      const timer = setTimeout(async () => {
        await dispatch(setLoading(false));
      }, 2000);

      return () => clearTimeout(timer);
    }
    mount();
  }, [dispatch]);

  const { override, children, rest } = useOverrides(
    props,
    overrides,
    defaultProps
  );
  return (
    <Section {...rest}>
      <Override
        slot="SectionContent"
        margin="0px auto 0px auto"
        sm-flex-direction="row"
        sm-margin="6px auto 6px auto"
      />
      <Box {...override("box")}>
        <LinkBox {...override("linkBox")}>
          <Image {...override("image")} />
        </LinkBox>
        <LinkBox {...override("linkBox1")}>
          <Box {...override("box1")}>
            <Link {...override("link")} />
            <Link {...override("link1")} />
            <Link {...override("link2")} />
            <Link {...override("link3")} />
            <Link {...override("link4")} />
            <LinkBox {...override("linkBox2")}>
              <Icon {...override("icon")} />
            </LinkBox>
          </Box>
        </LinkBox>
      </Box>
      {children}
    </Section>
  );
};

Object.assign(Nav, { ...Section, defaultProps, overrides });
export default Nav;
