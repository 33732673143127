export default {
  defaultWidth: 1280,
  breakpoints: {
    sm: [
      {
        type: "max-width",
        value: 576,
      },
    ],
    md: [
      {
        type: "max-width",
        value: 768,
      },
    ],
    lg: [
      {
        type: "max-width",
        value: 992,
      },
    ],
  },
  color: {
    dark: "#111111",
    darkL1: "#ffffff",
    darkL2: "#191E22",
    grey: "#919191",
    greyD1: "#637897",
    greyD2: "#586D8E",
    light: "#F7FBFF",
    lightD1: "#EDF2F6",
    lightD2: "#E4E8EC",
    green: "#00875A",
    primary: "#FFD000",
    secondary: "#1f006f",
    orange: "#FF7C22",
    red: "#e80838",
    purple: "#FD6DF9",
    indigo: "#9B6CFC",
  },
  fontFamily: {
    sans: "-apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    sansHeavy:
      "Impact, Haettenschweiler, 'Franklin Gothic Bold', Charcoal, 'Helvetica Inserat', 'Bitstream Vera Sans Bold', 'Arial Black', sans-serif",
    sansHelvetica:
      "Frutiger, 'Frutiger Linotype', Univers, Calibri, 'Gill Sans', 'Gill Sans MT', 'Myriad Pro', Myriad, 'DejaVu Sans Condensed', 'Liberation Sans', 'Nimbus Sans L', Tahoma, Geneva, 'Helvetica Neue', Helvetica, Arial, sans-serif",
    sansVerdana:
      "Corbel, 'Lucida Grande', 'Lucida Sans Unicode', 'DejaVu Sans', 'Bitstream Vera Sans', 'Liberation Sans', Verdana, 'Verdana Ref', sans-serif",
    sansTrebuchet:
      "'Segoe UI', Candara, 'Bitstream Vera Sans', 'DejaVu Sans', 'Trebuchet MS', Verdana, 'Verdana Ref', sans-serif",
    mono: "Consolas, 'Andale Mono WT', 'Andale Mono', 'Lucida Console', 'Lucida Sans Typewriter', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Liberation Mono', 'Nimbus Mono L', Monaco, 'Courier New', Courier, monospace",
    serifTimes:
      "Cambria, 'Hoefler Text', Utopia, 'Liberation Serif', 'Nimbus Roman No9 L Regular', Times, 'Times New Roman', serif",
    serifGeorgia:
      "Constantia, 'Lucida Bright', Lucidabright, 'Lucida Serif', Lucida, 'DejaVu Serif', 'Bitstream Vera Serif', 'Liberation Serif', Georgia, serif",
    serifGaramond:
      "'Palatino Linotype', Palatino, Palladio, 'URW Palladio L', 'Book Antiqua', Baskerville, 'Bookman Old Style', 'Bitstream Charter', 'Nimbus Roman No9 L', Garamond, 'Apple Garamond', 'ITC Garamond Narrow', 'New Century Schoolbook', 'Century Schoolbook', 'Century Schoolbook L', Georgia, serif",
    googleMontserrat: '"Montserrat", sans-serif',
    googleDancingScript: '"Dancing Script", cursive',
    googlePlayfairDisplay: '"Playfair Display", serif',
    googlePtSans: '"PT Sans", sans-serif',
  },
  font: {
    headline1: "normal 900 90px/1.2 --fontFamily-googleMontserrat",
    headline2: "normal 100 84px/1.2 --fontFamily-googlePlayfairDisplay",
    headline3: "normal 700 64px/1.2 --fontFamily-googlePlayfairDisplay",
    base: "normal 200 18px/100% --fontFamily-googlePtSans",
    base1: "normal 200 14px/100% --fontFamily-googlePtSans",
    lead: "normal 300 20px/1.5 --fontFamily-sans",
    headline4: "normal 100 40px/1.2 --fontFamily-googlePtSans",
    headline5: "normal 500 48px/1.2 --fontFamily-googlePlayfairDisplay",
    headline6: "normal 500 64px/1.2 --fontFamily-googlePlayfairDisplay",
    subheadline1: "normal 500 48px/1.2 --fontFamily-googleMontserrat",
    subheadline2: "100 38px/110% --fontFamily-googlePtSans",
    subheadline3: "300 32px/1.2 --fontFamily-googlePlayfairDisplay",
    subheadline4: "400 24px/1.2 --fontFamily-googlePtSans",
    subheadline5: "400 20px/1.3 --fontFamily-googlePtSans",
    subheadline6: "600 16px/1.2 --fontFamily-googlePtSans",
    subheadline7: "400 16px/1.2 --fontFamily-googlePtSans",
    subheadline8: "600 16px/1.2 --fontFamily-googlePtSans",
    subheadline9: "400 18px/1.3 --fontFamily-googlePtSans",
    subheadline10: "400 28px/1.2 --fontFamily-googlePtSans",
    subheadline11: "600 14px/1.2 --fontFamily-googlePtSans",
    subheadline12: "400 14px/1.2 --fontFamily-googlePtSans",
    signatureFont: "italic 100 26px/100% --fontFamily-googlePtSans",
    btn1: "600 18px/1.2 --fontFamily-googleMontserrat",
    btn2: "700 12px/1.2 --fontFamily-googleMontserrat",
    p1: "500 22px/1.2 --fontFamily-googleMontserrat",
    p2: "200 18px/1.3 --fontFamily-googlePtSans",
    p3: "600 16px/1.2 --fontFamily-googleMontserrat",
    p4: "400 14px/20px --fontFamily-googlePtSans",
    p5: "400 12px/1.2 --fontFamily-googleMontserrat",
    p6: "500 18px/1.2 --fontFamily-googlePtSans",
    signatureFont2: "900 24px --fontFamily-googlePlayfairDisplay",
    subheadline31: "normal 100 28px/100% --fontFamily-googlePtSans",
    p41: "700 14px --fontFamily-googlePtSans",
  },
  boxShadow: {
    m: "0 4px 5px -1px rgba(0, 0, 0, 0.1)",
    l: "0 10px 15px -3px rgba(0, 0, 0, 0.1)",
    xl: "0 20px 25px -5px rgba(0, 0, 0, 0.1)",
    xxl: "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
  },
  background: {
    primaryGradient:
      "linear-gradient(450deg,#ffdeae 0%,#cfb545 100%) 0 0 no-repeat",
    secondaryGradient:
      "linear-gradient(180deg, --color-secondary, transparent) no-repeat 0 0",
    backgroundLight: "#ffffff url() 0 0 no-repeat",
    backgroundDark: "#000000 url() 0 0 no-repeat",
  },
  transform: {
    rotate90: "rotate(90deg)",
    rotate180: "rotate(180deg)",
    flipX: "scaleX(-1)",
    flipY: "scaleY(-1)",
  },
  transition: {
    opacityOut:
      "opacity --transitionDuration-normal --transitionTimingFunction-easeOut",
    transformOut:
      "transform --transitionDuration-normal --transitionTimingFunction-easeOut",
    transformInOut:
      "transform --transitionDuration-normal --transitionTimingFunction-easeInOut",
  },
  transitionTimingFunction: {
    easeIn: "cubic-bezier(0.4, 0, 1, 1)",
    easeOut: "cubic-bezier(0, 0, 0.2, 1)",
    easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
    sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
  },
  transitionDuration: {
    fastest: "0.1s",
    fast: "0.2s",
    normal: "0.3s",
  },
  filter: {
    grayscale: "grayscale(100%)",
    invert: "invert(100%)",
    blur: "blur(10px)",
  },
  animation: {},
  keyframes: {
    fadeIn: [
      {
        key: "from",
        props: [
          {
            opacity: 0,
          },
        ],
      },
      {
        key: "to",
        props: [
          {
            opacity: 1,
          },
        ],
      },
    ],
    fadeOut: [
      {
        key: "from",
        props: [
          {
            opacity: 1,
          },
        ],
      },
      {
        key: "to",
        props: [
          {
            opacity: 0,
          },
        ],
      },
    ],
  },
  components: {
    section: {
      maxWidth: {
        default: "1280px",
      },
      minWidth: {
        default: "300px",
      },
      width: {
        default: "90%",
      },
    },
    stack: {
      gap: {
        default: "32px",
        small: "16px",
      },
    },
  },
  fonts: {
    Montserrat: {
      family: "Montserrat",
      type: "google-fonts",
      meta: {
        category: "sans-serif",
        variants: [
          "100",
          "200",
          "300",
          "regular",
          "500",
          "600",
          "700",
          "800",
          "900",
          "100italic",
          "200italic",
          "300italic",
          "italic",
          "500italic",
          "600italic",
          "700italic",
          "800italic",
          "900italic",
        ],
      },
    },
    "Dancing Script": {
      family: "Dancing Script",
      type: "google-fonts",
      meta: {
        category: "handwriting",
        variants: ["regular", "500", "600", "700"],
      },
    },
    "Playfair Display": {
      family: "Playfair Display",
      type: "google-fonts",
      meta: {
        category: "serif",
        variants: [
          "regular",
          "500",
          "600",
          "700",
          "800",
          "900",
          "italic",
          "500italic",
          "600italic",
          "700italic",
          "800italic",
          "900italic",
        ],
      },
    },
    "PT Sans": {
      family: "PT Sans",
      type: "google-fonts",
      meta: {
        category: "sans-serif",
        variants: ["regular", "italic", "700", "700italic"],
      },
    },
  },
  version: 42,
};
