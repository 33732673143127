// import axios from "axios";
import apis from "../../apis/variations";

export const getVariations = (payload) => {
  console.log("variations");
  return async (dispatch) => {
    try {
      let findData = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        pageId: payload.pageId,
      };

      let { data } = await apis.getVariations(findData);

      console.log(data, "page");
      if (data) dispatch(setVariation(data.data));
      return data;
    } catch (error) {}
  };
};

export const setVariation = (data) => {
  console.log(data, "hello");
  return {
    type: "SET_VARIATION",
    payload: data,
  };
};
