import React, { useState } from "react";
import FeatherIcon from "feather-icons-react";
import { InputGroup, Form, Modal, Button } from "react-bootstrap";
import { Icon } from "@quarkly/widgets";
import { IoMdShare } from "react-icons/io";

const Sharepopup = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div>
      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}
      <Icon
        category="io"
        icon={IoMdShare}
        onClick={handleShow}
        size="36px"
        color="--darkL1"
      />

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="px-20 pt-4 pb-12">
            <div className="flex w-full justify-around pb-3">
              <FeatherIcon icon="facebook" className="textblack " size="24px" />
              <FeatherIcon icon="twitter" className="textblack " size="24px" />
              <FeatherIcon
                icon="instagram"
                className="textblack "
                size="24px"
              />
              <FeatherIcon icon="youtube" className="textblack " size="24px" />
              <FeatherIcon icon="mail" className="textblack " size="24px" />
            </div>
            <InputGroup>
              <Form.Control
                aria-label="text"
                aria-describedby="basic-addon2"
                className="popup"
              />
              <Button variant=" popup" id="button-asson2">
                <FeatherIcon
                  icon="copy"
                  className=" "
                  size="20px"
                  color="--darkL1"
                />
              </Button>
            </InputGroup>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Sharepopup;
