import React from "react";
import Sidebar from "react-sidebar";
import { connect } from "react-redux";
import { getElementById, updateStore, updateCms } from "redux/CMS/cms-actions";
import FeatherIcon from "feather-icons-react";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";
import { Input, Upload } from "element-react";

class ContentUpdate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: props.isShow,
      content: "",
      imageUrl: "",
      baseUrl: process.env.REACT_APP_STORAGE_URL,
      file: {},
      formData: new FormData(),
    };

    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleTextArea = this.handleTextArea.bind(this);
    this.imageChange = this.imageChange.bind(this);
    this.onEdit = this.onEdit.bind(this);
    console.log(this.props, "props");
  }

  componentDidMount() {
    console.log("MOUNT");
    if (this.props.isShow) this.props.dispatch(getElementById(this.props.id));
  }
  async componentDidUpdate() {
    if (!this.props.isShow && this.state.content) {
      await this.setState({ content: "" });
      console.log(this.props.selectedData, "after close");
    }
    console.log(
      "test",
      this.props.selectedData,
      this.props.isShow,
      this.state.content,
      this.props.selectedElement,
      this.props.isShow && !!this.props.selectedData && !this.state.content
    );
    if (this.props.isShow && !!this.props.selectedData && !this.state.content) {
      console.log("fetch");
      await this.props.dispatch(getElementById(this.props.selectedElement));
      console.log(!this.state.content);
      if (!this.state.content) {
        this.setState({ content: this.props.selectedData.content });
        console.log(this.props, "update state");
      }

      if (!this.state.imageUrl) {
        this.setState({
          imageUrl: this.state.baseUrl + this.props.selectedData.content,
        });
        console.log(this.props, "update state");
      }
    }
  }

  async handleInput(event) {
    await this.setState({ content: event.target.value });
    console.log(this.state.content);
    let payload = {
      pageName: this.props.selectedData.pageName,
      elementId: this.props.selectedElement,
      content: this.state.content,
    };
    await this.props.dispatch(updateStore(payload));
  }

  async handleTextArea(event) {
    console.log(event.target.selectionStart);
    if (event.keyCode === 13) {
      // event.preventDefault();
      let value = await event.target.value;
      let index = await event.target.selectionStart;
      let newValue =
        (await value.substring(0, index)) + "<br />" + value.substring(index);
      await this.setState({ content: newValue });
    } else {
      await this.setState({ content: event.target.value });
    }

    let payload = {
      pageName: this.props.selectedData.pageName,
      elementId: this.props.selectedElement,
      content: this.state.content,
    };
    await this.props.dispatch(updateStore(payload));
  }

  async handleCursorChange(event) {
    console.log(event.target.selectionStart, "cursor");
  }

  async onEdit() {
    console.log("submit");
    if (this.props.selectedData.contentType === "Image") {
      let payload = {
        _id: this.props.selectedData._id,
        path: this.props.selectedData.projectName + "/cms",
        projectName: this.props.selectedData.projectName,
        image: this.state.file,
        contentType: this.props.selectedData.contentType,
      };

      await this.props.dispatch(updateCms(payload));
    } else {
      let payload = {
        _id: this.props.selectedData._id,
        content: this.state.content,
        projectName: this.props.selectedData.projectName,
        contentType: this.props.selectedData.contentType,
      };
      console.log(payload, "after");
      await this.props.dispatch(updateCms(payload));
    }

    this.props.closeSidebar(false);
  }

  async imageChange(event) {
    let file = event.target.files[0];
    await this.setState({ file: file });
    let image = URL.createObjectURL(file);
    await this.setState({ imageUrl: image });
    let payload = {
      pageName: this.props.selectedData.pageName,
      elementId: this.props.selectedElement,
      content: this.state.imageUrl,
    };
    await this.props.dispatch(updateStore(payload));
  }
  // onSetSidebarClose(close) {
  //   this.setState({ sidebarClose: close });
  // }

  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open });
  }

  render() {
    const suggestionData = [
      {
        id: "01",
        pageName: "Home",
        text: " Select a plan based on your business needs Choose a pricing plan & start scoping your business requirements.",
      },
      {
        id: "01",
        pageName: "Home",
        text: " Select a plan based on your business needs Choose a pricing plan & start scoping your business requirements.",
      },
      {
        id: "01",
        pageName: "About Us",
        text: " Select a plan based on your business needs Choose a pricing plan & start scoping your business requirements.",
      },
      {
        id: "01",
        pageName: "Home",
        text: " Select a plan based on your business needs Choose a pricing plan & start scoping your business requirements.",
      },
      {
        id: "01",
        pageName: "Contact Us",
        text: " Select a plan based on your business needs Choose a pricing plan & start scoping your business requirements.",
      },

      {
        id: "01",
        pageName: "Home",
        text: " Select a plan based on your business needs Choose a pricing plan & start scoping your business requirements.",
      },
      {
        id: "01",
        pageName: "Home",
        text: " Select a plan based on your business needs Choose a pricing plan & start scoping your business requirements.",
      },
      {
        id: "01",
        pageName: "Home",
        text: " Select a plan based on your business needs Choose a pricing plan & start scoping your business requirements.",
      },
    ];
    //quill
    const modules = {
      toolbar: [
        //[{ 'font': [] }],
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link", "image"],
        [{ align: [] }, { color: [] }, { background: [] }], // dropdown with defaults from theme
        ["clean"],
      ],
    };

    const formats = [
      //'font',
      "header",
      "bold",
      "italic",
      "underline",
      "strike",
      "blockquote",
      "list",
      "bullet",
      "indent",
      "link",
      "image",
      "align",
      "color",
      "background",
    ];
    const { selectedData } = this.props;
    const { content, imageUrl } = this.state;
    return (
      <Sidebar
        pullRight
        rootClassName="stop-abcall"
        overlayClassName="stop-abchild"
        sidebarClassName="sidebarclass sidebarfixcls"
        sidebar={
          <div className="sidebar-overlay">
            <div className="flexsetjustify px-20px">
              <h2 className="sideheader">Content Management System</h2>
              <p
                className="cursor-pointer"
                onClick={() => {
                  this.props.closeSidebar(false);
                }}
              >
                <FeatherIcon icon="x" className="text-white" />
              </p>
            </div>
            <div className="mt-2 px-20px pt-20px">
              <div className="relative">
                {selectedData && selectedData.contentType === "Text" ? (
                  <textarea
                    placeholder="Enter..."
                    value={content}
                    onChange={this.handleInput}
                    className="border w-full mt-4 p-2 text-dark"
                    rows="4"
                    cols="100"
                  ></textarea>
                ) : (
                  ""
                )}
                {selectedData && selectedData.contentType === "TextField" ? (
                  <textarea
                    placeholder="Enter..."
                    value={content}
                    onChange={this.handleTextArea}
                    className="inputcss"
                    rows="4"
                    cols="50"
                  ></textarea>
                ) : (
                  ""
                )}
                {selectedData && selectedData.contentType === "Image" ? (
                  <div className="relative">
                    <img src={imageUrl} className="h-60 w-full" />
                    <div className="absolute top-0">
                      <input
                        type="file"
                        onChange={this.imageChange}
                        className="h-60 w-full border opactid"
                      ></input>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {selectedData && selectedData.contentType === "Button" ? (
                  <div>
                    <input
                      type="text"
                      value={content}
                      onChange={this.handleInput}
                      className="inputcss"
                    ></input>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="mt-3 flex justify-center">
                <button
                  className="submitbutton fsize17 sm-fsize12 cursor-pointer"
                  onClick={() => {
                    console.log("clicked");
                    this.onEdit();
                  }}
                >
                  {" "}
                  Update
                </button>
              </div>

              {/* suggestion content */}

              {/* <div className="mt-4 ">
                <div className="mb-2  px-2">
                  {" "}
                  <input
                    type="text"
                    placeholder="Search..."
                    className="cms-searchinput themefamily w-full "
                  />
                </div>

                <div className="cms-scrollbar p-2">
                  {suggestionData.map((e) => {
                    return (
                      <div className=" mb-3 elshadow-md rounded-md p-3 ">
                        <h6 className="textprimary mb-1 fsize14  font-semibold">
                          {e.pageName}
                        </h6>
                        <div className=" flex items-center">
                          <p className="themefamily text-gray fsize12  w-11/12">
                            {e.text}
                          </p>
                          <div className="w-1/12 flex justify-end">
                            <FeatherIcon
                              icon="plus"
                              size={30}
                              className="text-white bgsecondary cursor-pointer rounded-full p5px"
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div> */}
            </div>
            {/* <div className="p-3">
              <ReactQuill theme="snow" modules={modules} formats={formats} />
            </div> */}
            {/* <div className="p-3 ">
              <div className="flex overflow-x-auto gap-4 w-full">
                <div className="wid-upload">
                  {" "}
                  <Upload
                    className="upload-demo  wid-upload"
                    drag
                    action="//jsonplaceholder.typicode.com/posts/"
                    multiple
                  >
                    <i className="el-icon-upload"></i>
                  </Upload>
                </div>
                <div className="wid-upload">
                  {" "}
                  <Upload
                    className="upload-demo  wid-upload"
                    drag
                    action="//jsonplaceholder.typicode.com/posts/"
                    multiple
                  >
                    <i className="el-icon-upload"></i>
                  </Upload>
                </div>
                <div className="wid-upload">
                  {" "}
                  <Upload
                    className="upload-demo  wid-upload"
                    drag
                    action="//jsonplaceholder.typicode.com/posts/"
                    multiple
                  >
                    <i className="el-icon-upload"></i>
                  </Upload>
                </div>
                <div className="wid-upload">
                  {" "}
                  <Upload
                    className="upload-demo  wid-upload"
                    drag
                    action="//jsonplaceholder.typicode.com/posts/"
                    multiple
                  >
                    <i className="el-icon-upload"></i>
                  </Upload>
                </div>
              </div>
            </div>
            <div className="p-3  border">
              <div className="flex overflow-x-auto gap-4 w-full">
                <div className="wid-upload2 border p-2">
                  <div className="wid-upload">
                    {" "}
                    <Upload
                      className="upload-demo  wid-upload"
                      drag
                      action="//jsonplaceholder.typicode.com/posts/"
                      multiple
                    >
                      <i className="el-icon-upload"></i>
                    </Upload>
                  </div>
                  <div className="py-1">
                    <Input placeholder="Please input" />
                  </div>
                  <div className="py-1">
                    <Input placeholder="Please input" />
                  </div>
                </div>
                <div className="wid-upload2 border p-2">
                  <div className="wid-upload">
                    {" "}
                    <Upload
                      className="upload-demo  wid-upload"
                      drag
                      action="//jsonplaceholder.typicode.com/posts/"
                      multiple
                    >
                      <i className="el-icon-upload"></i>
                    </Upload>
                  </div>
                  <div className="py-1">
                    <Input placeholder="Please input" />
                  </div>
                  <div className="py-1">
                    <Input placeholder="Please input" />
                  </div>
                </div>
                <div className="wid-upload2 border p-2">
                  <div className="wid-upload">
                    {" "}
                    <Upload
                      className="upload-demo  wid-upload"
                      drag
                      action="//jsonplaceholder.typicode.com/posts/"
                      multiple
                    >
                      <i className="el-icon-upload"></i>
                    </Upload>
                  </div>
                  <div className="py-1">
                    <Input placeholder="Please input" />
                  </div>
                  <div className="py-1">
                    <Input placeholder="Please input" />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        }
        open={this.props.isShow}
        onSetOpen={this.onSetSidebarOpen}
        styles={{ sidebar: { background: "white" } }}
      ></Sidebar>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state, "mapstate");
  if (!!state.cms.selectedData) {
    return {
      selectedData: state.cms.selectedData,
    };
  } else {
    return {
      selectedData: {},
    };
  }
};

export default connect(mapStateToProps)(ContentUpdate);
