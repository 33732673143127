import API from "./index";

const get = "get";

const categoryRoute = (route) => {
  return `portfolio/category/${route}`;
};
const subCategoryRoute = (route) => {
  return `portfolio/subcategory/${route}`;
};
const subCategoryByCategoryRoute = (route) => {
  return `portfolio/subcategory/${route}`;
};
const productBysubcatRoute = (route) => {
  return `portfolio/product/${route}`;
};
export default {
  getAllCategory() {
    return API.post(categoryRoute(get));
  },
  getAllSubCategory() {
    return API.post(subCategoryRoute(get));
  },
  getSubCategory(payload) {
    return API.post(subCategoryByCategoryRoute(get),payload);
  },
  getProduct(payload) {
    return API.post(productBysubcatRoute(get),payload);
  },
};
