import React from "react";
import Reveal from "react-reveal/Reveal";
import Slide from "react-reveal/Slide";
import theme from "theme";
import Footer from "./Footer";
import { InstagramEmbed } from "react-social-media-embed";
import {
  Theme,
  Image,
  Text,
  Box,
  Section,
  Icon,
  Input,
  Button,
  Hr,
  LinkBox,
  Link,
} from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, SocialMedia } from "@quarkly/components";
import { createContact } from "redux/marcom/marcom-action";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import * as Components from "components";
import { TiLocation } from "react-icons/ti";
import { MdCall, MdEmail } from "react-icons/md";

import { useDispatch, useSelector } from "react-redux/es/exports";

import { useParams } from "react-router-dom";
import ContentUpdate from "../components/CMS/ContentUpdate";

import { useEffect } from "react";
import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { Navigation } from "swiper";
import validator from "validator";
import "swiper/swiper.min.css";
export default () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [errPhone, setErrPhone] = useState("");
  const [errEmail, setErrEmail] = useState("");
  const [errName, setErrName] = useState("");
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [selectedData, updateSelectedData] = useState("update");
  const BrandDetails = useSelector((state) => state.brandDetails.brandProfile);

  console.log(BrandDetails, "outside in");
  let project = useSelector((state) => state.userDetails.project);
  let data = useSelector((state) => state.cms.pages["Contact Us"]);
  let userData = useSelector((state) => state.cms.userData);
  let productData = useSelector((state) => state.product.product);
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handlephoneChange = (event) => {
    setPhone(event.target.value);
    if (event.target.value) {
      if (event.target.value.length != 10) {
        if (/^\d*$/.test(event.target.value) === false) {
          setErrPhone("Please enter valid number!");
        } else {
          setErrPhone("Valid only 10 digit!");
        }
      } else {
        if (/^\d*$/.test(event.target.value)) {
          setErrPhone("");
        } else {
          setErrPhone("Please enter valid number!");
        }
      }
    }
  };

  const handleemailChange = (event) => {
    setEmail(event.target.value);
    if (event.target.value) {
      if (!validator.isEmail(event.target.value)) {
        setErrEmail("Invalid email address!");
      } else {
        setErrEmail("");
      }
    } else {
      if (validator.isEmail(event.target.value)) {
        setErrEmail("");
      } else {
        setErrEmail("Please enter email address!");
      }
    }
  };

  const handlemessageChange = (event) => {
    setMessage(event.target.value);
    if (event.target.value) {
      setErrMsg("");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (message == "") {
      setErrMsg("Please enter message!");
      return false;
    } else {
      setErrMsg("");
    }
    if (phone == "") {
      setErrPhone("Please enter phone!");
      return false;
    } else {
      setErrPhone("");
    }
    if (email == "") {
      setErrEmail("Please enter email!");
      return false;
    } else {
      if (!validator.isEmail(email)) {
        setErrEmail("Invalid email address!");
        return false;
      } else {
        setErrEmail("");
      }
    }
    if (name == "") {
      setErrName("Please enter name!");
      return false;
    } else {
      setErrName("");
    }
    let payload = {
      name: name,
      mobile: phone,
      email: email,
      description: message,
      type: "Website",
    };
    if (
      payload.name !== "" ||
      payload.mobile !== "" ||
      payload.email !== "" ||
      payload.description !== ""
    ) {
      let data = await dispatch(createContact(payload));
      console.log(data, "DATA");

      if (data.code === 200) {
        setEmail("");
        setMessage("");
        setName("");
        setPhone("");
        Swal.fire({
          // title: "Success",
          text: "Thank you for contacting us.",
          icon: "success",
        });

        console.log(data.code, "registercode");
      } else {
        // alert(data.message);
        Swal.fire({
          title: "Errow",
          text: "Please enter valid credential",
          icon: "error",
        });
        console.log(data.message, "datamessage");
      }
    }
  };
  //-----------hide/show-------
  const { token } = useParams();

  if (token) {
    let cmsStyle = document.getElementsByClassName("hoverText");
    console.log(cmsStyle, "cmsStyle");
    for (let i = 0; i < cmsStyle.length; i++) {
      cmsStyle[i].onClick = function () {
        return false;
      };
      cmsStyle[i].classList.add("hoverText1");
    }
  }
  //----------------hide/show-----------

  useEffect(() => {
    console.log(data, "Home");
    setMounted(true);
  }, [dispatch, setMounted]);
  console.log(data, "outside");
  console.log(productData, "hii");

  async function isSideBarOpen(id) {
    if (token) {
      await updateSelectedData(id);
      setIsShow(true);
      console.log(isShow, selectedData, id, "show");
    }
  }

  function closeSidebar(value) {
    setIsShow(value);
    updateSelectedData("");
    // selectedData = "";

    console.log(selectedData, "show");
  }

  function getFavicon(value) {
    return value
      ? value.favIcon
        ? process.env.REACT_APP_STORAGE_URL_2 + brandProfile.favIcon
        : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/logo.png"
      : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/logo.png";
  }
  const baseURL = process.env.REACT_APP_STORAGE_URL;
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index211"} />
      <Helmet>
        <title>Black-Frames-Studio</title>
        <meta
          name={"description"}
          content={"Web site created using quarkly.io"}
        />
        <link
          rel={"shortcut icon"}
          href={getFavicon(brandProfile)}
          type={"image/x-icon"}
        />
      </Helmet>
      {/* <Components.Nav /> */}
      <Components.NavabarHeader />
      {/* <Section
        position="relative"
        quarkly-title="hero"
        padding="106px 0 60px 0"
      >
        <Override
          slot="SectionContent"
          flex-direction="row"
          margin="40px auto 40px auto"
          align-items="center"
          justify-content="center"
          height="300px"
        />
        <Image
          display="block"
          position="absolute"
          width="100%"
          top="auto"
          left="auto"
          bottom="auto"
          right="auto"
          height="100%"
          object-fit="cover"
          src={
            data
              ? data["4685716871"]
              : "https://images.unsplash.com/photo-1556103255-4443dbae8e5a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
          }
          background="linear-gradient(450deg,#ffdeae 0%,#cfb545 100%) 0 0 no-repeat"
          id="4685716871"
          className="hoverText"
          onClick={() => isSideBarOpen("4685716871")}
        />
        <Box
          min-width="100px"
          min-height="100px"
          position="absolute"
          display="flex"
          align-items="center"
          justify-content="center"
          flex-direction="row"
          width="100%"
          height="100%"
          z-index="-1"
          opacity="0.9"
          background="linear-gradient(450deg,rgba(0, 0, 0, 0.8) 0%,rgba(0, 0, 0, 0.8) 100%) 0 0 no-repeat"
        />
        <Box
          width="100%"
          display="flex"
          sm-display="block"
          align-items="center"
          justify-content="space-between"
        >
          <Text
            margin="0px 0px 0px 0px"
            font="--headline2"
            sm-font-size="30px"
            height="max-content"
            sm-width="100%"
            sm-margin-bottom="15px"
            color="--darkL1"
            display="flex"
            flex-direction="row"
            id="6845758345"
            className="hoverText"
            onClick={() => isSideBarOpen("6845758345")}
          >
            <Reveal> {data ? data["6845758345"] : "Contact Us"}</Reveal>
          </Text>
          <Box
            width="50%"
            sm-width="100%"
            display="flex"
            flex-direction="column"
            height="max-content"
          >
            <Text
              margin="0px 0px 0px 0px"
              font="--p2"
              color="--darkL1"
              sm-font="--p5"
              sm-font-size="15px"
              sm-margin="0px 0px 16px 0px"
              id="1678620915"
              className="hoverText"
              onClick={() => isSideBarOpen("1678620915")}
            >
              <Reveal>
                {data ? data["1678620915"] : "Capturing Every precious moments"}
              </Reveal>
            </Text>
          </Box>
        </Box>
      </Section> */}
      <Section
        position="relative"
        md-height="max-content"
        padding="0px 0 0px 0"
        className="cust-mt"
      >
        <Override
          slot="SectionContent"
          position="static"
          align-items="center"
          justify-content="center"
          height="490px"
          max-width="100%"
          width="100%"
          sm-margin="0px auto 0px auto"
          md-margin="20px auto 20px auto"
          md-height="300px"
          lg-height="400px"
          sm-height="350px"
        />
        <Image
          src={
            data
              ? data["4685716871"]
              : "https://images.unsplash.com/photo-1556103255-4443dbae8e5a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
          }
          display="block"
          background="linear-gradient(450deg, rgb(255, 222, 174) 0%, rgb(207, 181, 69) 100%) 0px 0px no-repeat;"
          position="absolute"
          bottom="auto"
          top="0px"
          left="0px"
          right="auto"
          width="100%"
          z-index="-2"
          height="491.172px"
          object-fit="cover"
          sm-bottom="139.59555555555553px"
          sm-height="351.51944444444445px"
          sm-top="0px"
          md-bottom="179.059px"
          md-height="312.094px"
          md-top="0px"
          id="4685716871"
          lg-bottom="1.02878546258224px"
          lg-height="498.9421245374178px"
          lg-top="0px"
          className="hoverText"
          onClick={() => isSideBarOpen("4685716871")}
        />
        <Box
          min-width="100px"
          min-height="100px"
          position="absolute"
          display="flex"
          align-items="center"
          justify-content="center"
          flex-direction="row"
          width="100%"
          height="100%"
          z-index="-1"
          opacity="0.9"
          background="linear-gradient(450deg,rgba(0, 0, 0, 0.8) 0%,rgba(0, 0, 0, 0.8) 100%) 0 0 no-repeat"
        />
        <Box
          margin="90px 0px 90px 0px"
          md-min-height="max-content"
          md-margin="48px 0px 60px 0px"
          color="--darkL1"
        >
          <Text
            margin="0px 0px 6px 0px"
            font="--headline3"
            color="--secondery"
            text-align="center"
            sm-font="--subheadline2"
            md-font="--headline5"
            lg-font="--headline5"
            id="5172018544"
            className="hoverText"
            onClick={() => isSideBarOpen("5172018544")}
          >
            <Reveal> {data ? data["6845758345"] : "Contact Us"}</Reveal>
          </Text>
          <Text
            margin="0px 20px 0px 20px"
            sm-margin="0px 10px 0px 10px"
            font="--subheadline4"
            text-align="center"
            sm-font="--subheadline12"
            md-font="--subheadline4"
            lg-font="--subheadline4"
            color="--darkL1"
            md-color="--darkL1"
            id="3565408458"
            className="hoverText"
            onClick={() => isSideBarOpen("3565408458")}
          >
            <Reveal>
              {data ? data["1678620915"] : "Capturing Every precious moments"}
            </Reveal>
          </Text>
        </Box>
      </Section>
      <Section
        background="--backgroundDark"
        position="relative"
        z-index="0"
        align-items="center"
        justify-content="center"
        sm-padding="0 0 0 0"
        padding="50px 0 50px 0"
      >
        <Override
          slot="SectionContent"
          align-items="center"
          justify-content="center"
          max-width="100%"
          sm-margin="24px 0 24px 0"
          md-margin="16px auto 16px auto"
          margin="0px 80px 0px 80px"
        />
        <Box
          width="100%"
          lg-font="--headline4"
          sm-width="100%"
          sm-margin="0px 0px 24px 0px"
          md-width="100%"
          md-display="flex"
          md-align-items="center"
          margin="0px 0px 40px 0px"
          sm-padding="0px 0px 0px 0px"
          lg-width="50%"
          lg-padding="0px 0px 0px 0px"
          background="--backgroundDark"
          height="100%"
          display="flex"
          flex-direction="column"
          md-justify-content="center"
          align-items="center"
        >
          {/* <Text
            margin="0px 0px 48px 0px"
            font="--subheadline3"
            text-align="center"
            sm-font="--subheadline6"
            md-font="--subheadline5"
            lg-font="--subheadline4"
            color="--grey"
            width="80%"
            className="hoverText"
            onClick={() => isSideBarOpen("7069919535")}
            id="7069919535"
          >
       <Reveal>   {data ? data["7069919535"] : "We provide smarter"} </Reveal>  
          </Text> */}
          <Reveal>
            <Box
              min-width="100px"
              min-height="100px"
              display="grid"
              grid-template-columns="repeat(3, 1fr)"
              grid-gap="16px"
              margin="0px 0px 24px 0px"
              sm-grid-template-columns="repeat(1, 1fr)"
              sm-margin="0px 0px 0 0px"
              md-grid-gap="8px"
            >
              <Box
                display="flex"
                align-items="center"
                justify-content="flex-start"
                flex-direction="column"
                padding="24px 24px 24px 24px"
                md-padding="8px 8px 0px 8px"
                background="--backgroundLight"
                box-shadow="--l"
                sm-padding="24px 24px 24px 24px"
                sm-margin="0 0 0px 0"
                id="0268736876"
              >
                <Box
                  min-width="75px"
                  min-height="75px"
                  align-items="center"
                  display="flex"
                  justify-content="center"
                  margin="0px 0px 16px 0px"
                  background="--primaryGradient"
                  border-radius="8px"
                  sm-width="40px"
                  sm-height="40px"
                  sm-min-height={0}
                  sm-min-width={0}
                  md-min-width={0}
                  md-min-height={0}
                  md-width="48px"
                  md-height="48px"
                >
                  <Icon
                    category="ti"
                    icon={TiLocation}
                    size="32px"
                    margin="0 0 0 0"
                    color="--darkL1"
                    className="hoverText"
                  />
                </Box>
                <Text
                  margin="0px 0px 16px 0px"
                  font="--subheadline4"
                  color="--secondary"
                  padding="0px 0px 0 0px"
                  letter-spacing="2px"
                  sm-font="--subheadlined4"
                  md-font="--subheadlined4"
                  md-display="none"
                  id="2741381504"
                  className="hoverText"
                  onClick={() => isSideBarOpen("2741381504")}
                >
                  {data ? data["2741381504"] : "Connect Us"}
                </Text>
                <Text
                  margin="0px 0px 0px 0px"
                  font="--p3"
                  text-align="center"
                  sm-font="--p5"
                  sm-font-size="15px"
                  md-font="--p5"
                  lg-font="--subheadline4"
                  color="--grey"
                  className="hoverText"
                  onClick={() => isSideBarOpen("7258384818")}
                  id="7258384818"
                >
                  {data
                    ? data["7258384818"]
                    : "505, B Wing, Kemp Plaza, Malad West, Mumbai – 400 064, India."}
                </Text>
              </Box>
              <Box
                display="flex"
                align-items="center"
                justify-content="flex-start"
                margin="0px 0px 16px 0px"
                flex-direction="column"
                height="100%"
                padding="24px 24px 24px 24px"
                md-padding="8px 8px 8px 8px"
                background="--backgroundLight"
                box-shadow="--l"
                sm-padding="24px 24px 24px 24px"
                sm-margin="0 0 0px 0"
                id="0268736876"
              >
                <Box
                  min-width="75px"
                  min-height="75px"
                  display="flex"
                  align-items="center"
                  justify-content="center"
                  margin="0px 0px 16px 0px"
                  background="--primaryGradient"
                  border-radius="8px"
                  sm-width="40px"
                  sm-height="40px"
                  sm-min-height={0}
                  sm-min-width={0}
                  md-min-width={0}
                  md-min-height={0}
                  md-width="48px"
                  md-height="48px"
                  sm-font-size="15px"
                >
                  <Icon
                    category="md"
                    icon={MdCall}
                    size="32px"
                    margin="0 0 0 0"
                    color="--darkL1"
                    className="hoverText"
                  />
                </Box>
                <Text
                  margin="0px 0px 16px 0px"
                  font="--subheadline4"
                  color="--secondery"
                  padding="0px 0px 0 0px"
                  letter-spacing="2px"
                  sm-font="--subheadlined4"
                  md-font="--subheadlined4"
                  md-display="none"
                  id="6978879218"
                  className="hoverText"
                  onClick={() => isSideBarOpen("6978879218")}
                >
                  {data ? data["6978879218"] : "Call Us"}
                </Text>
                <a href="tel:7710044448">
                  <Text
                    margin="0px 0px 6px 0px"
                    font="--p3"
                    text-align="left"
                    sm-font="--p5"
                    md-font="--p5"
                    sm-font-size="15px"
                    lg-font="--subheadline4"
                    color="--grey"
                    md-margin="0px 0px 2px 0px"
                    className="hoverText"
                    onClick={() => isSideBarOpen("2292255627")}
                    id="2292255627"
                  >
                    {data ? data["2292255627"] : "+91 7710044448"}
                  </Text>
                </a>
                <Text
                  margin="0px 0px 0px 0px"
                  font="--p3"
                  sm-font-size="15px"
                  text-align="left"
                  sm-font="--p5"
                  md-font="--p5"
                  lg-font="--subheadline4"
                  color="--grey"
                  md-text-align="center"
                  className="hoverText sm-fsize15"
                  id="2292255627"
                >
                  Monday - Sunday: 9:00 AM to 9:00 PM
                </Text>
              </Box>
              <Box
                display="flex"
                align-items="center"
                justify-content="flex-start"
                margin="0px 0px 16px 0px"
                flex-direction="column"
                height="100%"
                padding="24px 24px 24px 24px"
                md-padding="8px 8px 8px 8px"
                background="--backgroundLight"
                box-shadow="--l"
                sm-padding="24px 24px 24px 24px"
                id="0268736876"
              >
                <Box
                  min-width="75px"
                  min-height="75px"
                  display="flex"
                  align-items="center"
                  justify-content="center"
                  margin="0px 0px 16px 0px"
                  background="--primaryGradient"
                  border-radius="8px"
                  sm-min-height={0}
                  sm-min-width={0}
                  sm-height="40px"
                  sm-width="40px"
                  md-min-width={0}
                  md-min-height={0}
                  md-width="48px"
                  md-height="48px"
                >
                  <Icon
                    category="md"
                    icon={MdEmail}
                    size="32px"
                    margin="0 0 0 0"
                    color="--darkL1"
                    className="hoverText"
                  />
                </Box>
                <Text
                  margin="0px 0px 16px 0px"
                  font="--subheadline4"
                  color="--secondery"
                  padding="0px 0px 0 0px"
                  letter-spacing="2px"
                  sm-font="--subheadlined4"
                  md-font="--subheadlined4"
                  md-display="none"
                  id="1541395387"
                  className="hoverText"
                  onClick={() => isSideBarOpen("1541395387")}
                >
                  {data ? data["1541395387"] : "Mail Us"}
                </Text>
                <a href="mailto:blackframesinfo@gmail.com">
                  <Text
                    margin="0px 0px 0px 0px"
                    font="--p3"
                    text-align="left"
                    sm-font="--p5"
                    sm-font-size="15px"
                    md-font="--p5"
                    lg-font="--subheadline4"
                    color="--grey"
                    className="hoverText"
                    onClick={() => isSideBarOpen("2960651102")}
                    id="2960651102"
                  >
                    {data ? data["2960651102"] : "data"}
                  </Text>
                </a>
              </Box>
            </Box>
          </Reveal>
        </Box>
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          justify-content="space-between"
          sm-flex-direction="column"
          align-items="center"
          height="max-content"
          width="100%"
          className="cust-w100"
        >
          <Box
            width="100%"
            padding="0px 12px 0px 0px"
            lg-font="--headline4"
            sm-width="100%"
            sm-margin="0px 0px 48px 0px"
            md-width="50%"
            md-display="flex"
            md-align-items="flex-start"
            margin="0px 20px 0px 0px"
            sm-padding="0px 0 0px 0px"
            lg-width="50%"
            lg-padding="0px 0px 0px 0px"
          >
            <Reveal effect="fadeInUp">
              <Image
                src={
                  data
                    ? data["7524925992"]
                    : "https://uploads.quarkly.io/6459d5984b38c40020065812/images/Frame%207.png?v=2023-06-22T12:44:27.704Z"
                }
                display="block"
                width="100%"
                id="7524925992"
                className="hoverText"
                onClick={() => isSideBarOpen("7524925992")}
              />
            </Reveal>
          </Box>
          <Reveal effect="fadeInUp">
            <Box
              min-width="100px"
              min-height="100px"
              width="100%"
              sm-width="100%"
              display="flex"
              flex-direction="column"
            >
              <Text
                margin="0px 0px 16px 0px"
                font="--subheadline4"
                color="--darkL1"
                padding="0px 0px 0 0px"
                letter-spacing="4px"
                width="29%"
                sm-width="100%"
                sm-font="--subheadlined4"
                md-font="--subheadlined4"
                sm-font-size="25px"
                id="3790969032"
                className="hoverText text-center"
                onClick={() => isSideBarOpen("3790969032")}
              >
                {data ? data["3790969032"] : "Connect Us"}
                <Hr
                  margin="5px auto 0px auto"
                  height="2px"
                  padding="0px 0px 2px 0px"
                  background="--primaryGradient"
                  width="50%"
                  sm-width="25%"
                />
              </Text>

              <Box
                display="flex"
                align-items="center"
                margin="0 0 42px 0"
                sm-margin="0px 0px 16px 0px"
                lg-margin="0px 0px 24px 0px"
              >
                <Box display="content" width="100%">
                  <Input
                    display="block"
                    placeholder-color="rgba(211, 211, 211, 0.41)"
                    width="100%"
                    margin="0px 0px 0px 0px"
                    height="60px"
                    border-width="0 0 1px 0"
                    border-style="solid"
                    placeholder="FULL NAME"
                    id="3174789627"
                    border-color="--color-darkL1"
                    background="rgba(255, 255, 255, 0)"
                    placeholder-font="--base"
                    font="--base"
                    color="--darkL1"
                    value={name}
                    onChange={handleNameChange}
                  />
                  {errName === "" ? (
                    ""
                  ) : (
                    <div
                      style={{
                        color: "red",
                        margin: "0",

                        padding: "0",
                      }}
                      className="errClass"
                    >
                      {errName}
                    </div>
                  )}
                </Box>
              </Box>
              <Box
                display="flex"
                align-items="center"
                margin="0 0 42px 0"
                sm-display="flex"
                sm-flex-direction="column"
                sm-margin="0px 0px 16px 0px"
                lg-margin="0px 0px 24px 0px"
              >
                <Box display="content" width="100%">
                  <Input
                    placeholder-color="rgba(211, 211, 211, 0.41)"
                    width="100%"
                    margin="0px 24px 0px 0px"
                    height="60px"
                    border-width="0 0 1px 0"
                    border-style="solid"
                    placeholder="PHONE NUMBER"
                    display="flex"
                    sm-margin="0px 0 16px 0px"
                    id="4868732435"
                    border-color="--color-darkL1"
                    background="rgba(255, 255, 255, 0)"
                    color="--darkL1"
                    value={phone}
                    onChange={handlephoneChange}
                  />
                  {errPhone == "" ? (
                    ""
                  ) : (
                    <div
                      style={{
                        color: "red",
                        margin: "0",

                        padding: "0",
                      }}
                      className="errClass"
                    >
                      {errPhone}
                    </div>
                  )}
                </Box>
                <Box display="block" width="100%">
                  <Input
                    display="block"
                    placeholder-color="rgba(211, 211, 211, 0.41)"
                    width="100%"
                    height="60px"
                    border-width="0 0 1px 0"
                    border-style="solid"
                    margin="0px 0px 0px 10px"
                    placeholder="EMAIL "
                    id="2926016427"
                    border-color="--color-darkL1"
                    background="rgba(255, 255, 255, 0)"
                    color="--darkL1"
                    value={email}
                    type="email"
                    onChange={handleemailChange}
                  />
                  {errEmail == "" ? (
                    ""
                  ) : (
                    <div
                      style={{
                        color: "red",
                        margin: "0",

                        padding: "0",
                      }}
                      className="errClass"
                    >
                      {errEmail}
                    </div>
                  )}
                </Box>
              </Box>
              <Box
                display="flex"
                align-items="center"
                margin="0px 0px 48px 0px"
                lg-margin="0px 0px 24px 0px"
                md-margin="0px 0px 60px 0px"
              >
                <Input
                  display="block"
                  placeholder-color="rgba(211, 211, 211, 0.41)"
                  width="100%"
                  margin="0px 0px 0px 0px"
                  height="60px"
                  border-width="0 0 1px 0"
                  border-style="solid"
                  placeholder="ENTER MESSAGE"
                  id="5791018233"
                  border-color="--color-darkL1"
                  background="rgba(255, 255, 255, 0)"
                  color="--darkL1"
                  value={message}
                  required="true"
                  onChange={handlemessageChange}
                />
                {errMsg == "" ? (
                  ""
                ) : (
                  <div
                    style={{
                      position: "absolute",
                      color: "red",
                      margin: "0",
                      marginTop: "100px",
                      padding: "0",
                    }}
                    className="errClass"
                  >
                    {errMsg}
                  </div>
                )}
              </Box>
              <Button
                Id="5748094947"
                color="--dark"
                font="--btn1"
                padding="24px 24px 24px 24px"
                sm-font="--bt2"
                sm-display="flex"
                sm-flex-direction="row"
                sm-margin="0px 8px 0px 0px"
                md-display="block"
                width="100%"
                height="max-content"
                md-padding="16px 24px 16px 24px"
                sm-align-items="center"
                sm-justify-content="center"
                className="hoverText skinButton uppercase"
                id="4739643656"
                lg-padding="16px 24px 16px 24px"
                background="--color-primary"
                // onClick={() => isSideBarOpen("4739643656")}
                onClick={handleSubmit}
              >
                {data ? data["4739643656"] : "Enquire Now"}
              </Button>
            </Box>
          </Reveal>
        </Box>
      </Section>
      <Section
        align-items="center"
        justify-content="center"
        sm-align-items="center"
        sm-justify-content="center"
        lg-font="--subheadline5"
        sm-padding="40px 0 40px 0"
        padding="80px 0 80px 0"
        lg-padding="30px 0 60px 0"
        md-padding="20px 0 60px 0"
        background="--color-dark"
      >
        <Override
          slot="SectionContent"
          margin="8px 80px 8px 80px"
          md-margin="24px 40px 24px 40px"
          lg-margin="40px 60px 40px 60px"
          max-width="100%"
          width="100%"
          sm-margin="0px 20px 0px 20px"
        />
        <Box
          margin="0px 0px 0px 0px"
          sm-height="max-content"
          sm-min-height="max-content"
          md-height="max-content"
          md-min-height="max-content"
          lg-min-height="max-content"
          align-items="center"
          display="flex"
          flex-direction="column"
          justify-content="center"
        >
          <Text
            margin="0px 0px 14px 0px"
            font="--subheadline2"
            color="--darkL1"
            text-align="center"
            sm-font="--subheadline6"
            sm-font-size="25px"
            lg-font="--subheadline5"
            id="9953465216"
            border-color="--color-secondery"
            className="hoverText"
            onClick={() => isSideBarOpen("9953465216")}
          >
            <Reveal>
              {" "}
              {data ? data["9953465216"] : "FOLLOW US ON INSTAGRAM"}{" "}
            </Reveal>
            <Hr
              margin="5px auto 0px auto"
              height="2px"
              padding="0px 0px 2px 0px"
              background="--primaryGradient"
              width="25%"
            />
          </Text>

          <Reveal></Reveal>
          <Text
            margin="0px 0px 0px 0px"
            font="--subheadline3"
            text-align="center"
            sm-font="--subheadline5"
            lg-font="--subheadline4"
            id="6841189597"
            display="none"
          >
            CAPTURING MOMENTS IN TIME
          </Text>
          <Box
            // display="grid"
            // grid-template-columns="repeat(4, 1fr)"
            width="100%"
            // grid-gap="32px"
            margin="60px 0px 0px 0px"
            // lg-grid-gap="18px"
            // md-grid-template-columns="repeat(2, 1fr)"
            // sm-grid-gap="20px"
            sm-margin="20px 0px 0px 0px"
            // sm-grid-template-columns="repeat(1, 1fr)"
            className="cust-w100"
          >
            <Reveal>
              {/* <Swiper
                spaceBetween={35}
                slidesPerView={3}
                observer={true}
                observeParents={true}
                modules={[Navigation]}
                className="mySwiper"
                autoplay={{
                  delay: "1000",
                }}
                loop
                navigation={{
                  nextEl: ".image-swiper-button-next",
                  prevEl: ".image-swiper-button-prev",
                }}
                breakpoints={{
                  1536: {
                    slidesPerView: 3,
                    // spaceBetween: 20,
                  },
                  1280: {
                    slidesPerView: 3,
                    // spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 3,
                    // spaceBetween: 20,
                  },
                  991: {
                    slidesPerView: 3,
                    // spaceBetween: 30,
                  },
                  640: {
                    slidesPerView: 2,
                    // spaceBetween: 30,
                  },
                  425: {
                    slidesPerView: 1.2,
                    // spaceBetween: 50,
                  },
                  325: {
                    slidesPerView: 1.2,
                    // spaceBetween: 50,
                  },
                }}
              >
                <div class="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer z-10">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
                  </svg>
                </div>
                <div class="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer z-10">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
                  </svg>
                </div>

                <SwiperSlide>
                  <div className="w-full">
                    <InstagramEmbed
                      height={580}
                      url="https://www.instagram.com/reel/Cvr-e_FIIDi/?igshid=MzRlODBiNWFlZA=="
                    />
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="w-full">
                    <InstagramEmbed
                      height={580}
                      url="https://www.instagram.com/p/CvpZNGuyFF1/?igshid=MzRlODBiNWFlZA=="
                    />
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="w-full">
                    <InstagramEmbed
                      height={580}
                      url="https://www.instagram.com/p/Cvh9sFfSp7U/?igshid=MzRlODBiNWFlZA=="
                    />
                  </div>
                </SwiperSlide>
              </Swiper> */}
              <iframe
                src="https://widgets.sociablekit.com/instagram-feed/iframe/219322"
                frameborder="0"
                width="100%"
                height="1000"
              ></iframe>
            </Reveal>
          </Box>
        </Box>
      </Section>
      {/* <Components.QuarklycommunityKitYandexMap
        height="450px"
        md-height="400px"
      /> */}
      <Box>
        <Reveal>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7538.703221945182!2d72.82672353006636!3d19.136080229992732!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b73ab030530d%3A0x281a1a6299fc5dc9!2sBlack%20Frames%20Studios!5e0!3m2!1sen!2sin!4v1691127354597!5m2!1sen!2sin"
            width="100%"
            height="450"
            style={{ border: 0 }}
            loading="lazy"
          ></iframe>
        </Reveal>
      </Box>
      <Footer />
      {isShow ? (
        <ContentUpdate
          selectedElement={selectedData}
          isShow={isShow}
          closeSidebar={closeSidebar}
        />
      ) : (
        ""
      )}
      <RawHtml>
        <style place={"endOfHead"} rawKey={"6459d5984b38c40020065810"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
};
