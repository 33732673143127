import React, { useEffect, useState } from "react";
import { TypeAnimation } from "react-type-animation";
import Reveal from "react-reveal/Reveal";
import Slide from "react-reveal/Slide";
import theme from "theme";
import {
  Theme,
  Image,
  Box,
  Text,
  Section,
  Hr,
  Icon,
  Span,
  Link,
} from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, SocialMedia } from "@quarkly/components";
import * as Components from "components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { Navigation } from "swiper";
import "swiper/swiper.min.css";

import Footer from "./Footer";
import { useDispatch, useSelector } from "react-redux/es/exports";

import { useParams } from "react-router-dom";
import ContentUpdate from "../components/CMS/ContentUpdate";

export default () => {
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [selectedData, updateSelectedData] = useState("update");
  const BrandDetails = useSelector((state) => state.brandDetails.brandProfile);

  console.log(BrandDetails, "outside in");
  let project = useSelector((state) => state.userDetails.project);
  let data = useSelector((state) => state.cms.pages["About Us"]);
  let userData = useSelector((state) => state.cms.userData);
  let productData = useSelector((state) => state.product.product);
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  //-----------hide/show-------
  const { token } = useParams();

  if (token) {
    let cmsStyle = document.getElementsByClassName("hoverText");
    console.log(cmsStyle, "cmsStyle");
    for (let i = 0; i < cmsStyle.length; i++) {
      cmsStyle[i].onClick = function () {
        return false;
      };
      cmsStyle[i].classList.add("hoverText1");
    }
  }
  //----------------hide/show-----------

  useEffect(() => {
    console.log(data, "Home");
    setMounted(true);
  }, [dispatch, setMounted]);
  console.log(data, "outside");
  console.log(productData, "hii");

  async function isSideBarOpen(id) {
    if (token) {
      await updateSelectedData(id);
      setIsShow(true);
      console.log(isShow, selectedData, id, "show");
    }
  }

  function closeSidebar(value) {
    setIsShow(value);
    updateSelectedData("");
    // selectedData = "";

    console.log(selectedData, "show");
  }

  function getFavicon(value) {
    return value
      ? value.favIcon
        ? process.env.REACT_APP_STORAGE_URL_2 + brandProfile.favIcon
        : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/logo.png"
      : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/logo.png";
  }
  const baseURL = process.env.REACT_APP_STORAGE_URL;
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index1"} />
      <Helmet>
        <title>Black-Frames-Studio</title>
        <meta
          name={"description"}
          content={"Web site created using quarkly.io"}
        />
        <link
          rel={"shortcut icon"}
          href={getFavicon(brandProfile)}
          type={"image/x-icon"}
        />
      </Helmet>
      {/* <Components.Nav>
        <Override slot="link1" />
      </Components.Nav> */}
      <Components.NavabarHeader>
        <Override slot="link1" />
      </Components.NavabarHeader>
      {/* <Section
        position="relative"
        quarkly-title="hero"
        padding="106px 0 60px 0"
      >
        <Override
          slot="SectionContent"
          flex-direction="row"
          margin="40px auto 40px auto"
          align-items="center"
          justify-content="center"
          height="300px"
        />
        <Image
          display="block"
          position="absolute"
          width="100%"
          z-index="-1"
          top="auto"
          left="auto"
          bottom="auto"
          right="auto"
          height="100%"
          object-fit="cover"
          src={
            data
              ? data["6607622748"]
              : "https://images.unsplash.com/photo-1682686580433-2af05ee670ad?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
          }
          object-position="50% 80%"
          background="linear-gradient(450deg,#ffdeae 0%,#cfb545 100%) 0 0 no-repeat"
          id="6607622748"
          className="hoverText"
          onClick={() => isSideBarOpen("6607622748")}
        />
        <Box
          min-width="100px"
          min-height="100px"
          position="absolute"
          display="flex"
          align-items="center"
          justify-content="center"
          flex-direction="row"
          width="100%"
          height="100%"
          z-index="-1"
          opacity="0.9"
          background="linear-gradient(450deg,rgba(0, 0, 0, 0.8) 0%,rgba(0, 0, 0, 0.8) 100%) 0 0 no-repeat"
        />
        <Box
          width="100%"
          display="flex"
          sm-display="block"
          align-items="center"
          justify-content="space-between"
        >
          <Text
            margin="0px 0px 0px 0px"
            font="--headline2"
            height="max-content"
            color="--darkL1"
            display="flex"
            flex-direction="row"
            sm-font-size="35px"
            sm-margin-bottom="15px"
            id="8636314037"
            className="hoverText"
            onClick={() => isSideBarOpen("8636314037")}
          >
           
            <Slide left>{data ? data["8636314037"] : "About Us"}</Slide>
          </Text>
          <Box
            width="50%"
            sm-width="100%"
            display="flex"
            flex-direction="column"
            height="max-content"
          >
            <Text
              margin="0px 0px 0px 0px"
              font="--p2"
              color="--darkL1"
              sm-font="--p5"
              sm-font-size="15px"
              sm-margin="0px 0px 16px 0px"
              id="0645342312"
              className="hoverText"
              onClick={() => isSideBarOpen("0645342312")}
            >
              <Slide right>
                {" "}
                {data
                  ? data["0645342312"]
                  : "We're not your average photographers"}{" "}
              </Slide>
            </Text>
          </Box>
        </Box>
      </Section> */}
      <Section
        position="relative"
        md-height="max-content"
        padding="0px 0 0px 0"
        className="cust-mt"
      >
        <Override
          slot="SectionContent"
          position="static"
          align-items="center"
          justify-content="center"
          height="500px"
          max-width="100%"
          width="100%"
          sm-margin="0px auto 0px auto"
          md-margin="20px auto 20px auto"
          md-height="300px"
          lg-height="400px"
          sm-height="350px"
        />
        <Image
          src={
            data
              ? data["6607622748"]
              : "https://nimbuscluster.blob.core.windows.net/server01/black-frames-studio/blackframes/Black%20frames%20images/imagess/MicrosoftTeams-image%20(1).png"
          }
          display="block"
          position="absolute"
          bottom="auto"
          top="0px"
          left="0px"
          right="auto"
          width="100%"
          height="500px"
          object-fit="cover"
          object-position="50% 80%"
          sm-bottom="139.59555555555553px"
          sm-height="351.51944444444445px"
          sm-top="0px"
          md-bottom="179.059px"
          md-height="312.094px"
          md-top="0px"
          id="6607622748"
          lg-bottom="1.02878546258224px"
          lg-height="500px"
          lg-top="0px"
          className="hoverText"
          z-index="-1"
          background=" linear-gradient(450deg, rgb(255, 222, 174) 0%, rgb(207, 181, 69) 100%) 0px 0px no-repeat;"
          onClick={() => isSideBarOpen("6607622748")}
        />
        <Box
          min-width="100px"
          min-height="100px"
          position="absolute"
          display="flex"
          align-items="center"
          justify-content="center"
          flex-direction="row"
          width="100%"
          height="100%"
          z-index="-1"
          opacity="0.9"
          background="linear-gradient(450deg,rgba(0, 0, 0, 0.8) 0%,rgba(0, 0, 0, 0.8) 100%) 0 0 no-repeat"
        />
        <Box
          margin="90px 0px 90px 0px"
          md-min-height="max-content"
          md-margin="48px 0px 60px 0px"
          color="--darkL1"
          opacity="0.9"
        >
          <Text
            margin="0px 0px 6px 0px"
            font="--headline3"
            color="--secondery"
            text-align="center"
            sm-font="--subheadline2"
            md-font="--headline5"
            lg-font="--headline5"
            id="8636314037"
            className="hoverText"
            onClick={() => isSideBarOpen("8636314037")}
          >
            <Reveal effect="fadeInUp">
              {data ? data["8636314037"] : "About Us"}
            </Reveal>
          </Text>
          <Text
            margin="0px 20px 0px 20px"
            sm-margin="0px 10px 0px 10px"
            font="--subheadline4"
            text-align="center"
            sm-font="--subheadline12"
            md-font="--subheadline4"
            lg-font="--subheadline4"
            color="--darkL1"
            md-color="--darkL1"
            id="0645342312"
            className="hoverText"
            onClick={() => isSideBarOpen("0645342312")}
          >
            <Reveal effect="fadeInUp">
              {" "}
              {data
                ? data["0645342312"]
                : "We're not your average photographers"}{" "}
            </Reveal>
          </Text>
        </Box>
      </Section>

      <Section
        position="static"
        padding="100px 0 40px 0"
        md-justify-content="center"
        md-padding="100px 0px 40px 0px"
      >
        <Override
          slot="SectionContent"
          flex-direction="row"
          align-items="center"
          justify-content="center"
          height="600px"
          md-flex-direction="column"
          md-margin="0px 20px 0px 20px"
          md-width="100%"
          className="cust-w100"
        />
        <Reveal effect="fadeInUp">
          <Box
            width="100%"
            display="flex"
            align-items="center"
            justify-content="space-between"
            margin="0px 20px 0px 0px"
            position="relative"
            lg-margin="0px 40px 0px 0px"
            md-margin="0px 0px 40px 0px"
          >
            {/* <Image
              display="block"
              position="absolute"
              width="208.797px"
              z-index="1"
              top="-110px"
              left="auto"
              bottom="auto"
              right="35px"
              height="214.969px"
              object-fit="cover"
              src={
                data
                  ? data["3906382959"]
                  : "https://images.unsplash.com/photo-1516724562728-afc824a36e84?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
              }
              object-position="50% 80%"
              background="--primaryGradient"
              border-radius="20px 20px 20px 20px"
              lg-bottom="auto"
              lg-height="184.40904651988637px"
              lg-left="auto"
              lg-right="52px"
              lg-top="-94px"
              lg-width="184.06074946732957px"
              md-bottom="auto"
              md-left="auto"
              md-right="-93px"
              md-top="-96px"
              sm-bottom="auto"
              sm-height="100.82857578822546px"
              sm-left="auto"
              sm-right="-20px"
              sm-top="-20px"
              sm-width="100.907177734375px"
              id="3906382959"
              className="hoverText"
              onClick={() => isSideBarOpen("3906382959")}
            /> */}
            <Image
              display="block"
              position="static"
              width="80%"
              z-index="-1"
              top="auto"
              left="auto"
              bottom="auto"
              right="auto"
              object-fit="cover"
              src={
                data
                  ? data["5911380608"]
                  : "https://nimbuscluster.blob.core.windows.net/server01/black-frames-studio/blackframes/Black%20frames%20images/imagess/aboutus.jpg"
              }
              object-position="50% 35%"
              background="--primaryGradient"
              height="550px"
              border-radius="32px 32px 20px 32px"
              lg-height="380px"
              md-width="100%"
              md-margin="0px 0px 0px 0px"
              sm-height="420px"
              id="5911380608"
              className="hoverText"
              onClick={() => isSideBarOpen("5911380608")}
            />
            <Image
              src="https://uploads.quarkly.io/649bdc8a8bd7e600185a1390/images/Frame%203.png?v=2023-07-11T04:40:43.607Z"
              display="block"
              bottom="auto"
              height="133.88888888888889px"
              left="21px"
              position="absolute"
              right="auto"
              top="-72px"
              width="222.8888888888889px"
              z-index="-2"
              md-display="none"
            />
          </Box>
        </Reveal>
        <Reveal effect="fadeInUp">
          <Box
            width="100%"
            display="flex"
            flex-direction="column"
            height="max-content"
            md-width="100%"
          >
            <Text
              margin="0px 0px 8px 0px"
              font="--subheadline2"
              text-align="left"
              sm-font="--subheadline4"
              md-font="--subheadline4"
              lg-font="--subheadline4"
              color="--dark"
              id="7768994448"
              sm-margin="0px 0px 12px 0px"
              onClick={() => isSideBarOpen("7768994448")}
            >
              {data ? data["7768994448"] : "CAPTURING EVERY PRECIOUS MOMENTS"}
            </Text>
            <Hr
              min-height={0}
              min-width="5%"
              margin="0px 0px 18px 0px"
              width="25%"
              background="--primaryGradient"
              height="2px"
              padding="0px 0px 2px 0px"
              border-radius="4px"
            />
            <Text
              margin="0px 0px 0px 0px"
              font="--p2"
              color="--grey"
              sm-font="--p5"
              sm-margin="0px 0px 16px 0px"
              id="0586533336"
              className="hoverText"
              onClick={() => isSideBarOpen("0586533336")}
            >
              {data ? data["0586533336"] : "WE'RE NOT YOUR"}
            </Text>
          </Box>
        </Reveal>
      </Section>
      <Section
        background="--backgroundDark"
        position="static"
        z-index="0"
        sm-align-items="center"
        sm-justify-content="center"
        padding="0px 0 0px 0"
        lg-padding="40px 0 40px 0"
        sm-padding="20px 0 20px 0"
        display="none"
      >
        <Override
          slot="SectionContent"
          width="100%"
          align-items="center"
          justify-content="center"
          margin="80px 0 80px 0"
          max-width="100%"
          padding="0px 80px 0px 80px"
          sm-padding="0px 20px 0px 20px"
          sm-margin="26px 0 26px 0"
          sm-height="max-content"
          md-padding="0px 20px 0px 20px"
          md-align-items="center"
          md-justify-content="center"
          md-margin="24px 0 24px 0"
          lg-padding="0px 40px 0px 40px"
          lg-margin="16px 0 16px 0"
          md-height="fit-content"
          flex-direction="row"
          className="cust-w100"
        />
        <Reveal>
          <Swiper
            spaceBetween={35}
            slidesPerView={4}
            observer={true}
            observeParents={true}
            modules={[Navigation]}
            className="mySwiper"
            autoplay={{
              delay: "1000",
            }}
            loop
            navigation={{
              nextEl: ".image-swiper-button-next",
              prevEl: ".image-swiper-button-prev",
            }}
            breakpoints={{
              1536: {
                slidesPerView: 1.1,
                // spaceBetween: 20,
              },
              1280: {
                slidesPerView: 1.1,
                // spaceBetween: 20,
              },
              1024: {
                slidesPerView: 1.1,
                // spaceBetween: 20,
              },
              991: {
                slidesPerView: 1.1,
                // spaceBetween: 30,
              },
              640: {
                slidesPerView: 1,
                // spaceBetween: 30,
              },
              425: {
                slidesPerView: 1,
                // spaceBetween: 50,
              },
              325: {
                slidesPerView: 1,
                // spaceBetween: 50,
              },
            }}
          >
            <div class="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer z-10">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div class="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer z-10">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>
            <SwiperSlide>
              <Image
                src={
                  data
                    ? data["7253004303"]
                    : "https://images.unsplash.com/photo-1601506521937-0121a7fc2a6b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
                }
                display="block"
                width="100%"
                height="560px"
                object-fit="cover"
                margin="0px 28px 0px 28px"
                lg-height="460px"
                md-margin="0px 12px 0px 12px"
                md-height="400px"
                sm-height="290px"
                sm-margin="0px 10px 0px 10px"
                id="7253004303"
                className="hoverText"
                onClick={() => isSideBarOpen("7253004303")}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Image
                src={
                  data
                    ? data["7253004303"]
                    : "https://images.unsplash.com/photo-1601506521937-0121a7fc2a6b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
                }
                display="block"
                width="100%"
                height="560px"
                object-fit="cover"
                margin="0px 28px 0px 28px"
                lg-height="460px"
                md-margin="0px 12px 0px 12px"
                md-height="400px"
                sm-height="290px"
                sm-margin="0px 10px 0px 10px"
                id="7253004303"
                className="hoverText"
                onClick={() => isSideBarOpen("7253004303")}
              />
            </SwiperSlide>
          </Swiper>
        </Reveal>
        {/* <Icon
          category="md"
          icon={MdKeyboardArrowLeft}
          size="54px"
          color="--primary"
          id="3261563182"
        />
        
        <Icon
          category="md"
          icon={MdKeyboardArrowRight}
          size="54px"
          color="--primary"
          id="3268880350"
        /> */}
      </Section>
      <Section
        position="relative"
        align-items="center"
        justify-content="center"
        padding="0px 0 0px 0"
        lg-padding="40px 0 0px 0"
      >
        <Override
          slot="SectionContent"
          margin="0px 80px 20px 80px"
          sm-margin="8px auto 8px auto"
          sm-align-items="center"
          sm-justify-content="center"
          md-margin="24px auto 24px auto"
          lg-margin="16px auto 16px auto"
          className="cust-w100"
        />
        <Reveal>
          <Box
            margin="0px 0px 0px 0px"
            sm-padding="0px 24px 0px 24px"
            md-min-height="max-content"
            md-margin="0px 0px 32px 0px"
            align-items="center"
            display="flex"
            flex-direction="column"
            justify-content="center"
            id="5748486445"
          >
            <Text
              margin="0px 0px 6px 0px"
              font="--subheadline2"
              color="--dark"
              text-align="center"
              sm-font="--subheadline6"
              md-font="--subheadline5"
              lg-font="--subheadline5"
              id="1077573819"
              className="hoverText"
              onClick={() => isSideBarOpen("1077573819")}
              border-color="--color-secondery"
            >
              {data ? data["1077573819"] : "SERVICES"}
            </Text>
            <Hr
              min-height={0}
              min-width="5%"
              margin="0px 0px 8px 0px"
              width="5%"
              background="--primaryGradient"
              height="2px"
              padding="0px 0px 2px 0px"
              border-radius="4px"
            />
            <Text
              margin="0px 0px 0px 0px"
              font="--subheadline3"
              text-align="center"
              sm-font="--subheadline4"
              md-font="--subheadline4"
              lg-font="--subheadline4"
              id="5803582221"
              display="none"
            >
              UNLEASHING THE POWER OF VISUAL STORYTELLING
            </Text>
          </Box>
        </Reveal>
        <Box
          display="grid"
          grid-template-columns="repeat(2, 1fr)"
          sm-grid-template-columns="repeat(1, 1fr)"
          lg-margin="0px auto 0px auto"
          grid-gap="20px"
          margin="60px 0px 0px 0px"
          className="cust-w100"
        >
          <Reveal effect="fadeInUp">
            <Box
              margin="0px 16px 0px 0px"
              display="flex"
              flex-direction="column"
              justify-content="center"
              padding="24px 24px 24px 24px"
              sm-padding="16px 16px 16px 16px"
              md-padding="8px 1px 8px 16px"
              md-margin="0px 0 0px 0px"
              lg-margin="0px 0 0px 0px"
            >
              <Text
                margin="0px 0px 12px 0px"
                font="--subheadline4"
                color="--secondery"
                sm-font="--subheadline5"
                md-font="--subheadline5"
                lg-font="--subheadline5"
                id="0553666348"
                border-color="--color-secondery"
                className="hoverText"
                onClick={() => isSideBarOpen("0553666348")}
              >
                {data ? data["0553666348"] : "PHOTOSHOOT"}
              </Text>
              <Text
                margin="0px 0px 0px 0px"
                font="--p2"
                color="--grey"
                text-align="left"
                sm-font="--p5"
                md-font="--p4"
                lg-font="--p4"
                id="2624217280"
                className="hoverText"
                onClick={() => isSideBarOpen("2624217280")}
              >
                {data ? data["2624217280"] : "CAPTURE MOMENTS"}
                <br />
              </Text>
            </Box>
          </Reveal>
          <Reveal effect="fadeInUp">
            <Box
              margin="0px 16px 0px 0px"
              display="flex"
              flex-direction="column"
              align-items="flex-start"
              justify-content="center"
              padding="24px 24px 24px 24px"
              sm-padding="16px 16px 16px 16px"
              md-padding="8px 1px 8px 16px"
              md-margin="0px 0 0px 0px"
              lg-margin="0px 0 0px 0px"
            >
              <Text
                margin="0px 0px 12px 0px"
                font="--subheadline4"
                color="--secondery"
                sm-font="--subheadline5"
                md-font="--subheadline5"
                lg-font="--subheadline5"
                id="9979804747"
                className="hoverText"
                onClick={() => isSideBarOpen("9979804747")}
              >
                {data ? data["9979804747"] : "STUDIO RENT"}
              </Text>
              <Text
                margin="0px 0px 0px 0px"
                font="--p2"
                color="--grey"
                text-align="left"
                sm-font="--p5"
                md-font="--p4"
                lg-font="--p4"
                id="3150652342"
                className="hoverText"
                onClick={() => isSideBarOpen("3150652342")}
              >
                {data ? data["3150652342"] : "CAPTURE YOUR MOMENTS"}
                <br />
              </Text>
            </Box>
          </Reveal>
        </Box>
      </Section>
      <Section
        position="relative"
        align-items="center"
        justify-content="center"
        padding="0px 0 0px 0"
        sm-padding="0px 0px 0px 0px"
        md-padding="0px 0px 0px 0px"
      >
        <Override
          slot="SectionContent"
          margin="80px 80px 80px 80px"
          sm-margin="18px 20px 18px 20px"
          sm-align-items="center"
          sm-justify-content="center"
          lg-margin="16px auto 16px auto"
          sm-width="100%"
          md-margin="40px 0px 40px 0px"
        />
        <Reveal>
          <Box
            margin="0px 0px 0px 0px"
            sm-padding="0px 24px 0px 24px"
            md-min-height="max-content"
            md-margin="0px 0px 32px 0px"
            align-items="center"
            display="flex"
            flex-direction="column"
            justify-content="center"
          >
            <Text
              margin="0px 0px 6px 0px"
              font="--subheadline2"
              color="--dark"
              text-align="center"
              sm-font="--subheadline6"
              md-font="--subheadline5"
              lg-font="--subheadline5"
              id="7066463582"
              border-color="--color-secondery"
              className="hoverText"
              onClick={() => isSideBarOpen("7066463582")}
            >
              {data ? data["7066463582"] : "HOW WE WORK"}
            </Text>
            <Hr
              min-height={0}
              min-width="5%"
              margin="0px 0px 8px 0px"
              width="5%"
              background="--primaryGradient"
              height="2px"
              padding="0px 0px 2px 0px"
              border-radius="4px"
            />
            <Text
              margin="0px 0px 0px 0px"
              font="--subheadline3"
              text-align="center"
              sm-font="--subheadline4"
              md-font="--subheadline4"
              lg-font="--subheadline4"
              id="5803582221"
              display="none"
            >
              UNLEASHING THE POWER OF VISUAL STORYTELLING
            </Text>
          </Box>
        </Reveal>
        <Box
          grid-template-columns="repeat(2, 1fr)"
          sm-grid-template-columns="repeat(1, 1fr)"
          lg-margin="0px auto 0px auto"
          grid-gap="20px"
          margin="60px 0px 0px 0px"
          display="grid"
          align-items="center"
        >
          <Reveal effect="fadeInUp">
            <Box
              flex-direction="column"
              justify-content="flex-start"
              padding="24px 24px 24px 24px"
              sm-padding="16px 16px 16px 16px"
              md-padding="8px 1px 8px 16px"
              md-margin="0px 0 0px 0px"
              lg-margin="0px 0 0px 0px"
              border-width="1px"
              border-style="solid"
              border-color="--color-dark"
              width="100%"
              align-items="flex-start"
              flex="0 0 auto"
              height="12rem"
              sm-height="10rem"
            >
              <Text
                margin="0px 0px 14px 0px"
                font="--subheadline4"
                color="--secondery"
                sm-font="--subheadline5"
                md-font="--subheadline5"
                lg-font="--subheadline5"
                id="3852971144"
                border-color="--color-secondery"
                className="hoverText"
                onClick={() => isSideBarOpen("3852971144")}
              >
                {data ? data["3852971144"] : "CONCEPT"}
              </Text>
              <Text
                margin="0px 0px 0px 0px"
                font="--p2"
                color="--grey"
                text-align="left"
                sm-font="--p5"
                md-font="--p4"
                lg-font="--p4"
                id="5480434371"
                letter-spacing="0.3px"
                className="hoverText"
                onClick={() => isSideBarOpen("5480434371")}
              >
                <Span
                  overflow-wrap="normal"
                  word-break="normal"
                  white-space="normal"
                  text-indent="0"
                  text-overflow="clip"
                  hyphens="manual"
                  user-select="auto"
                  pointer-events="auto"
                  letter-spacing="0.5px"
                >
                  {data ? data["5480434371"] : "Unleash the power"}
                </Span>
                <br />
              </Text>
            </Box>
          </Reveal>
          <Reveal effect="fadeInUp">
            <Box
              margin="0px 0px 0px 0px"
              flex-direction="column"
              align-items="flex-start"
              justify-content="flex-start"
              padding="24px 24px 24px 24px"
              sm-padding="16px 16px 16px 16px"
              md-padding="8px 1px 8px 16px"
              md-margin="0px 0 0px 0px"
              lg-margin="0px 0 0px 0px"
              border-width="1px"
              border-style="solid"
              border-color="--color-dark"
              width="100%"
              flex="0 0 auto"
              height="12rem"
              sm-height="10rem"
            >
              <Text
                margin="0px 0px 14px 0px"
                font="--subheadline4"
                color="--secondery"
                sm-font="--subheadline5"
                md-font="--subheadline5"
                lg-font="--subheadline5"
                id="0702647194"
                className="hoverText"
                onClick={() => isSideBarOpen("0702647194")}
              >
                {data ? data["0702647194"] : "PRODUCTION"}
              </Text>
              <Text
                margin="0px 0px 0px 0px"
                font="--p2"
                color="--grey"
                text-align="left"
                sm-font="--p5"
                md-font="--p4"
                lg-font="--p4"
                id="3683735084"
                letter-spacing="0.3px"
                className="hoverText"
                onClick={() => isSideBarOpen("3683735084")}
              >
                <Span
                  overflow-wrap="normal"
                  word-break="normal"
                  white-space="normal"
                  text-indent="0"
                  text-overflow="clip"
                  hyphens="manual"
                  user-select="auto"
                  pointer-events="auto"
                >
                  <Span
                    overflow-wrap="normal"
                    word-break="normal"
                    white-space="normal"
                    text-indent="0"
                    text-overflow="clip"
                    hyphens="manual"
                    user-select="auto"
                    pointer-events="auto"
                    letter-spacing="0.5px"
                  >
                    {data ? data["3683735084"] : "Seamlessly orchestrate"}
                  </Span>
                </Span>
                <br />
              </Text>
            </Box>
          </Reveal>
          <Reveal effect="fadeInUp">
            <Box
              flex-direction="column"
              align-items="flex-start"
              justify-content="flex-start"
              padding="24px 24px 24px 24px"
              sm-padding="16px 16px 16px 16px"
              md-padding="8px 1px 8px 16px"
              md-margin="0px 0 0px 0px"
              lg-margin="0px 0 0px 0px"
              border-width="1px"
              border-style="solid"
              border-color="--color-dark"
              width="100%"
              flex="0 0 auto"
              height="12rem"
              sm-height="10rem"
            >
              <Text
                margin="0px 0px 14px 0px"
                font="--subheadline4"
                color="--secondery"
                sm-font="--subheadline5"
                md-font="--subheadline5"
                lg-font="--subheadline5"
                id="5210074955"
                className="hoverText"
                onClick={() => isSideBarOpen("5210074955")}
              >
                {data ? data["5210074955"] : "PHOTOGRAPHY"}
              </Text>
              <Text
                margin="0px 0px 0px 0px"
                font="--p2"
                color="--grey"
                text-align="left"
                sm-font="--p5"
                md-font="--p4"
                lg-font="--p4"
                id="4505190079"
                letter-spacing="0.3px"
                className="hoverText"
                onClick={() => isSideBarOpen("4505190079")}
              >
                <Span
                  overflow-wrap="normal"
                  word-break="normal"
                  white-space="normal"
                  text-indent="0"
                  text-overflow="clip"
                  hyphens="manual"
                  user-select="auto"
                  pointer-events="auto"
                  letter-spacing="0.5px"
                >
                  {data ? data["4505190079"] : "Embrace the artistry"}
                </Span>
              </Text>
            </Box>
          </Reveal>
          <Reveal effect="fadeInUp">
            <Box
              margin="0px 0px 0px 0px"
              flex-direction="column"
              align-items="flex-start"
              justify-content="flex-start"
              padding="24px 24px 24px 24px"
              sm-padding="16px 16px 16px 16px"
              md-padding="8px 1px 8px 16px"
              md-margin="0px 0 0px 0px"
              lg-margin="0px 0 0px 0px"
              border-width="1px"
              border-style="solid"
              border-color="--color-dark"
              width="100%"
              flex="0 0 auto"
              height="12rem"
              sm-height="10rem"
            >
              <Text
                margin="0px 0px 14px 0px"
                font="--subheadline4"
                color="--secondery"
                sm-font="--subheadline5"
                md-font="--subheadline5"
                lg-font="--subheadline5"
                id="6609890469"
                className="hoverText"
                onClick={() => isSideBarOpen("6609890469")}
              >
                {data ? data["6609890469"] : "POST PROCESSING"}
              </Text>
              <Text
                margin="0px 0px 0px 0px"
                font="--p2"
                color="--grey"
                text-align="left"
                sm-font="--p5"
                md-font="--p4"
                lg-font="--p4"
                id="4688171270"
                letter-spacing="0.3px"
                className="hoverText"
                onClick={() => isSideBarOpen("4688171270")}
              >
                <Span
                  overflow-wrap="normal"
                  word-break="normal"
                  white-space="normal"
                  text-indent="0"
                  text-overflow="clip"
                  hyphens="manual"
                  user-select="auto"
                  pointer-events="auto"
                  letter-spacing="0.5px"
                >
                  {data ? data["4688171270"] : "Elevate your photographs"}
                </Span>
              </Text>
            </Box>
          </Reveal>
        </Box>
      </Section>
      <Section
        position="static"
        padding="24px 80px 60px 80px"
        sm-padding="24px 20px 24px 20px"
      >
        <Override
          slot="SectionContent"
          flex-direction="column"
          margin="0px auto 0px auto"
          align-items="center"
          justify-content="center"
          max-width="100%"
          width="100%"
        />
        <Reveal>
          <Box
            width="100%"
            display="flex"
            flex-direction="column"
            height="max-content"
            align-items="center"
            justify-content="center"
            margin="0px 0px 38px 0px"
          >
            <Text
              margin="0px 0px 6px 0px"
              font="--subheadline2"
              color="--dark"
              text-align="center"
              sm-font="--subheadline6"
              md-font="--subheadline5"
              lg-font="--subheadline5"
              id="1217957068"
              className="hoverText"
              onClick={() => isSideBarOpen("1217957068")}
            >
              {data ? data["1217957068"] : "OUR TEAM"}
            </Text>
            <Hr
              min-height={0}
              min-width="5%"
              margin="0px 0px 8px 0px"
              width="25%"
              background="--primaryGradient"
              height="2px"
              padding="0px 0px 2px 0px"
              border-radius="4px"
            />
            <Text
              margin="0px 0px 16px 0px"
              font="--subheadline4"
              text-align="left"
              sm-font="--subheadline4"
              md-font="--subheadline4"
              lg-font="--subheadline4"
              color="--dark"
              id="8587418388"
              display="none"
            >
              Capturing Every precious moments
            </Text>
          </Box>
        </Reveal>
        <Box
          width="100%"
          display="grid"
          grid-template-columns="repeat(2, 1fr)"
          sm-grid-template-columns="repeat(1, 1fr)"
          grid-gap="24px"
          height="600px"
          lg-height="fit-content"
          //  className="cust-wh100"
        >
          <Reveal effect="fadeInUp">
            <Box
              width="100%"
              height="100%"
              display="flex"
              grid-template-columns="repeat(4, 1fr)"
              flex-direction="column"
              position="relative"
              min-height="100px"
              min-width="100px"
              lg-height="500px"
              md-height="400px"
              sm-height="320px"
            >
              <Box
                position="absolute"
                bottom={0}
                right="0px"
                left="0px"
                height="100%"
                top={0}
                display="flex"
                justify-content="flex-end"
                align-items="center"
                flex-direction="column"
                background="linear-gradient(0deg,rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%),url(https://uploads.quarkly.io/6459d5984b38c40020065812/images/6e7b03_26f58cf3219649d88968594f7c688c02~mv2_d_2048_2048_s_2.webp?v=2023-06-26T09:21:01.794Z) 80%/100%"
                hover-display="flex"
                opacity="1"
                hover-opacity="1"
              >
                <Text
                  margin="0px 0px 6px 0px"
                  font="--subheadline4"
                  color="--indigo"
                  text-align="center"
                  sm-font="--subheadline6"
                  md-font="--subheadline5"
                  lg-font="--subheadline5"
                >
                  Mohan Sharma
                </Text>
                <Text
                  margin="0px 0px 24px 0px"
                  font="--subheadline5"
                  color="--darkL1"
                  text-align="center"
                  sm-font="--subheadline6"
                  md-font="--subheadline5"
                  lg-font="--subheadline5"
                >
                  video editor
                </Text>
              </Box>
              <Box
                position="absolute"
                bottom={0}
                right="0px"
                left="0px"
                height="100%"
                top={0}
                display="flex"
                justify-content="flex-end"
                align-items="center"
                flex-direction="column"
                background="linear-gradient(0deg,rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%),url(https://uploads.quarkly.io/6459d5984b38c40020065812/images/6e7b03_26f58cf3219649d88968594f7c688c02~mv2_d_2048_2048_s_2.webp?v=2023-06-26T09:21:01.794Z) 80%/100%"
                hover-display="flex"
                opacity="1"
                hover-opacity="1"
              >
                <Text
                  margin="0px 0px 6px 0px"
                  font="--subheadline4"
                  color="--indigo"
                  text-align="center"
                  sm-font="--subheadline6"
                  md-font="--subheadline5"
                  lg-font="--subheadline5"
                >
                  Mohan Sharma
                </Text>
                <Text
                  margin="0px 0px 24px 0px"
                  font="--subheadline5"
                  color="--darkL1"
                  text-align="center"
                  sm-font="--subheadline6"
                  md-font="--subheadline5"
                  lg-font="--subheadline5"
                >
                  video editor
                </Text>
              </Box>
              <Box
                position="absolute"
                bottom={0}
                right="0px"
                left="0px"
                height="100%"
                top={0}
                display="flex"
                justify-content="flex-end"
                align-items="center"
                flex-direction="column"
                background="linear-gradient(0deg,rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%),url(https://nimbuscluster.blob.core.windows.net/server01/black-frames-studio/blackframes/Black%20frames%20images/imagess/toshit.jpg) 80%/100%"
                hover-display="flex"
                opacity="1"
                hover-opacity="1"
                id="0774990390"
                className="hoverText"
              >
                <Text
                  margin="0px 0px 6px 0px"
                  font="--subheadline10"
                  color="--primary"
                  text-align="center"
                  sm-font="--subheadline6"
                  md-font="--subheadline5"
                  lg-font="--subheadline5"
                  className="hoverText"
                  onClick={() => isSideBarOpen("9578685040")}
                  id="9578685040"
                >
                  {data ? data["9578685040"] : "Toshit Bhatnagar"}
                </Text>
                <Text
                  margin="0px 0px 6px 0px"
                  font="--subheadline8"
                  color="--darkL1"
                  text-align="center"
                  sm-font="--subheadline6"
                  md-font="--subheadline5"
                  lg-font="--subheadline5"
                  className="hoverText"
                  onClick={() => isSideBarOpen("9277539321")}
                  id="9277539321"
                >
                  {data ? data["9277539321"] : "video editor"}
                </Text>
                <Text
                  margin="0px auto 24px auto"
                  width="80%"
                  sm-width="100%"
                  font="--subheadline9"
                  color="--darkL1"
                  text-align="center"
                  sm-font="--subheadline6"
                  md-font="--subheadline5"
                  sm-padding="5px"
                  lg-font="--subheadline5"
                  className="hoverText"
                  id="3785788279"
                  onClick={() => isSideBarOpen("3785788279")}
                >
                  {data
                    ? data["3785788279"]
                    : "A multi-talended visionary whi wears many hats. A skilled photographer, a visionary producer, and an exceptional showrunner!"}
                </Text>
              </Box>
            </Box>
          </Reveal>
          <Reveal effect="fadeInUp">
            <Box
              width="100%"
              height="100%"
              display="flex"
              grid-template-columns="repeat(4, 1fr)"
              flex-direction="column"
              position="relative"
              min-height="100px"
              min-width="100px"
              lg-height="500px"
              md-height="400px"
              sm-height="320px"
              // className="cust-w100"
            >
              <Box
                position="absolute"
                bottom={0}
                right="0px"
                left="0px"
                height="100%"
                top={0}
                display="flex"
                justify-content="flex-end"
                align-items="center"
                flex-direction="column"
                background="linear-gradient(0deg,rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%),url(https://nimbuscluster.blob.core.windows.net/server01/black-frames-studio/blackframes/tanya.webp) 80%/100%"
                hover-display="flex"
                opacity="1"
                hover-opacity="1"
                id="0774990390"
                className="hoverText"
              >
                <Text
                  margin="0px 0px 6px 0px"
                  font="--subheadline4"
                  color="--indigo"
                  text-align="center"
                  sm-font="--subheadline6"
                  md-font="--subheadline5"
                  lg-font="--subheadline5"
                >
                  Mohan Sharma
                </Text>
                <Text
                  margin="0px 0px 24px 0px"
                  font="--subheadline5"
                  color="--darkL1"
                  text-align="center"
                  sm-font="--subheadline6"
                  md-font="--subheadline5"
                  lg-font="--subheadline5"
                >
                  video editor
                </Text>
              </Box>
              <Box
                position="absolute"
                bottom={0}
                right="0px"
                left="0px"
                height="100%"
                top={0}
                display="flex"
                justify-content="flex-end"
                align-items="center"
                flex-direction="column"
                background="linear-gradient(0deg,rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%),url(https://uploads.quarkly.io/6459d5984b38c40020065812/images/6e7b03_26f58cf3219649d88968594f7c688c02~mv2_d_2048_2048_s_2.webp?v=2023-06-26T09:21:01.794Z) 80%/100%"
                hover-display="flex"
                opacity="1"
                hover-opacity="1"
              >
                <Text
                  margin="0px 0px 6px 0px"
                  font="--subheadline4"
                  color="--indigo"
                  text-align="center"
                  sm-font="--subheadline6"
                  md-font="--subheadline5"
                  lg-font="--subheadline5"
                >
                  Mohan Sharma
                </Text>
                <Text
                  margin="0px 0px 6px 0px"
                  font="--subheadline5"
                  color="--darkL1"
                  text-align="center"
                  sm-font="--subheadline6"
                  md-font="--subheadline5"
                  lg-font="--subheadline5"
                >
                  video editor
                </Text>
              </Box>

              <Box
                position="absolute"
                bottom={0}
                right="0px"
                left="0px"
                height="100%"
                top={0}
                display="flex"
                justify-content="flex-end"
                align-items="center"
                flex-direction="column"
                background="linear-gradient(0deg,rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%),url(https://nimbuscluster.blob.core.windows.net/server01/black-frames-studio/blackframes/Black%20frames%20images/imagess/tanya.jpg) 80%/100%"
                hover-display="flex"
                opacity="1"
                hover-opacity="1"
              >
                <Text
                  margin="0px 0px 6px 0px"
                  font="--subheadline10"
                  color="--primary"
                  text-align="center"
                  sm-font="--subheadline6"
                  md-font="--subheadline5"
                  lg-font="--subheadline5"
                  className="hoverText"
                  onClick={() => isSideBarOpen("1633944225")}
                  id="1633944225"
                >
                  {data ? data["1633944225"] : "Tanya Prasad"}
                </Text>
                <Text
                  margin="0px 0px 6px 0px"
                  font="--subheadline8"
                  color="--darkL1"
                  text-align="center"
                  sm-font="--subheadline6"
                  md-font="--subheadline5"
                  lg-font="--subheadline5"
                  className="hoverText"
                  onClick={() => isSideBarOpen("0260182505")}
                  id="0260182505"
                >
                  {data ? data["0260182505"] : "video editor"}
                </Text>
                <Text
                  margin="0px auto 24px auto"
                  width="80%"
                  sm-width="100%"
                  font="--subheadline9"
                  color="--darkL1"
                  text-align="center"
                  sm-font="--subheadline6"
                  sm-padding="5px"
                  md-font="--subheadline5"
                  lg-font="--subheadline5"
                  className="hoverText"
                >
                  An artistic powerhouse with a camera in hand. As a skilled
                  photographer, passionate artist, and visionary creative
                  director, she infuses every project with a touch of magic.
                </Text>
              </Box>
            </Box>
          </Reveal>
        </Box>
      </Section>
      <Footer />
      {isShow ? (
        <ContentUpdate
          selectedElement={selectedData}
          isShow={isShow}
          closeSidebar={closeSidebar}
        />
      ) : (
        ""
      )}
      <RawHtml>
        <style place={"endOfHead"} rawKey={"6459d5984b38c40020065810"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
};
