import apis from "../../apis/product";

export const getAllCategory = (payload) => {
  return async (dispatch) => {
    try {
      let payload = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
      };
      let { data } = await apis.getAllCategory(payload);
      dispatch(setProductCategory(data.data.data));
      return data;
    } catch (error) {
      console.log(error);
    }
  };
};
export const getSubCategory = (payload) => {
  var categoryName = payload.categoryName;
  return async (dispatch, payload) => {
    try {
      let payload = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        categoryName: categoryName,
      };
      let { data } = await apis.getSubCategory(payload);
      dispatch(setProductSubCategory(data.data.data));
      return data;
    } catch (error) {
      console.log(error);
    }
  };
};
export const getProduct = (payload) => {
  var subcategoryName = payload.subcategoryName;
  return async (dispatch, payload) => {
    try {
      let payload = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        subcategoryName: subcategoryName,
      };
      // dispatch(setProduct([]));
      let { data } = await apis.getProduct(payload);
      dispatch(setProduct(data.data.data));
      return data;
    } catch (error) {
      console.log(error);
    }
  };
};
export const setProductCategory = (data) => {
  return {
    type: "setProductCategory",
    payload: data,
  };
};
export const setIsLoadingCat = (data) => {
  return {
    type: "setIsLoadingCat",
    payload: data,
  };
};
export const setProduct = (data) => {
  return {
    type: "setProduct",
    payload: data,
  };
};
export const setProductSubCategory = (data) => {
  return {
    type: "setProductSubCategory",
    payload: data,
  };
};

export const addSingleSubCategory = (data) => {
  console.log(data, "subcat");
  return {
    type: "ADD_SINGLE_SubCategory",
    payload: data,
  };
};

export const addSingleSubCategoryAlbum = (data) => {
  console.log(data, "subcat");
  return {
    type: "ADD_SINGLE_SubCategoryAlbum",
    payload: data,
  };
};
