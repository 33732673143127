import React from "react";
import theme from "theme";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import "swiper/swiper.min.css";
import Footer from "./Footer";
import RecentWork from "components/common/RecentWork";
import {
  Theme,
  Box,
  Image,
  Text,
  Section,
  Hr,
  Icon,
  LinkBox,
  Span,
  Link,
} from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, SocialMedia } from "@quarkly/components";
import * as Components from "components";
import { IoMdHeartEmpty, IoMdShare } from "react-icons/io";
import { useSelector } from "react-redux/es/exports";

export default () => {
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  function getFavicon(value) {
    return value
      ? value.favIcon
        ? process.env.REACT_APP_STORAGE_URL_2 + brandProfile.favIcon
        : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/logo.png"
      : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/logo.png";
  }
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"extra/index2"} />
      <Helmet>
        <title>Black-Frames-Studio</title>
        <meta
          name={"description"}
          content={"Web site created using quarkly.io"}
        />
        <link
          rel={"shortcut icon"}
          href={getFavicon(brandProfile)}
          type={"image/x-icon"}
        />
      </Helmet>
      {/* <Components.Nav /> */}
      <Components.NavabarHeader />
      <Section position="relative">
        <Override
          slot="SectionContent"
          margin="60px auto 60px auto"
          position="static"
          align-items="center"
          justify-content="center"
        />
        <Box
          min-width="100px"
          position="absolute"
          height="100%"
          width="100%"
          background="--primaryGradient"
          opacity="0.8"
          z-index="-1"
        />
        <Image
          src="https://images.unsplash.com/photo-1677938591136-1fa87fe34e7a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
          display="block"
          position="absolute"
          bottom={0}
          top={0}
          left={0}
          right={0}
          width="100%"
          z-index="-2"
          height="100%"
          object-fit="cover"
          id="1306513267"
        />
        <Box
          margin="90px 0px 90px 0px"
          sm-padding="0px 24px 0px 24px"
          md-min-height="max-content"
          md-margin="0px 0px 32px 0px"
          color="--darkL1"
        >
          <Text
            margin="0px 0px 6px 0px"
            font="--subheadline4"
            color="--darkL1"
            text-align="center"
            sm-font="--subheadline6"
            md-font="--subheadline5"
            lg-font="--subheadline5"
            id="0650331723"
          >
            Services
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            font="--subheadline3"
            text-align="center"
            sm-font="--subheadline4"
            md-font="--subheadline4"
            lg-font="--subheadline4"
            id="2442648549"
          >
            Capturing Every precious moments
          </Text>
        </Box>
      </Section>
      <Section
        position="relative"
        align-items="center"
        justify-content="center"
        padding="0px 0 0px 0"
      >
        <Override
          slot="SectionContent"
          margin="80px 80px 80px 80px"
          sm-margin="8px auto 8px auto"
          sm-align-items="center"
          sm-justify-content="center"
          md-margin="24px auto 24px auto"
          lg-margin="16px auto 16px auto"
        />
        <Box
          margin="0px 0px 0px 0px"
          sm-padding="0px 24px 0px 24px"
          md-min-height="max-content"
          md-margin="0px 0px 32px 0px"
          align-items="center"
          display="flex"
          flex-direction="column"
          justify-content="center"
        >
          <Text
            margin="0px 0px 6px 0px"
            font="--subheadline2"
            color="--dark"
            text-align="center"
            sm-font="--subheadline6"
            md-font="--subheadline5"
            lg-font="--subheadline5"
            id="5748486445"
            border-color="--color-secondery"
          >
            SERVICES
          </Text>
          <Hr
            min-height={0}
            min-width="5%"
            margin="0px 0px 8px 0px"
            width="5%"
            background="--primaryGradient"
            height="2px"
            padding="0px 0px 2px 0px"
            border-radius="4px"
          />
          <Text
            margin="0px 0px 0px 0px"
            font="--subheadline3"
            text-align="center"
            sm-font="--subheadline4"
            md-font="--subheadline4"
            lg-font="--subheadline4"
            id="5803582221"
            display="none"
          >
            UNLEASHING THE POWER OF VISUAL STORYTELLING
          </Text>
        </Box>
        <Box
          display="grid"
          grid-template-columns="repeat(2, 1fr)"
          sm-grid-template-columns="repeat(1, 1fr)"
          lg-margin="0px auto 0px auto"
          grid-gap="20px"
          margin="60px 0px 0px 0px"
        >
          <Box
            margin="0px 16px 0px 0px"
            display="flex"
            flex-direction="column"
            justify-content="center"
            padding="24px 24px 24px 24px"
            sm-padding="16px 16px 16px 16px"
            md-padding="8px 1px 8px 16px"
            md-margin="0px 0 0px 0px"
            lg-margin="0px 0 0px 0px"
          >
            <Text
              margin="0px 0px 12px 0px"
              font="--subheadline4"
              color="--secondery"
              sm-font="--subheadline5"
              md-font="--subheadline5"
              lg-font="--subheadline5"
              id="2937271862"
              border-color="--color-secondery"
            >
              PHOTOSHOOT
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              font="--p2"
              color="--grey"
              text-align="left"
              sm-font="--p5"
              md-font="--p4"
              lg-font="--p4"
              id="4522771128"
            >
              CAPTURE MOMENTS, CREATE MEMORIES. SKILLED FRAMING, STORYTELLING.
              PORTRAITS TO EVENTS, BRINGING YOUR VISION TO LIFE.
              <br />
            </Text>
          </Box>
          <Box
            margin="0px 16px 0px 0px"
            display="flex"
            flex-direction="column"
            align-items="flex-start"
            justify-content="center"
            padding="24px 24px 24px 24px"
            sm-padding="16px 16px 16px 16px"
            md-padding="8px 1px 8px 16px"
            md-margin="0px 0 0px 0px"
            lg-margin="0px 0 0px 0px"
          >
            <Text
              margin="0px 0px 12px 0px"
              font="--subheadline4"
              color="--secondery"
              sm-font="--subheadline5"
              md-font="--subheadline5"
              lg-font="--subheadline5"
              id="6746111559"
            >
              STUDIO RENT
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              font="--p2"
              color="--grey"
              text-align="left"
              sm-font="--p5"
              md-font="--p4"
              lg-font="--p4"
              id="7268375693"
            >
              CAPTURE YOUR MOMENTS IN OUR VERSATILE STUDIO SPACE, TAILORED FOR
              PHOTOGRAPHERS AND FILMMAKERS ALIKE.
              <br />
              {"\n\n\n\n"}
            </Text>
          </Box>
        </Box>
      </Section>
      <Section
        background="--backgroundLight"
        padding="80px 0 80px 0"
        md-padding="60px 0 60px 0"
        sm-justify-content="center"
        sm-padding="60px 0px 60px 0px"
      >
        <Override
          slot="SectionContent"
          lg-margin="0px 60px 0px 60px"
          md-margin="0px 40px 0px 40px"
          sm-margin="0px 20px 0px 20px"
          sm-width="100%"
        />
        <Box
          display="grid"
          grid-template-columns="repeat(3, 1fr)"
          grid-gap="40px"
          md-grid-gap="28px"
          sm-grid-gap="22px"
          sm-grid-template-columns="repeat(1, 1fr)"
          md-grid-template-columns="repeat(2, 1fr)"
        >
          <LinkBox
            align-items="flex-start"
            height="fit-content"
            href="/index2131"
            sm-grid-column="auto / auto"
            md-grid-column="auto / auto"
          >
            <Box
              height="fit-content"
              width="100%"
              position="relative"
              margin="0px 0px 6px 0px"
            >
              <Box
                flex-direction="column"
                justify-content="center"
                padding="12px 12px 12px 12px"
                background="linear-gradient(450deg,rgba(0, 0, 0, 0.45) 0%,rgba(0, 0, 0, 0.45) 100%) 0 0 no-repeat"
                top="auto"
                right="auto"
                bottom="0px"
                left="0px"
                z-index="4"
                position="absolute"
                width="100%"
                height="100%"
                opacity="0"
                hover-opacity="1"
                transition="opacity 0.3s ease-in 0s"
                display="flex"
                align-items="center"
              >
                <Box display="flex">
                  <Box
                    display="flex"
                    justify-content="center"
                    align-items="center"
                    margin="0px 20px 0px 0px"
                  >
                    <Text
                      margin="0px 4px 0px 0px"
                      font="--subheadline5"
                      color="--darkL1"
                      text-align="left"
                      sm-font="--subheadline6"
                      md-font="--subheadline5"
                      lg-font="--subheadline5"
                      id="5748486445"
                      border-color="--color-secondery"
                      letter-spacing="4px"
                      display="none"
                    >
                      28
                    </Text>
                    <Icon
                      category="io"
                      icon={IoMdHeartEmpty}
                      size="36px"
                      color="--darkL1"
                    />
                  </Box>
                  <Hr
                    margin="0px 20px 0px 0px"
                    display="flex"
                    width="1px"
                    border-width="0 1px 0 0"
                    border-color="#8a8a8a"
                  />
                  <Icon
                    category="io"
                    icon={IoMdShare}
                    size="36px"
                    color="--darkL1"
                  />
                </Box>
              </Box>
              <Image
                src="https://uploads.quarkly.io/649bdc8a8bd7e600185a1390/images/HOH22696.jpg?v=2023-07-03T07:47:27.695Z"
                display="block"
                object-fit="cover"
                width="100%"
                height="380px"
                margin="0px 0px 0px 0px"
                object-position="50% 0%"
                sm-height="300px"
              />
            </Box>
            <Text
              margin="0px 0px 6px 0px"
              font="--subheadline5"
              color="--dark"
              text-align="left"
              sm-font="--subheadline6"
              md-font="--subheadline5"
              lg-font="--subheadline5"
              id="5748486445"
              border-color="--color-secondery"
              letter-spacing="4px"
            >
              CHANDAN ROY SANYAL
            </Text>
          </LinkBox>
          <LinkBox
            grid-column="2 / span 2"
            align-items="flex-start"
            height="fit-content"
            href="/index2131"
            sm-grid-column="auto / auto"
            md-grid-column="auto / auto"
          >
            <Box
              height="fit-content"
              width="100%"
              position="relative"
              margin="0px 0px 6px 0px"
            >
              <Box
                flex-direction="column"
                justify-content="center"
                padding="12px 12px 12px 12px"
                background="linear-gradient(450deg,rgba(0, 0, 0, 0.45) 0%,rgba(0, 0, 0, 0.45) 100%) 0 0 no-repeat"
                top="auto"
                right="auto"
                bottom="0px"
                left="0px"
                z-index="4"
                position="absolute"
                width="100%"
                height="100%"
                opacity="0"
                hover-opacity="1"
                transition="opacity 0.3s ease-in 0s"
                display="flex"
                align-items="center"
              >
                <Box display="flex">
                  <Box
                    display="flex"
                    justify-content="center"
                    align-items="center"
                    margin="0px 20px 0px 0px"
                  >
                    <Text
                      margin="0px 4px 0px 0px"
                      font="--subheadline5"
                      color="--darkL1"
                      text-align="left"
                      sm-font="--subheadline6"
                      md-font="--subheadline5"
                      lg-font="--subheadline5"
                      id="5748486445"
                      border-color="--color-secondery"
                      letter-spacing="4px"
                      display="none"
                    >
                      28
                    </Text>
                    <Icon
                      category="io"
                      icon={IoMdHeartEmpty}
                      size="36px"
                      color="--darkL1"
                    />
                  </Box>
                  <Hr
                    margin="0px 20px 0px 0px"
                    display="flex"
                    width="1px"
                    border-width="0 1px 0 0"
                    border-color="#8a8a8a"
                  />
                  <Icon
                    category="io"
                    icon={IoMdShare}
                    size="36px"
                    color="--darkL1"
                  />
                </Box>
              </Box>
              <Image
                src="https://uploads.quarkly.io/649bdc8a8bd7e600185a1390/images/img0749.jpg?v=2023-07-03T07:45:40.235Z"
                display="block"
                object-fit="cover"
                width="100%"
                height="380px"
                margin="0px 0px 0px 0px"
                object-position="50% 20%"
                sm-height="300px"
              />
            </Box>
            <Text
              margin="0px 0px 6px 0px"
              font="--subheadline5"
              color="--dark"
              text-align="left"
              sm-font="--subheadline6"
              md-font="--subheadline5"
              lg-font="--subheadline5"
              id="5748486445"
              border-color="--color-secondery"
              letter-spacing="4px"
            >
              CHANDAN ROY SANYAL
            </Text>
          </LinkBox>
          <LinkBox
            grid-column="1 / span 2"
            align-items="flex-start"
            height="fit-content"
            href="/index2131"
            sm-grid-column="auto / auto"
            md-grid-column="auto / auto"
          >
            <Box
              height="fit-content"
              width="100%"
              position="relative"
              margin="0px 0px 6px 0px"
            >
              <Box
                flex-direction="column"
                justify-content="center"
                padding="12px 12px 12px 12px"
                background="linear-gradient(450deg,rgba(0, 0, 0, 0.45) 0%,rgba(0, 0, 0, 0.45) 100%) 0 0 no-repeat"
                top="auto"
                right="auto"
                bottom="0px"
                left="0px"
                z-index="4"
                position="absolute"
                width="100%"
                height="100%"
                opacity="0"
                hover-opacity="1"
                transition="opacity 0.3s ease-in 0s"
                display="flex"
                align-items="center"
              >
                <Box display="flex">
                  <Box
                    display="flex"
                    justify-content="center"
                    align-items="center"
                    margin="0px 20px 0px 0px"
                  >
                    <Text
                      margin="0px 4px 0px 0px"
                      font="--subheadline5"
                      color="--darkL1"
                      text-align="left"
                      sm-font="--subheadline6"
                      md-font="--subheadline5"
                      lg-font="--subheadline5"
                      id="5748486445"
                      border-color="--color-secondery"
                      letter-spacing="4px"
                      display="none"
                    >
                      28
                    </Text>
                    <Icon
                      category="io"
                      icon={IoMdHeartEmpty}
                      size="36px"
                      color="--darkL1"
                    />
                  </Box>
                  <Hr
                    margin="0px 20px 0px 0px"
                    display="flex"
                    width="1px"
                    border-width="0 1px 0 0"
                    border-color="#8a8a8a"
                  />
                  <Icon
                    category="io"
                    icon={IoMdShare}
                    size="36px"
                    color="--darkL1"
                  />
                </Box>
              </Box>
              <Image
                src="https://uploads.quarkly.io/649bdc8a8bd7e600185a1390/images/_BFS8022-1.jpg?v=2023-07-03T07:48:06.633Z"
                display="block"
                object-fit="cover"
                width="100%"
                height="380px"
                margin="0px 0px 0px 0px"
                object-position="50% 20%"
                sm-height="300px"
              />
            </Box>
            <Text
              margin="0px 0px 6px 0px"
              font="--subheadline5"
              color="--dark"
              text-align="left"
              sm-font="--subheadline6"
              md-font="--subheadline5"
              lg-font="--subheadline5"
              id="5748486445"
              border-color="--color-secondery"
              letter-spacing="4px"
            >
              CHANDAN ROY SANYAL
            </Text>
          </LinkBox>
          <LinkBox
            align-items="flex-start"
            height="fit-content"
            href="/index2131"
            sm-grid-column="auto / auto"
            md-grid-column="auto / auto"
          >
            <Box
              height="fit-content"
              width="100%"
              position="relative"
              margin="0px 0px 6px 0px"
            >
              <Box
                flex-direction="column"
                justify-content="center"
                padding="12px 12px 12px 12px"
                background="linear-gradient(450deg,rgba(0, 0, 0, 0.45) 0%,rgba(0, 0, 0, 0.45) 100%) 0 0 no-repeat"
                top="auto"
                right="auto"
                bottom="0px"
                left="0px"
                z-index="4"
                position="absolute"
                width="100%"
                height="100%"
                opacity="0"
                hover-opacity="1"
                transition="opacity 0.3s ease-in 0s"
                display="flex"
                align-items="center"
              >
                <Box display="flex">
                  <Box
                    display="flex"
                    justify-content="center"
                    align-items="center"
                    margin="0px 20px 0px 0px"
                  >
                    <Text
                      margin="0px 4px 0px 0px"
                      font="--subheadline5"
                      color="--darkL1"
                      text-align="left"
                      sm-font="--subheadline6"
                      md-font="--subheadline5"
                      lg-font="--subheadline5"
                      id="5748486445"
                      border-color="--color-secondery"
                      letter-spacing="4px"
                      display="none"
                    >
                      28
                    </Text>
                    <Icon
                      category="io"
                      icon={IoMdHeartEmpty}
                      size="36px"
                      color="--darkL1"
                    />
                  </Box>
                  <Hr
                    margin="0px 20px 0px 0px"
                    display="flex"
                    width="1px"
                    border-width="0 1px 0 0"
                    border-color="#8a8a8a"
                  />
                  <Icon
                    category="io"
                    icon={IoMdShare}
                    size="36px"
                    color="--darkL1"
                  />
                </Box>
              </Box>
              <Image
                src="https://uploads.quarkly.io/649bdc8a8bd7e600185a1390/images/IMGANI0586.jpg?v=2023-07-03T07:36:43.271Z"
                display="block"
                object-fit="cover"
                width="100%"
                height="380px"
                margin="0px 0px 0px 0px"
                object-position="50% 20%"
                sm-height="300px"
              />
            </Box>
            <Text
              margin="0px 0px 6px 0px"
              font="--subheadline5"
              color="--dark"
              text-align="left"
              sm-font="--subheadline6"
              md-font="--subheadline5"
              lg-font="--subheadline5"
              id="5748486445"
              border-color="--color-secondery"
              letter-spacing="4px"
            >
              CHANDAN ROY SANYAL
            </Text>
          </LinkBox>
          <LinkBox
            align-items="flex-start"
            height="fit-content"
            href="/index2131"
            sm-grid-column="auto / auto"
            md-grid-column="auto / auto"
          >
            <Box
              height="fit-content"
              width="100%"
              position="relative"
              margin="0px 0px 6px 0px"
            >
              <Box
                flex-direction="column"
                justify-content="center"
                padding="12px 12px 12px 12px"
                background="linear-gradient(450deg,rgba(0, 0, 0, 0.45) 0%,rgba(0, 0, 0, 0.45) 100%) 0 0 no-repeat"
                top="auto"
                right="auto"
                bottom="0px"
                left="0px"
                z-index="4"
                position="absolute"
                width="100%"
                height="100%"
                opacity="0"
                hover-opacity="1"
                transition="opacity 0.3s ease-in 0s"
                display="flex"
                align-items="center"
              >
                <Box display="flex">
                  <Box
                    display="flex"
                    justify-content="center"
                    align-items="center"
                    margin="0px 20px 0px 0px"
                  >
                    <Text
                      margin="0px 4px 0px 0px"
                      font="--subheadline5"
                      color="--darkL1"
                      text-align="left"
                      sm-font="--subheadline6"
                      md-font="--subheadline5"
                      lg-font="--subheadline5"
                      id="5748486445"
                      border-color="--color-secondery"
                      letter-spacing="4px"
                      display="none"
                    >
                      28
                    </Text>
                    <Icon
                      category="io"
                      icon={IoMdHeartEmpty}
                      size="36px"
                      color="--darkL1"
                    />
                  </Box>
                  <Hr
                    margin="0px 20px 0px 0px"
                    display="flex"
                    width="1px"
                    border-width="0 1px 0 0"
                    border-color="#8a8a8a"
                  />
                  <Icon
                    category="io"
                    icon={IoMdShare}
                    size="36px"
                    color="--darkL1"
                  />
                </Box>
              </Box>
              <Image
                src="https://uploads.quarkly.io/649bdc8a8bd7e600185a1390/images/Img_560234.jpg?v=2023-07-03T07:47:08.062Z"
                display="block"
                object-fit="cover"
                width="100%"
                height="380px"
                margin="0px 0px 0px 0px"
                object-position="50%100%"
                sm-height="300px"
              />
            </Box>
            <Text
              margin="0px 0px 6px 0px"
              font="--subheadline5"
              color="--dark"
              text-align="left"
              sm-font="--subheadline6"
              md-font="--subheadline5"
              lg-font="--subheadline5"
              id="5748486445"
              border-color="--color-secondery"
              letter-spacing="4px"
            >
              CHANDAN ROY SANYAL
            </Text>
          </LinkBox>
          <LinkBox
            grid-column="2 / span 2"
            align-items="flex-start"
            height="fit-content"
            href="/index2131"
            sm-grid-column="auto / auto"
            md-grid-column="auto / auto"
          >
            <Box
              height="fit-content"
              width="100%"
              position="relative"
              margin="0px 0px 6px 0px"
            >
              <Box
                flex-direction="column"
                justify-content="center"
                padding="12px 12px 12px 12px"
                background="linear-gradient(450deg,rgba(0, 0, 0, 0.45) 0%,rgba(0, 0, 0, 0.45) 100%) 0 0 no-repeat"
                top="auto"
                right="auto"
                bottom="0px"
                left="0px"
                z-index="4"
                position="absolute"
                width="100%"
                height="100%"
                opacity="0"
                hover-opacity="1"
                transition="opacity 0.3s ease-in 0s"
                display="flex"
                align-items="center"
              >
                <Box display="flex">
                  <Box
                    display="flex"
                    justify-content="center"
                    align-items="center"
                    margin="0px 20px 0px 0px"
                  >
                    <Text
                      margin="0px 4px 0px 0px"
                      font="--subheadline5"
                      color="--darkL1"
                      text-align="left"
                      sm-font="--subheadline6"
                      md-font="--subheadline5"
                      lg-font="--subheadline5"
                      id="5748486445"
                      border-color="--color-secondery"
                      letter-spacing="4px"
                      display="none"
                    >
                      28
                    </Text>
                    <Icon
                      category="io"
                      icon={IoMdHeartEmpty}
                      size="36px"
                      color="--darkL1"
                    />
                  </Box>
                  <Hr
                    margin="0px 20px 0px 0px"
                    display="flex"
                    width="1px"
                    border-width="0 1px 0 0"
                    border-color="#8a8a8a"
                  />
                  <Icon
                    category="io"
                    icon={IoMdShare}
                    size="36px"
                    color="--darkL1"
                  />
                </Box>
              </Box>
              <Image
                src="https://uploads.quarkly.io/649bdc8a8bd7e600185a1390/images/IMGANI0425.jpg?v=2023-07-03T07:22:43.235Z"
                display="block"
                object-fit="cover"
                width="100%"
                height="380px"
                margin="0px 0px 0px 0px"
                object-position="50% 20%"
                sm-height="300px"
              />
            </Box>
            <Text
              margin="0px 0px 6px 0px"
              font="--subheadline5"
              color="--dark"
              text-align="left"
              sm-font="--subheadline6"
              md-font="--subheadline5"
              lg-font="--subheadline5"
              id="5748486445"
              border-color="--color-secondery"
              letter-spacing="4px"
            >
              CHANDAN ROY SANYAL
            </Text>
          </LinkBox>
          <LinkBox
            grid-column="1 / span 2"
            align-items="flex-start"
            height="fit-content"
            href="/index2131"
            sm-grid-column="auto / auto"
            md-grid-column="auto / auto"
          >
            <Box
              height="fit-content"
              width="100%"
              position="relative"
              margin="0px 0px 6px 0px"
            >
              <Box
                flex-direction="column"
                justify-content="center"
                padding="12px 12px 12px 12px"
                background="linear-gradient(450deg,rgba(0, 0, 0, 0.45) 0%,rgba(0, 0, 0, 0.45) 100%) 0 0 no-repeat"
                top="auto"
                right="auto"
                bottom="0px"
                left="0px"
                z-index="4"
                position="absolute"
                width="100%"
                height="100%"
                opacity="0"
                hover-opacity="1"
                transition="opacity 0.3s ease-in 0s"
                display="flex"
                align-items="center"
              >
                <Box display="flex">
                  <Box
                    display="flex"
                    justify-content="center"
                    align-items="center"
                    margin="0px 20px 0px 0px"
                  >
                    <Text
                      margin="0px 4px 0px 0px"
                      font="--subheadline5"
                      color="--darkL1"
                      text-align="left"
                      sm-font="--subheadline6"
                      md-font="--subheadline5"
                      lg-font="--subheadline5"
                      id="5748486445"
                      border-color="--color-secondery"
                      letter-spacing="4px"
                      display="none"
                    >
                      28
                    </Text>
                    <Icon
                      category="io"
                      icon={IoMdHeartEmpty}
                      size="36px"
                      color="--darkL1"
                    />
                  </Box>
                  <Hr
                    margin="0px 20px 0px 0px"
                    display="flex"
                    width="1px"
                    border-width="0 1px 0 0"
                    border-color="#8a8a8a"
                  />
                  <Icon
                    category="io"
                    icon={IoMdShare}
                    size="36px"
                    color="--darkL1"
                  />
                </Box>
              </Box>
              <Image
                src="https://uploads.quarkly.io/649bdc8a8bd7e600185a1390/images/_BFS8128.jpg?v=2023-07-03T07:49:30.368Z"
                display="block"
                object-fit="cover"
                width="100%"
                height="380px"
                margin="0px 0px 0px 0px"
                object-position="50% 0%"
                sm-height="300px"
              />
            </Box>
            <Text
              margin="0px 0px 6px 0px"
              font="--subheadline5"
              color="--dark"
              text-align="left"
              sm-font="--subheadline6"
              md-font="--subheadline5"
              lg-font="--subheadline5"
              id="5748486445"
              border-color="--color-secondery"
              letter-spacing="4px"
            >
              CHANDAN ROY SANYAL
            </Text>
          </LinkBox>
          <LinkBox
            align-items="flex-start"
            height="fit-content"
            href="/index2131"
            sm-grid-column="auto / auto"
            md-grid-column="auto / auto"
          >
            <Box
              height="fit-content"
              width="100%"
              position="relative"
              margin="0px 0px 6px 0px"
            >
              <Box
                flex-direction="column"
                justify-content="center"
                padding="12px 12px 12px 12px"
                background="linear-gradient(450deg,rgba(0, 0, 0, 0.45) 0%,rgba(0, 0, 0, 0.45) 100%) 0 0 no-repeat"
                top="auto"
                right="auto"
                bottom="0px"
                left="0px"
                z-index="4"
                position="absolute"
                width="100%"
                height="100%"
                opacity="0"
                hover-opacity="1"
                transition="opacity 0.3s ease-in 0s"
                display="flex"
                align-items="center"
              >
                <Box display="flex">
                  <Box
                    display="flex"
                    justify-content="center"
                    align-items="center"
                    margin="0px 20px 0px 0px"
                  >
                    <Text
                      margin="0px 4px 0px 0px"
                      font="--subheadline5"
                      color="--darkL1"
                      text-align="left"
                      sm-font="--subheadline6"
                      md-font="--subheadline5"
                      lg-font="--subheadline5"
                      id="5748486445"
                      border-color="--color-secondery"
                      letter-spacing="4px"
                      display="none"
                    >
                      28
                    </Text>
                    <Icon
                      category="io"
                      icon={IoMdHeartEmpty}
                      size="36px"
                      color="--darkL1"
                    />
                  </Box>
                  <Hr
                    margin="0px 20px 0px 0px"
                    display="flex"
                    width="1px"
                    border-width="0 1px 0 0"
                    border-color="#8a8a8a"
                  />
                  <Icon
                    category="io"
                    icon={IoMdShare}
                    size="36px"
                    color="--darkL1"
                  />
                </Box>
              </Box>
              <Image
                src="https://uploads.quarkly.io/649bdc8a8bd7e600185a1390/images/DSC_9050-cropped.jpg?v=2023-07-03T07:22:24.708Z"
                display="block"
                object-fit="cover"
                width="100%"
                height="380px"
                margin="0px 0px 0px 0px"
                object-position="50% 20%"
                sm-height="300px"
              />
            </Box>
            <Text
              margin="0px 0px 6px 0px"
              font="--subheadline5"
              color="--dark"
              text-align="left"
              sm-font="--subheadline6"
              md-font="--subheadline5"
              lg-font="--subheadline5"
              id="5748486445"
              border-color="--color-secondery"
              letter-spacing="4px"
            >
              CHANDAN ROY SANYAL
            </Text>
          </LinkBox>
        </Box>
      </Section>
      <RecentWork />
      <Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"6459d5984b38c40020065810"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
};
