import React from "react";

function Skeleton() {
  const skeletonCard = 9;

  return (
    <div>
      <div class="flex animate-pulse mb-8 ">
        <div class="w-1/4 m-auto">
          <ul class="px-0">
            <li class="w-full h-8 bg-gray-200 rounded-md dark:bg-gray-700"></li>
          </ul>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-4">
        {Array.from({ length: skeletonCard }, (_, index) => (
          <div class="flex animate-pulse impcat ">
            <div class="w-full">
              <ul class="px-0 mb-0">
                <li class="w-full h-680px bg-gray-200 rounded-md dark:bg-gray-700"></li>
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Skeleton;
