import API from "./index";
const get = "getCombinedMenu";

const apiBase = (route) => {
  return `menu/twoHierarchyMenu/${route}`;
};

export default {
  getAllMenu() {
    return API.post(apiBase(get));
  },
};
