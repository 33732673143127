const initialState = {
  Testimonal: [],
};
const setTestimonal = (state = initialState, action) => {
  switch (action.type) {
    case "setTestimonal":
      return { ...state, Testimonal: action.payload };

    default:
      return state;
  }
};

export default setTestimonal;
