import React, { useEffect, useState } from "react";
import Reveal from "react-reveal/Reveal";
import Skeleton from "components/common/Skeleton";
import theme from "theme";
import { Swiper, SwiperSlide } from "swiper/react";
// import { setIsLoadingCat } from "redux/Product/product-action";
import { Navigation } from "swiper";
import "swiper/swiper.min.css";
import {
  Theme,
  Text,
  Span,
  Box,
  Image,
  Section,
  Icon,
  Hr,
  LinkBox,
} from "@quarkly/widgets";

import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, SocialMedia } from "@quarkly/components";
import { useDispatch, useSelector } from "react-redux/es/exports";

import { useParams } from "react-router-dom";
import ContentUpdate from "../CMS/ContentUpdate";

export default () => {
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [selectedData, updateSelectedData] = useState("update");
  const BrandDetails = useSelector((state) => state.brandDetails.brandProfile);
  const [loading, setLoading] = useState(false);
  console.log(BrandDetails, "outside in");
  let project = useSelector((state) => state.userDetails.project);
  let AllCategory = useSelector((state) => state.product.Category);
  let data = useSelector((state) => state.cms.pages["Home"]);
  let userData = useSelector((state) => state.cms.userData);
  let isLoadingCat = useSelector((state) => state.product.isLoadingCat);
  //-----------hide/show-------
  const { token } = useParams();

  if (token) {
    let cmsStyle = document.getElementsByClassName("hoverText");
    console.log(cmsStyle, "cmsStyle");
    for (let i = 0; i < cmsStyle.length; i++) {
      cmsStyle[i].onClick = function () {
        return false;
      };
      cmsStyle[i].classList.add("hoverText1");
    }
  }
  //----------------hide/show-----------

  function getImage(img) {
    return process.env.REACT_APP_STORAGE_URL_2 + img;
  }

  useEffect(() => {
    console.log(data, "Home");
    setMounted(true);
  }, [dispatch, setMounted]);
  console.log(AllCategory, "outside");
  async function isSideBarOpen(id) {
    if (token) {
      await updateSelectedData(id);
      setIsShow(true);
      console.log(isShow, selectedData, id, "show");
    }
  }

  function closeSidebar(value) {
    setIsShow(value);
    updateSelectedData("");
    // selectedData = "";

    console.log(selectedData, "show");
  }
  const baseURL = process.env.REACT_APP_STORAGE_URL;
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index"} />

      <Section
        align-items="center"
        justify-content="center"
        sm-align-items="center"
        sm-justify-content="center"
        lg-font="--subheadline5"
        sm-padding="40px 0 40px 0"
        padding="0px 0 0px 0"
        lg-padding="30px 0 60px 0"
        md-padding="20px 0 60px 0"
      >
        <Override
          slot="SectionContent"
          margin="8px 80px 8px 80px"
          md-margin="24px 40px 24px 40px"
          lg-margin="40px 60px 40px 60px"
          max-width="100%"
          width="100%"
          sm-margin="0px 20px 0px 20px"
        />
        {isLoadingCat ? (
          <Skeleton />
        ) : (
          <>
            <Box
              margin="0px 0px 0px 0px"
              sm-height="max-content"
              sm-min-height="max-content"
              md-height="max-content"
              md-min-height="max-content"
              lg-min-height="max-content"
              align-items="center"
              display="flex"
              flex-direction="column"
              justify-content="center"
            >
              <Text
                margin="0px 0px 6px 0px"
                font="--subheadline4"
                color="--dark"
                text-align="center"
                sm-font="--subheadline6"
                lg-font="--subheadline5"
                id="7792713477"
                border-color="--color-secondery"
                className="hoverText uppercase"
                onClick={() => isSideBarOpen("7792713477")}
              >
                <Span
                  font-weight="normal"
                  overflow-wrap="normal"
                  word-break="normal"
                  white-space="normal"
                  text-indent="0"
                  text-overflow="clip"
                  hyphens="manual"
                  user-select="auto"
                  pointer-events="auto"
                  font="--subheadline2"
                  sm-font="--subheadline6"
                >
                  <Reveal> {data ? data["7792713477"] : "no data"} </Reveal>
                </Span>
              </Text>
              <Hr
                min-width="5%"
                margin="0px 0px 8px 0px"
                width="5%"
                background="--primaryGradient"
                height="2px"
                min-height={0}
                padding="0px 0px 2px 0px"
                border-radius="4px"
              />
              <Text
                margin="0px 0px 0px 0px"
                font="--subheadline3"
                text-align="center"
                sm-font="--subheadline5"
                lg-font="--subheadline4"
                id="6841189597"
                display="none"
              >
                CAPTURING MOMENTS IN TIME
              </Text>
              <Box
                // display="grid"
                // grid-template-columns="repeat(4, 1fr)"
                width="100%"
                // grid-gap="32px"
                margin="60px 0px 0px 0px"
                // lg-grid-gap="18px"
                // md-grid-template-columns="repeat(2, 1fr)"
                // sm-grid-gap="20px"
                sm-margin="20px 0px 0px 0px"
                // sm-grid-template-columns="repeat(1, 1fr)"
                className="cust-w100"
              >
                <Reveal>
                  <Swiper
                    spaceBetween={35}
                    slidesPerView={4}
                    observer={true}
                    observeParents={true}
                    modules={[Navigation]}
                    className="mySwiper"
                    autoplay={{
                      delay: "1000",
                    }}
                    loop
                    navigation={{
                      nextEl: ".image-swiper-button-next",
                      prevEl: ".image-swiper-button-prev",
                    }}
                    breakpoints={{
                      1536: {
                        slidesPerView: 4,
                        // spaceBetween: 20,
                      },
                      1280: {
                        slidesPerView: 4,
                        // spaceBetween: 20,
                      },
                      1024: {
                        slidesPerView: 4,
                        // spaceBetween: 20,
                      },
                      991: {
                        slidesPerView: 2.5,
                        // spaceBetween: 30,
                      },
                      640: {
                        slidesPerView: 2.5,
                        // spaceBetween: 30,
                      },
                      425: {
                        slidesPerView: 1.2,
                        // spaceBetween: 50,
                      },
                      325: {
                        slidesPerView: 1.2,
                        // spaceBetween: 50,
                      },
                    }}
                  >
                    <div class="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer z-10">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 512 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
                      </svg>
                    </div>
                    <div class="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer z-10">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 512 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
                      </svg>
                    </div>
                    {AllCategory.result
                      ? AllCategory.result.map((e) => (
                          <SwiperSlide>
                            <LinkBox
                              position="relative"
                              width="100%"
                              lg-height="370px"
                              md-height="370px"
                              sm-height="370px"
                              id="5118032478"
                              href={`/portfolio/${e.name
                                .replace(/ /g, "-")
                                .toLowerCase()}/${e.subcategoryId[
                                e.subcategoryId.length - 1
                              ].name
                                .replace(/ /g, "-")
                                .toLowerCase()}`}
                            >
                              <Box
                                display="flex"
                                flex-direction="column"
                                justify-content="space-between"
                                // padding="12px 12px 12px 12px"
                                background="linear-gradient(450deg,#ffdeae 0%,#cfb545 100%) 0 0 no-repeat"
                                bottom="0px"
                                left="0px"
                                right="auto"
                                top="auto"
                                z-index="4"
                                position="absolute"
                                width="100%"
                                height="100%"
                                opacity="0"
                                hover-opacity="1"
                                transition="opacity 0.4s ease-in 0s"
                              >
                                <Text
                                  margin="0px 0px 0px 0px"
                                  font="--subheadline2"
                                  padding="12px 12px 12px 12px"
                                  color="--dark"
                                  text-align="left"
                                  sm-font="--subheadline31"
                                  md-font="--subheadline2"
                                  lg-font="--subheadline31"
                                  id="2189711384"
                                  border-color="--color-secondery"
                                  letter-spacing="4px"
                                >
                                  {e.subcategoryId && e.subcategoryId.length > 0
                                    ? e.subcategoryId[
                                        e.subcategoryId.length - 1
                                      ].name
                                    : ""}
                                </Text>
                                <Text
                                  margin="0px 0px 0px 0px"
                                  font="--subheadline4"
                                  padding="12px 12px 12px 12px"
                                  color="#636363"
                                  text-align="left"
                                  sm-font="--subheadline6"
                                  md-font="--subheadline5"
                                  lg-font="--subheadline5"
                                  id="0964925158"
                                  border-color="--color-secondery"
                                  letter-spacing="4px"
                                >
                                  {e.name}
                                </Text>
                              </Box>
                              <Image
                                src={
                                  e.subcategoryId && e.subcategoryId.length > 0
                                    ? getImage(
                                        e.subcategoryId[
                                          e.subcategoryId.length - 1
                                        ].image
                                      )
                                    : "https://nimbuscluster.blob.core.windows.net/server01/black-frames-studio/blackframes/image/pr.png"
                                }
                                display="block"
                                object-fit="cover"
                                height="420px"
                                width="100%"
                                margin="0px 0px 0px 0px"
                                className="hoverText"
                                id="7903093331"
                                onClick={() => isSideBarOpen("7903093331")}
                              />
                            </LinkBox>
                          </SwiperSlide>
                        ))
                      : "no-data"}

                    {/* <SwiperSlide>
                  <LinkBox
                    position="relative"
                    width="100%"
                    lg-height="320px"
                    md-height="280px"
                    sm-height="240px"
                    href="/index2131"
                    id="5118032478"
                  >
                    <Box
                      display="flex"
                      flex-direction="column"
                      justify-content="space-between"
                      background="linear-gradient(450deg,#ffdeae 0%,#cfb545 100%) 0 0 no-repeat"
                      top="auto"
                      right="auto"
                      bottom="0px"
                      left="0px"
                      z-index="4"
                      position="absolute"
                      width="100%"
                      height="100%"
                      opacity="0"
                      hover-opacity="1"
                      transition="opacity 0.4s ease-in 0s"
                    >
                      <Text
                        margin="0px 0px 0px 0px"
                        font="--subheadline2"
                        padding="12px 12px 12px 12px"
                        color="--dark"
                        text-align="left"
                        sm-font="--subheadline31"
                        md-font="--subheadline2"
                        lg-font="--subheadline31"
                        id="5690357429"
                        border-color="--color-secondery"
                        letter-spacing="4px"
                        className="hoverText"
                        onClick={() => isSideBarOpen("5690357429")}
                      >
                        {data ? data["5690357429"] : "SOULFUL PORTRAITS"}
                      </Text>
                      <Text
                        margin="0px 0px 0px 0px"
                        font="--subheadline4"
                        padding="12px 12px 12px 12px"
                        color="#636363"
                        text-align="left"
                        sm-font="--subheadline6"
                        md-font="--subheadline5"
                        lg-font="--subheadline5"
                        id="2201693250"
                        border-color="--color-secondery"
                        letter-spacing="4px"
                        className="hoverText"
                        onClick={() => isSideBarOpen("2201693250")}
                      >
                        {data ? data["2201693250"] : "PORTRAIT"}
                      </Text>
                    </Box>
                    <Image
                      src={
                        data
                          ? data["8384260699"]
                          : "https://uploads.quarkly.io/649bdc8a8bd7e600185a1390/images/_BFS8128.jpg?v=2023-07-03T07:49:30.368Z"
                      }
                      display="block"
                      object-fit="cover"
                      height="420px"
                      width="100%"
                      margin="0px 0px 0px 0px"
                      object-position="50%0%"
                      className="hoverText"
                      id="8384260699"
                      onClick={() => isSideBarOpen("8384260699")}
                    />
                  </LinkBox>
                </SwiperSlide>

                <SwiperSlide>
                  <LinkBox
                    position="relative"
                    width="100%"
                    lg-height="320px"
                    md-height="280px"
                    sm-height="240px"
                    id="5118032478"
                  >
                    <Box
                      display="flex"
                      flex-direction="column"
                      justify-content="space-between"
                      background="linear-gradient(450deg,#ffdeae 0%,#cfb545 100%) 0 0 no-repeat"
                      top="auto"
                      right="auto"
                      bottom="0px"
                      left="0px"
                      z-index="4"
                      position="absolute"
                      width="100%"
                      height="100%"
                      opacity="0"
                      hover-opacity="1"
                      transition="opacity 0.4s ease-in 0s"
                    >
                      <Text
                        margin="0px 0px 0px 0px"
                        font="--subheadline2"
                        color="--dark"
                        text-align="left"
                        padding="12px 12px 12px 12px"
                        sm-font="--subheadline31"
                        md-font="--subheadline2"
                        lg-font="--subheadline31"
                        id="6191630102"
                        border-color="--color-secondery"
                        letter-spacing="4px"
                        className="hoverText"
                        onClick={() => isSideBarOpen("6191630102")}
                      >
                        {data ? data["6191630102"] : "PUMA"}
                      </Text>
                      <Text
                        margin="0px 0px 0px 0px"
                        padding="12px 12px 12px 12px"
                        font="--subheadline4"
                        color="#636363"
                        text-align="left"
                        sm-font="--subheadline6"
                        md-font="--subheadline5"
                        lg-font="--subheadline5"
                        id="2581353091"
                        border-color="--color-secondery"
                        letter-spacing="4px"
                        className="hoverText"
                        onClick={() => isSideBarOpen("2581353091")}
                      >
                        {data ? data["2581353091"] : "PRODUCT"}
                      </Text>
                    </Box>
                    <Image
                      src={
                        data
                          ? data["7811865015"]
                          : "https://uploads.quarkly.io/649bdc8a8bd7e600185a1390/images/Tribe%20Product%20Shoot16185.jpg?v=2023-07-03T07:28:16.774Z"
                      }
                      display="block"
                      object-fit="cover"
                      height="420px"
                      width="100%"
                      margin="0px 0px 0px 0px"
                      object-position="10%"
                      className="hoverText"
                      id="7811865015"
                      onClick={() => isSideBarOpen("7811865015")}
                    />
                  </LinkBox>
                </SwiperSlide>

                <SwiperSlide>
                  <LinkBox
                    position="relative"
                    width="100%"
                    lg-height="320px"
                    md-height="280px"
                    sm-height="240px"
                    id="5118032478"
                  >
                    <Box
                      display="flex"
                      flex-direction="column"
                      justify-content="space-between"
                      // padding="12px 12px 12px 12px"
                      background="linear-gradient(450deg,#ffdeae 0%,#cfb545 100%) 0 0 no-repeat"
                      top="auto"
                      right="auto"
                      bottom="0px"
                      left="0px"
                      z-index="4"
                      position="absolute"
                      width="100%"
                      height="100%"
                      opacity="0"
                      hover-opacity="1"
                      transition="opacity 0.4s ease-in 0s"
                    >
                      <Text
                        margin="0px 0px 0px 0px"
                        font="--subheadline2"
                        padding="12px 12px 12px 12px"
                        color="--dark"
                        text-align="left"
                        sm-font="--subheadline31"
                        md-font="--subheadline2"
                        lg-font="--subheadline31"
                        id="7434197699"
                        border-color="--color-secondery"
                        letter-spacing="4px"
                        className="hoverText"
                        onClick={() => isSideBarOpen("7434197699")}
                      >
                        {data ? data["7434197699"] : "ETERNAL LOVE EVENTS"}
                      </Text>
                      <Text
                        margin="0px 0px 0px 0px"
                        font="--subheadline4"
                        padding="12px 12px 12px 12px"
                        color="#636363"
                        text-align="left"
                        sm-font="--subheadline6"
                        md-font="--subheadline5"
                        lg-font="--subheadline5"
                        id="2813364921"
                        border-color="--color-secondery"
                        letter-spacing="4px"
                        className="hoverText"
                        onClick={() => isSideBarOpen("2813364921")}
                      >
                        {data ? data["2813364921"] : "WEEDINGS"}
                      </Text>
                    </Box>
                    <Image
                      src={
                        data
                          ? data["1505662082"]
                          : "https://uploads.quarkly.io/649bdc8a8bd7e600185a1390/images/Antra%20SID0908.jpg?v=2023-07-03T07:47:45.161Z"
                      }
                      display="block"
                      object-fit="cover"
                      height="420px"
                      width="100%"
                      margin="0px 0px 0px 0px"
                      className="hoverText"
                      id="1505662082"
                      onClick={() => isSideBarOpen("1505662082")}
                    />
                  </LinkBox>
                </SwiperSlide>

                <SwiperSlide>
                  <LinkBox
                    position="relative"
                    width="100%"
                    lg-height="320px"
                    md-height="280px"
                    sm-height="240px"
                    id="5118032478"
                  >
                    <Box
                      display="flex"
                      flex-direction="column"
                      justify-content="space-between"
                      // padding="12px 12px 12px 12px"
                      background="linear-gradient(450deg,#ffdeae 0%,#cfb545 100%) 0 0 no-repeat"
                      top="auto"
                      right="auto"
                      bottom="0px"
                      left="0px"
                      z-index="4"
                      position="absolute"
                      width="100%"
                      height="100%"
                      opacity="0"
                      hover-opacity="1"
                      transition="opacity 0.4s ease-in 0s"
                    >
                      <Text
                        margin="0px 0px 0px 0px"
                        font="--subheadline2"
                        padding="12px 12px 12px 12px"
                        color="--dark"
                        text-align="left"
                        sm-font="--subheadline31"
                        md-font="--subheadline2"
                        lg-font="--subheadline31"
                        id="7434197699"
                        border-color="--color-secondery"
                        letter-spacing="4px"
                        className="hoverText"
                        onClick={() => isSideBarOpen("7434197699")}
                      >
                        {data ? data["7434197699"] : "ETERNAL LOVE EVENTS"}
                      </Text>
                      <Text
                        margin="0px 0px 0px 0px"
                        font="--subheadline4"
                        padding="12px 12px 12px 12px"
                        color="#636363"
                        text-align="left"
                        sm-font="--subheadline6"
                        md-font="--subheadline5"
                        lg-font="--subheadline5"
                        id="2813364921"
                        border-color="--color-secondery"
                        letter-spacing="4px"
                        className="hoverText"
                        onClick={() => isSideBarOpen("2813364921")}
                      >
                        {data ? data["2813364921"] : "WEEDINGS"}
                      </Text>
                    </Box>
                    <Image
                      src={
                        data
                          ? data["1505662082"]
                          : "https://uploads.quarkly.io/649bdc8a8bd7e600185a1390/images/Antra%20SID0908.jpg?v=2023-07-03T07:47:45.161Z"
                      }
                      display="block"
                      object-fit="cover"
                      height="420px"
                      width="100%"
                      margin="0px 0px 0px 0px"
                      className="hoverText"
                      id="1505662082"
                      onClick={() => isSideBarOpen("1505662082")}
                    />
                  </LinkBox>
                </SwiperSlide> */}
                  </Swiper>
                </Reveal>
              </Box>
            </Box>
          </>
        )}
      </Section>

      {isShow ? (
        <ContentUpdate
          selectedElement={selectedData}
          isShow={isShow}
          closeSidebar={closeSidebar}
        />
      ) : (
        ""
      )}
      <RawHtml>
        <style place={"endOfHead"} rawKey={"6459d5984b38c40020065810"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
};
