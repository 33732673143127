import React, { useEffect, useState } from "react";
import Reveal from "react-reveal/Reveal";
import Slide from "react-reveal/Slide";

import theme from "theme";
import { NavLink } from "react-router-dom";

import { getAllTestimonial } from "redux/testimonial/testimonial-action";
import "swiper/swiper.min.css";

import { Theme, Text, Span, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, SocialMedia } from "@quarkly/components";

import { useDispatch, useSelector } from "react-redux/es/exports";

import { useParams } from "react-router-dom";
import ContentUpdate from "../components/CMS/ContentUpdate";

import "sweetalert2/src/sweetalert2.scss";

export default () => {
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [selectedData, updateSelectedData] = useState("update");
  const BrandDetails = useSelector((state) => state.brandDetails.brandProfile);

  console.log(BrandDetails, "outside in");
  let project = useSelector((state) => state.userDetails.project);
  let data = useSelector((state) => state.cms.pages["Home"]);
  let userData = useSelector((state) => state.cms.userData);
  let productData = useSelector((state) => state.product.product);
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  let testimonalData = useSelector((state) => state.setTestimonal.Testimonal);
  //-----------hide/show-------
  const { token } = useParams();

  if (token) {
    let cmsStyle = document.getElementsByClassName("hoverText");
    console.log(cmsStyle, "cmsStyle");
    for (let i = 0; i < cmsStyle.length; i++) {
      cmsStyle[i].onClick = function () {
        return false;
      };
      cmsStyle[i].classList.add("hoverText1");
    }
  }
  //----------------hide/show-----------

  useEffect(() => {
    dispatch(getAllTestimonial());
    console.log(data, "Home");
    setMounted(true);
  }, [dispatch, setMounted]);
  console.log(data, "outside");
  console.log(productData, "hii");

  console.log(testimonalData, "chectest");

  async function isSideBarOpen(id) {
    if (token) {
      await updateSelectedData(id);
      setIsShow(true);
      console.log(isShow, selectedData, id, "show");
    }
  }

  function closeSidebar(value) {
    setIsShow(value);
    updateSelectedData("");
    // selectedData = "";

    console.log(selectedData, "show");
  }

  function getFavicon(value) {
    return value
      ? value.favIcon
        ? process.env.REACT_APP_STORAGE_URL_2 + brandProfile.favIcon
        : "https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png"
      : "https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png";
  }

  function getImages(value) {
    return value
      ? value.primaryLogo
        ? process.env.REACT_APP_STORAGE_URL_2 + brandProfile.primaryLogo
        : "https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png"
      : "https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png";
  }
  const baseURL = process.env.REACT_APP_STORAGE_URL;
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index"} />
      <Helmet>
        <title>Black-Frames-Studio</title>
        <meta
          name={"description"}
          content={"Web site created using quarkly.io"}
        />
        <link
          rel={"shortcut icon"}
          href={getFavicon(brandProfile)}
          type={"image/x-icon"}
        />
      </Helmet>
      <Section background="--backgroundDark" position="relative" z-index="0">
        <Override
          slot="SectionContent"
          width="100%"
          align-items="center"
          justify-content="center"
          // margin="15px 0 15px 0"
          max-width="100%"
          padding="0px 80px 0px 80px"
          lg-padding="0px 40px 0px 40px"
          className="cust-w100"
        />
        <Reveal>
          <Box
            width="100%"
            padding="16px 16px 0px 16px"
            sm-padding="16px"
            display="flex"
            align-items="center"
            sm-flex-direction="column"
          >
            <Box
              display="flex"
              sm-display="block"
              sm-text-align="center"
              color="white"
              width="100%"
              justify-content="center"
              className="gap-24"
            >
              <NavLink to={"/Home"}>
                <Text color="white">HOME</Text>
              </NavLink>
              <NavLink to={"/About Us"}>
                <Text color="white">ABOUT</Text>
              </NavLink>
              <NavLink to={"/Portfolio"}>
                <Text color="white">PORTFOLIO</Text>
              </NavLink>
              <NavLink to={"/Studio"}>
                <Text color="white">STUDIOS</Text>
              </NavLink>
              <NavLink to={"/Contact Us"}>
                <Text color="white">CONTACT US</Text>
              </NavLink>
            </Box>
          </Box>
          <Box
            min-height="100px"
            display="flex"
            justify-content="space-between"
            // sm-flex-direction="column"
            align-items="center"
            height="max-content"
            width="100%"
          >
            <Box
              empty-border-color="LightGray"
              // flex-direction="column"
              // md-justify-content="space-around"
              display="flex"
              sm-display="block"
              sm-text-align="center"
              padding="0px 16px 16px 16px"
              sm-padding="8px"
              md-width="100%"
              // empty-min-width="64px"
              // empty-min-height="64px"
              // empty-border-width="1px"
              // empty-border-style="solid"
              width="100%"
              align-items="center"
              className="justify-between"
            >
              <Image
                src={getImages(brandProfile)}
                margin="0px 0px 0px 0px"
                sm-margin="0px auto 24px auto"
                max-width="171px"
                height="110px"
              />
              {/* <SocialMedia
                facebook="https://www.facebook.com/blackframesphotos"
              twitter="https://twitter.com/quarklyapp"
                instagram="https://www.instagram.com/blackframes/"
                align-items="flex-start"
                justify-content="flex-start"
                sm-justify-content="center"
               whatsapp="https://web.whatsapp.com/"
              >

           
                <Override
                  slot="link"
                  margin="0 12px 0 0px"
                  hover-background="--color-primary"
                  background="--color-primary"
                  lg-height="28px"
                  lg-padding="4px 4px 4px 4px"
                  lg-display="flex"
                  lg-align-items="center"
                  border-radius="50%"
                  lg-justify-content="center"
                  lg-width="28px"
                  color="--light"
                  width="40px"
                  height="40px"
                >
                  <div display="none !important" />
                </Override>
                <Override slot="icon" lg-font="18px sans-serif" />
                <Override slot="link-telegram">
                  <div display="none !important" />
                </Override>
              </SocialMedia> */}
              <div className="flex justify-center gap-2">
                <a
                  href="https://www.facebook.com/blackframesphotos"
                  target="_blank"
                >
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/Black%20frames%20images/Mini%20Button-3.png"
                    alt="facebook"
                    className="w-10 h-10 "
                  />
                </a>
                <a
                  href="https://www.instagram.com/blackframes/"
                  target="_blank"
                >
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/Black%20frames%20images/Mini%20Button-2.png"
                    alt="instagram"
                    className="w-10 h-10 "
                  />
                </a>
                <a href="mailto:blackframesinfo@gmail.com">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/Black%20frames%20images/Mini%20Button-1.png"
                    alt="mail"
                    className="w-10 h-10 "
                  />
                </a>

                <a href="tel:7710044449">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/Black%20frames%20images/Mini%20Button.png"
                    alt="call"
                    className="w-10 h-10 "
                  />
                </a>
              </div>
              <Box>
                <Text color="white">© 2023-2024, All Rights Reserved</Text>
              </Box>
            </Box>
            {/* <Box
              empty-min-width="64px"
              empty-min-height="64px"
              empty-border-color="LightGray"
              width="25%"
              display="flex"
              md-width="100%"
              md-justify-content="center"
              empty-border-width="1px"
              empty-border-style="solid"
              flex-direction="column"
              padding="16px 16px 16px 16px"
              md-align-items="center"
            >
              <Text
                color="--darkL1"
                font="--subheadlined4"
                margin="0px 0px 20px 0px"
                sm-font="--subheadlined4"
              >
                SHORT LINKS
              </Text>
              <Link
                text-decoration-line="initial"
                transition="opacity 0.3s ease 0s"
                hover-opacity="1"
                margin="0px 0px 10px 0px"
                font="--base"
                href="#"
                color="--darkL1"
                opacity=".8"
                sm-font="--p4"
              >
                Home
              </Link>
              <Link
                opacity=".8"
                text-decoration-line="initial"
                transition="opacity 0.3s ease 0s"
                hover-opacity="1"
                margin="0px 0px 10px 0px"
                font="--base"
                href="#"
                color="--darkL1"
                sm-font="--p4"
              >
                Services
              </Link>
              <Link
                opacity=".8"
                text-decoration-line="initial"
                transition="opacity 0.3s ease 0s"
                hover-opacity="1"
                margin="0px 0px 10px 0px"
                font="--base"
                href="#"
                color="--darkL1"
                sm-font="--p4"
              >
                Screenshots
              </Link>
              <Link
                color="--darkL1"
                opacity=".8"
                text-decoration-line="initial"
                transition="opacity 0.3s ease 0s"
                hover-opacity="1"
                margin="0px 0px 0px 0px"
                font="--base"
                href="#"
                sm-font="--p4"
              >
                Contact
              </Link>
            </Box>
            <Box
              empty-min-height="64px"
              empty-border-color="LightGray"
              width="25%"
              display="flex"
              flex-direction="column"
              md-justify-content="center"
              empty-min-width="64px"
              empty-border-style="solid"
              padding="16px 16px 16px 16px"
              md-width="100%"
              md-align-items="center"
              empty-border-width="1px"
            >
              <Text
                color="--darkL1"
                font="--subheadlined4"
                margin="0px 0px 20px 0px"
                sm-font="--subheadlined4"
              >
                HELP LINK
                <br />
              </Text>
              <Link
                opacity=".8"
                text-decoration-line="initial"
                transition="opacity 0.3s ease 0s"
                hover-opacity="1"
                margin="0px 0px 10px 0px"
                font="--base"
                href="#"
                color="--darkL1"
                sm-font="--p4"
              >
                How to download
              </Link>
              <Link
                text-decoration-line="initial"
                transition="opacity 0.3s ease 0s"
                hover-opacity="1"
                margin="0px 0px 10px 0px"
                font="--base"
                href="#"
                color="--darkL1"
                opacity=".8"
                sm-font="--p4"
              >
                How it works
              </Link>
              <Link
                color="--darkL1"
                opacity=".8"
                text-decoration-line="initial"
                transition="opacity 0.3s ease 0s"
                hover-opacity="1"
                margin="0px 0px 10px 0px"
                font="--base"
                href="#"
                sm-font="--p4"
              >
                Video tutorial
              </Link>
              <Link
                opacity=".8"
                text-decoration-line="initial"
                transition="opacity 0.3s ease 0s"
                hover-opacity="1"
                margin="0px 0px 0px 0px"
                font="--base"
                href="#"
                color="--darkL1"
                sm-font="--p4"
              >
                FAQs
              </Link>
            </Box>
            <Box
              empty-min-height="64px"
              empty-border-style="solid"
              empty-border-color="LightGray"
              width="25%"
              padding="16px 16px 16px 16px"
              md-width="100%"
              empty-min-width="64px"
              empty-border-width="1px"
              display="flex"
              flex-direction="column"
              md-align-items="center"
              md-justify-content="center"
            >
              <Text
                color="--darkL1"
                font="--subheadlined4"
                margin="0px 0px 20px 0px"
                sm-font="--subheadlined4"
              >
                SHORT LINKS
              </Text>
              <Link
                color="--darkL1"
                opacity=".8"
                text-decoration-line="initial"
                hover-opacity="1"
                margin="0px 0px 10px 0px"
                font="--base"
                href="https://play.google.com/store"
                transition="opacity 0.3s ease 0s"
                target="_blank"
                sm-font="--p4"
              >
                Google Play Store
              </Link>
              <Link
                font="--base"
                href="#"
                color="--darkL1"
                opacity=".8"
                text-decoration-line="initial"
                transition="opacity 0.3s ease 0s"
                hover-opacity="1"
                margin="0px 0px 10px 0px"
                sm-font="--p4"
              >
                Apple App Store
              </Link>
              <Link
                hover-opacity="1"
                margin="0px 0px 10px 0px"
                font="--base"
                href="#"
                color="--darkL1"
                opacity=".8"
                text-decoration-line="initial"
                transition="opacity 0.3s ease 0s"
                sm-font="--p4"
              >
                Download Directly
              </Link>
            </Box> */}
          </Box>
        </Reveal>
        <Image
          src="https://uploads.quarkly.io/6459d5984b38c40020065812/images/13802681_5337446-ai%20%281%29.png?v=2023-05-11T06:00:07.833Z"
          display="block"
          position="absolute"
          width="100%"
          height="100%"
          object-fit="cover"
          z-index="-1"
          opacity="0.05"
          filter="invert(500%)"
        />
      </Section>
      {isShow ? (
        <ContentUpdate
          selectedElement={selectedData}
          isShow={isShow}
          closeSidebar={closeSidebar}
        />
      ) : (
        ""
      )}
      <RawHtml>
        <style place={"endOfHead"} rawKey={"6459d5984b38c40020065810"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
};
