import React, { useEffect } from "react";
import Index from "pages/Home";
import About from "pages/About";
import Studio from "pages/Studio";
import Portfolio from "pages/Portfolio";
import Fashion from "pages/Fashion";
import Impressions from "pages/Impressions";
import Contact from "pages/Contact";
import Extra from "pages/extra";
import Services from "pages/Services";
import Page404 from "pages/page404";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import { persistor } from "redux/store";
import { PersistGate } from "redux-persist/integration/react";
import ScrollToTop from "components/ScrollToTop";
import { getCms } from "./redux/CMS/cms-actions";
import { getBrandProfile } from "./redux/BrandDetails/bd-actions";
import { useDispatch, useSelector } from "react-redux/es/exports";

import { setLoading } from "redux/Menu/menu-actions";
const GlobalStyles = createGlobalStyle`
    body {
        margin: 0;
        padding: 0;
        font-family: sans-serif;
    } 
`;

const App = () => {
  const dispatch = useDispatch();
  let project = useSelector((state) => state.userDetails.project);
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);

  useEffect(async () => {
    async function mount() {
      await dispatch(getCms());
      await dispatch(setLoading(true));
      let data = await dispatch(
        getBrandProfile({
          projectId: project._id,
          projectName: project.projectName,
        })
      );
      if (data.code === 200) {
        await dispatch(setLoading(false));
      }
    }
    await mount();
  }, []);
  function applyThemeToDocument() {
    if (brandProfile && brandProfile.hasOwnProperty("primaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-primary",
        brandProfile.primaryColor
      );

    if (brandProfile && brandProfile.hasOwnProperty("secondaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-secondary",
        brandProfile.secondaryColor
      );

    if (brandProfile && brandProfile.hasOwnProperty("tertiaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-tertiary",
        brandProfile.tertiaryColor
      );
  }
  applyThemeToDocument();
  return (
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <GlobalStyles />
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={Index} />
          <Route exact path="/home" component={Index} />
          <Route exact path="/home/:token" component={Index} />
          <Route exact path="/About Us" component={About} />
          <Route exact path="/About Us/:token" component={About} />
          <Route exact path="/Studio" component={Studio} />
          <Route exact path="/Studio/:token" component={Studio} />
          <Route exact path="/portfolio" component={Portfolio} />
          {/* <Route exact path="/Portfolio/:token" component={Portfolio} /> */}
          <Route exact path="/portfolio/:category" component={Fashion} />
          <Route
            exact
            path="/portfolio/:category/:subcategory"
            component={Impressions}
          />
          <Route exact path="/fashion/:token" component={Fashion} />
          <Route exact path="/impressions" component={Impressions} />
          <Route exact path="/impressions/:token" component={Impressions} />
          <Route exact path="/Contact Us" component={Contact} />
          <Route exact path="/Contact Us/:token" component={Contact} />
          <Route exact path="/extra" component={Extra} />
          <Route exact path="/services" component={Services} />
          <Route exact path="/services/:token" component={Services} />
          <Route component={Page404} />
        </Switch>
      </Router>
    </PersistGate>
  );
};
export default App;
