import React, { useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Dropdown from "react-bootstrap/Dropdown";
import { LineWave } from "react-loader-spinner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { getVariations } from "redux/variations/variation-action";

import { useLocation, NavLink } from "react-router-dom";
import { getAllMenu } from "redux/Menu/menu-actions";
import { Button, Image } from "@quarkly/widgets";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { useOverrides, Override } from "@quarkly/components";
import { Section, Span } from "@quarkly/widgets";

// import User from "apis/user";
const defaultProps = {
  background: "--backgroundDark",
  position: "fixed",
  "z-index": "2",
  height: "fit-content",
  "md-align-items": "center",
  "md-justify-content": "center",
  "sm-top": "0px",
  "sm-bottom": "7159.294117647059px",
  "sm-height": "70.70588235294117px",
  "quarkly-title": "Nav",
  color: "--dark",
  "sm-z-index": "2",
  padding: "12px 0px 12px 0px",
};
const overrides = {
  box: {
    kind: "Box",
    props: {
      display: "flex",
      "justify-content": "space-between",
      "align-items": "center",
      height: "max-content",
      "md-align-items": "center",
      "sm-width": "100%",
      "sm-justify-content": "space-between",
      "md-justify-content": "space-between",
    },
  },
  linkBox: {
    kind: "LinkBox",
    props: {
      filter: "invert(0%)",
      href: "/index",
    },
  },
  image: {
    kind: "Image",
    props: {
      display: "block",
      "object-fit": "contain",
      filter: "invert(0)",
      width: "100px",
      height: "70px",
      src: "https://uploads.quarkly.io/649bdc8a8bd7e600185a1390/images/BFwhiteyellow.png?v=2023-07-04T07:36:58.503Z",
    },
  },
  linkBox1: {
    kind: "LinkBox",
    props: {
      "overflow-x": "hidden",
      "overflow-y": "hidden",
      "align-items": "flex-end",
      "active-width": "max-content",
      "visited-width": "max-content",
      "sm-width": "52px",
      "sm-height": "54px",
      "md-width": "9%",
    },
  },
  box1: {
    kind: "Box",
    props: {
      width: "100%",
      display: "flex",
      "justify-content": "flex-end",
      "sm-display": "flex",
      "md-display": "flex",
      "align-items": "center",
      "border-radius": "40px",
      padding: "0px 0px 0px 40px",
      font: "--subheadline5",
      "sm-height": "54px",
      "lg-width": "9%",
      "lg-overflow-x": "hidden",
      "lg-overflow-y": "hidden",
      "md-height": "54px",
      "md-width": "54px",
      color: "--dark",
      background: "--backgroundDark",
    },
  },
  link: {
    kind: "Link",
    props: {
      "text-decoration-line": "initial",
      margin: "0px 40px 0px 0px",
      font: "--subheadline6",
      href: "/about",
      color: "--grey",
      children: (
        <Span font-weight="normal" color="--lightD2">
          ABOUT
        </Span>
      ),
    },
  },
  link1: {
    kind: "Link",
    props: {
      "text-decoration-line": "initial",
      margin: "0px 40px 0px 0px",
      font: "--subheadline6",
      href: "/services",
      color: "--grey",
      children: (
        <Span font-weight="normal" color="--lightD2">
          SERVICES
        </Span>
      ),
      display: "none",
    },
  },
  link2: {
    kind: "Link",
    props: {
      "text-decoration-line": "initial",
      margin: "0px 40px 0px 0px",
      font: "--subheadline6",
      href: "/studio",
      color: "--grey",
      children: (
        <Span font-weight="normal" color="--lightD2">
          STUDIO
        </Span>
      ),
    },
  },
  link3: {
    kind: "Link",
    props: {
      "text-decoration-line": "initial",
      margin: "0px 40px 0px 0px",
      font: "--subheadline6",
      href: "/portfolio",
      color: "--grey",
      children: (
        <Span
          font-weight="normal"
          color="--lightD2"
          overflow-wrap="normal"
          word-break="normal"
          white-space="normal"
          text-indent="0"
          text-overflow="clip"
          hyphens="manual"
          user-select="auto"
          pointer-events="auto"
        >
          PORTFOLIO
        </Span>
      ),
    },
  },
  link4: {
    kind: "Link",
    props: {
      "text-decoration-line": "initial",
      font: "--subheadline6",
      href: "/contact",
      color: "--grey",
      children: (
        <Span font-weight="normal" color="--lightD2">
          CONTACT US
        </Span>
      ),
      margin: "0px 0px 0px 0px",
    },
  },
  linkBox2: {
    kind: "LinkBox",
    props: {
      "active-transform": "--rotate90",
      transform: "rotate(90deg)",
      "sm-height": "54px",
      display: "none",
    },
  },
  icon: {
    kind: "Icon",
    props: {
      category: "md",

      size: "64px",
      color: "--primary",
      transform: "--rotate90",
      "sm-height": "50px",
      "sm-width": "50px",
      "md-width": "54px",
      "md-height": "50% content-box",
    },
  },
};

const NavabarHeader = (props) => {
  let { pathname } = useLocation();
  const [navbar, setnavbar] = useState(0);
  const setclick = () => setnavbar(false);

  const location = useLocation();
  const [currentPath, setCurrentPath] = useState();
  const dispatch = useDispatch();
  // const location = useLocation();
  // const { override, children, rest } = useOverrides(
  //   props,
  //   overrides,
  //   defaultProps
  // );
  let loading = useSelector((state) => state.menu.loading);
  let data = useSelector((state) => state.menu.menu);
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);

  useEffect(() => {
    console.log("Header");
    async function mount() {
      dispatch(getVariations({ pageId: "6299b8fcd3a5d92deb0b908d" }));

      await dispatch(getAllMenu());

      console.log(getAllMenu, "cmsss");
      // const timer = setTimeout(async () => {
      //   await dispatch(setLoading(false));
      // }, 500);

      // return () => clearTimeout(timer);
    }
    setCurrentPath(location.pathname);
    mount();
  }, [dispatch]);

  console.log(data, "menu");
  console.log(currentPath, "path");
  // const renderButton = (props, ref) => {
  //   return (
  //     <button {...props} ref={ref} className="flex ite">
  //       <FeatherIcon icon="user" className="textblack " size="20px" />
  //       <FeatherIcon icon="chevron-down" className="textblack " size="20px" />
  //     </button>
  //   );
  // };

  function getImages(value) {
    return value
      ? value.primaryLogo
        ? process.env.REACT_APP_STORAGE_URL_2 + brandProfile.primaryLogo
        : "https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png"
      : "https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png";
  }

  const { children, rest } = useOverrides(props, overrides, defaultProps);

  return (
    <div className="w-full">
      {loading ? (
        <LineWave
          height="130"
          width="130"
          color="--qtheme-color-primary"
          ariaLabel="line-wave"
          wrapperClass="loader-set"
          wrapperStyle={{}}
          visible={true}
        />
      ) : (
        <Navbar
          expand="lg"
          className="zindex c-fixed flex top-0 w-full bgsecondary"
        >
          <div className="flex justify-between items-center container mx-auto w-full ">
            <div className="">
              <Navbar.Brand href="#" className="text-white flex  items-center">
                <NavLink to={"/Home"}>
                  <Image
                    height="60px"
                    width="145px"
                    src={getImages(brandProfile)}
                  />
                </NavLink>
              </Navbar.Brand>
            </div>
            {/* <div
                className="lg:hidden block text-white"
                aria-controls="basic-navbar-nav"
              >
                <FeatherIcon
                  icon="align-justify"
                  className=" "
                  size="24px"
                  id="navbarScroll"
                />
              </div> */}
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <div className="widthsm">
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav
                  className="me-auto flex items-center nav-pad"
                  style={{ maxHeight: "200px" }}
                  navbarScroll
                >
                  {/* <div className="lg:px-3 flex py-2">
                  <NavLink
                    exact
                    to="/"
                    className="themefamily fsize14 font-bold flex underlino-blck "
                  >
                    Home
                  </NavLink>
                </div> */}
                  {data.map((e, i) => {
                    return (
                      <div className="flex">
                        {e.pageData.length === 1 ? (
                          <div
                            className="lg:px-6 flex py-2 "
                            // onClick={() => this}
                          >
                            <NavLink
                              exact
                              to={`/${e.pageData[0].page}`}
                              className={
                                `/${e.pageData[0].page}` === currentPath
                                  ? "themefamily fsize16 font-semibold text-primary textuperrcase"
                                  : "themefamily fsize16 font-medium text-white textuperrcase"
                              }
                            >
                              {e.menuName}
                            </NavLink>
                          </div>
                        ) : (
                          e.pageData.length > 1 && (
                            <p className="themefamily fsize14 font-bold  underlino-blck">
                              {e.menuName}
                            </p>
                          )
                        )}
                        {e.pageData.length > 1 ? (
                          <div className="flex py-0 new-drop ">
                            <NavDropdown
                              id="basic-navbar-nav"
                              className="flex themefamily fsize14 font-semibold  underlino-blck items-center"
                              menuVariant="black"
                              title={e.menuName}
                            >
                              {e.pageData.map((i) => {
                                return (
                                  <NavDropdown.Item
                                    href="#"
                                    className="themefamily fsize14 font-semibold flex  underlino-blck"
                                  >
                                    <NavLink
                                      exact
                                      to={`/${e.pageData[0].page}`}
                                      className="themefamily fsize16 font-semibold  underlino-blck"
                                    >
                                      {i.page}
                                    </NavLink>
                                  </NavDropdown.Item>
                                );
                              })}
                            </NavDropdown>
                          </div>
                        ) : (
                          ""
                        )}

                        {e.pageData.length === 0 ? (
                          <div className="mega-menu">
                            <NavDropdown
                              className="themefamily fsize16 text-black lg:ml-1 font-semibold flex underlino-blck "
                              title={e.menuName}
                              id="basic-nav-dropdown"
                            >
                              <div className="eventsNav ">
                                <Row>
                                  <Col
                                    xs="12"
                                    md="12"
                                    className="text-left grid grid-cols-4 items-start gap-4 rounded-cancel"
                                  >
                                    {e.subMenu.map((y) => {
                                      return (
                                        <div className="pb-2 borderb">
                                          <Dropdown.Header className="fsize16 py-0 text-danger font-semibold">
                                            {y.menuName}
                                          </Dropdown.Header>
                                          {y.pageData.map((data) => {
                                            return (
                                              <Dropdown.Item className="fsize12 py-1 text-gray font-normal ">
                                                <NavLink
                                                  onClick={setclick}
                                                  exact
                                                  to={`/${y.pageData[0].page}`}
                                                  className="themefamily fsize16 font-semibold  underlino-blck"
                                                >
                                                  {data.name}
                                                </NavLink>{" "}
                                              </Dropdown.Item>
                                            );
                                          })}
                                        </div>
                                      );
                                    })}
                                  </Col>
                                </Row>
                              </div>
                            </NavDropdown>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })}

                  {/* <LinkBox {...override("linkBox1")}>
                      <Box {...override("box1")}>
                        <Link {...override("link")} />
                        <Link {...override("link1")} />
                        <Link {...override("link2")} />
                        <Link {...override("link3")} />
                        <Link {...override("link4")} />
                        <LinkBox {...override("linkBox2")}>
                          <Icon {...override("icon")} />
                        </LinkBox>
                      </Box>
                    </LinkBox> */}
                </Nav>
              </Navbar.Collapse>
            </div>
          </div>
        </Navbar>
      )}
    </div>
  );
};
Object.assign(NavabarHeader, { ...Section, defaultProps, overrides });

export default NavabarHeader;
