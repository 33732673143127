import React, { useEffect, useState } from "react";
import Reveal from "react-reveal/Reveal";
import Slide from "react-reveal/Slide";
import { TypeAnimation } from "react-type-animation";
import theme from "theme";
import { useLocation, NavLink } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { getAllTestimonial } from "redux/testimonial/testimonial-action";
import "swiper/swiper.min.css";
import RecentWork from "components/common/RecentWork";
import QuoteIcon from "../assets/images/QuotesIcon.svg";
import { getAllCategory } from "redux/Product/product-action";
import validator from "validator";
import {
  Theme,
  Text,
  Span,
  Box,
  Image,
  Section,
  Icon,
  Hr,
  LinkBox,
  Input,
  Strong,
  Button,
  Link,
} from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, SocialMedia } from "@quarkly/components";
import * as Components from "components";
import { IoMdHeartEmpty, IoMdShare } from "react-icons/io";

import { useDispatch, useSelector } from "react-redux/es/exports";
import { setSelectedData } from "redux/CMS/cms-actions";
import { useParams } from "react-router-dom";
import ContentUpdate from "../components/CMS/ContentUpdate";

import { createContact } from "redux/marcom/marcom-action";
import { setIsLoadingCat } from "redux/Product/product-action";
import Swal from "sweetalert2/dist/sweetalert2.js";

import "sweetalert2/src/sweetalert2.scss";
import Sharepopup from "./Sharepopup";
import Footer from "./Footer";
import Spinner from "./components/Spinner";
export default () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [errPhone, setErrPhone] = useState("");
  const [errEmail, setErrEmail] = useState("");
  const [errName, setErrName] = useState("");
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [selectedData, updateSelectedData] = useState("update");
  const BrandDetails = useSelector((state) => state.brandDetails.brandProfile);
  const [Loading, setLoading] = useState(false);
  console.log(BrandDetails, "outside in");
  let project = useSelector((state) => state.userDetails.project);
  let data = useSelector((state) => state.cms.pages["Home"]);
  let userData = useSelector((state) => state.cms.userData);
  let productData = useSelector((state) => state.product.product);
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  let testimonalData = useSelector((state) => state.setTestimonal.Testimonal);

  const handleNameChange = (event) => {
    setName(event.target.value);
    if (event.target.value) {
      setErrName("");
    }
  };

  const handlephoneChange = (event) => {
    setPhone(event.target.value);
    if (event.target.value) {
      if (event.target.value.length != 10) {
        if (/^\d*$/.test(event.target.value) === false) {
          setErrPhone("Please enter valid number!");
        } else {
          setErrPhone("Valid only 10 digit!");
        }
      } else {
        if (/^\d*$/.test(event.target.value)) {
          setErrPhone("");
        } else {
          setErrPhone("Please enter valid number!");
        }
      }
    }
  };

  const handleemailChange = (event) => {
    setEmail(event.target.value);
    if (event.target.value) {
      if (!validator.isEmail(event.target.value)) {
        setErrEmail("Invalid email address!");
      } else {
        setErrEmail("");
      }
    } else {
      if (validator.isEmail(event.target.value)) {
        setErrEmail("");
      } else {
        setErrEmail("Please enter email address!");
      }
    }
  };

  const handlemessageChange = (event) => {
    setMessage(event.target.value);
    if (event.target.value) {
      setErrMsg("");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (message == "") {
      setErrMsg("Please enter message!");
      return false;
    } else {
      setErrMsg("");
    }
    if (phone == "") {
      setErrPhone("Please enter phone!");
      return false;
    } else {
      setErrPhone("");
    }
    if (email == "") {
      setErrEmail("Please enter email!");
      return false;
    } else {
      if (!validator.isEmail(email)) {
        setErrEmail("Invalid email address!");
        return false;
      } else {
        setErrEmail("");
      }
    }
    if (name == "") {
      setErrName("Please enter name!");
      return false;
    } else {
      setErrName("");
    }
    let payload = {
      name: name,
      mobile: phone,
      email: email,
      description: message,
      type: "Website",
    };
    if (errName === "" && errEmail === "" && errMsg === "" && errPhone === "") {
      let data = await dispatch(createContact(payload));
      console.log(data, "DATA");

      if (data.code === 200) {
        setEmail("");
        setMessage("");
        setName("");
        setPhone("");
        Swal.fire({
          // title: "",
          text: "Thank you for contacting us.",
          icon: "success",
        });

        console.log(data.code, "registercode");
      } else {
        // alert(data.message);
        Swal.fire({
          title: "Errow",
          text: "Please enter valid credential",
          icon: "error",
        });
        console.log(data.message, "datamessage");
      }
    }
  };
  //-----------hide/show-------
  const { token } = useParams();

  if (token) {
    let cmsStyle = document.getElementsByClassName("hoverText");
    console.log(cmsStyle, "cmsStyle");
    for (let i = 0; i < cmsStyle.length; i++) {
      cmsStyle[i].onClick = function () {
        return false;
      };
      cmsStyle[i].classList.add("hoverText1");
    }
  }
  //----------------hide/show-----------

  useEffect(() => {
    dispatch(getAllTestimonial());
    async function mount() {
      dispatch(setIsLoadingCat(true));
      let data = await dispatch(getAllCategory());
      if (data.code === 200) {
        console.log("====================================");
        console.log(data);
        console.log("====================================");
        dispatch(setIsLoadingCat(false));
      }
      console.log(data, "chex");
    }
    mount();

    console.log(data, "Home");
    setMounted(true);
  }, [dispatch, setMounted]);

  console.log(data, "outside");
  console.log(productData, "hii");

  console.log(testimonalData, "chectest");

  async function isSideBarOpen(id) {
    if (token) {
      await updateSelectedData(id);
      // selectedData = id;
      setIsShow(true);

      console.log(isShow, selectedData, id, "show");
    }
  }

  function closeSidebar(value) {
    setIsShow(value);
    updateSelectedData("");
    // selectedData = "";
    dispatch(setSelectedData({}));
    console.log(selectedData, "show");
  }

  function getFavicon(value) {
    return value
      ? value.favIcon
        ? process.env.REACT_APP_STORAGE_URL_2 + brandProfile.favIcon
        : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/logo.png"
      : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/logo.png";
  }

  const baseURL = process.env.REACT_APP_STORAGE_URL;
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index"} />
      <Helmet>
        <title>Black-Frames-Studio</title>
        <meta
          name={"description"}
          content={"Web site created using quarkly.io"}
        />
        <link
          rel={"shortcut icon"}
          href={getFavicon(brandProfile)}
          type={"image/x-icon"}
        />
      </Helmet>
      {/* <Components.Nav>
        <Override slot="linkBox2" />
        <Override slot="link" />
        <Override slot="link1" />
        <Override slot="link2" />
        <Override slot="link3" />
        <Override slot="link4" />
        <Override slot="box1" />
      </Components.Nav> */}
      <Components.NavabarHeader></Components.NavabarHeader>
      <Section
        color="#000000"
        position="static"
        sm-align-items="center"
        sm-justify-content="center"
        sm-margin-top="70px"
        padding="160px 0 160px 0"
        background="linear-gradient(0deg,rgba(0, 0, 0, 0.7) 1%,rgba(0, 0, 0, 0.7) 100%),rgba(0, 0, 0, 0) url(https://nimbuscluster.blob.core.windows.net/server01/black-frames-studio/blackframes/Black%20frames%20images/home_banner.webp) 0% 0% /cover repeat scroll padding-box"
        id="5416783703"
        className="hoverText cust-mt"
      >
        <Override
          slot="SectionContent"
          position="relative"
          align-items="center"
          justify-content="center"
          max-width="100%"
          width="100%"
          z-index="1"
          sm-align-items="center"
          sm-justify-content="center"
          md-align-items="center"
          md-justify-content="center"
          height="100%"
        />
        <Box
          min-width="100px"
          min-height="100px"
          left="auto"
          right="115px"
          width="80%"
          bottom="auto"
          height="282px"
          top="57px"
          align-items="center"
          display="flex"
          flex-direction="column"
          justify-content="center"
          z-index="1"
          sm-width="90%"
          sm-bottom="auto"
          sm-left="auto"
          sm-right="auto"
          sm-top="87px"
          md-height="max-content"
          md-bottom="auto"
          md-left="auto"
          md-right="75px"
          md-top="144px"
          lg-height="max-content"
          lg-bottom="auto"
          lg-left="auto"
          lg-right="auto"
          lg-top="128px"
        >
          <Text
            margin="0px 0px 6px 0px"
            font="--subheadline5"
            color="--primary"
            letter-spacing="6px"
            sm-font="--p4"
            sm-letter-spacing="1px"
            sm-height="max-content"
            id="2764836648"
            display="none"
          >
            PHOTOGRAPHY | VIDEIOGRAPHY | EDITING
          </Text>
          <Text
            font="--headline2"
            text-align="center"
            color="--darkL1"
            sm-width="100%"
            sm-font="--headline3"
            md-font="--headline3"
            margin="0px 0px 0px 0px"
          >
            <Span
              font-weight="normal"
              font="--headline6"
              sm-font="--headline5"
              letter-spacing="2px"
              className="hoverText cust-w100"
              id="4480571781"
              onClick={() => isSideBarOpen("4480571781")}
            >
              <TypeAnimation
                sequence={[
                  data
                    ? data["4480571781"]
                    : "IN THE PERSUIT OF LIGHT AND SHADOW",
                  1000,
                ]}
                speed={5}
                repeat={Infinity}
              />
            </Span>
          </Text>
        </Box>
        <Box
          width="100%"
          height="450px"
          position="relative"
          align-items="center"
          display="none"
          justify-content="center"
          sm-height="800px"
          md-height="800px"
          background="--backgroundDark"
        >
          <Image
            src="https://images.unsplash.com/photo-1488240339625-c4014e114224?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
            display="none"
            width="100%"
            height="100%"
            object-fit="cover"
            object-position="50% 10%"
            filter="brightness(90%)"
            sm-align-items="center"
            id="4047304062"
          />
        </Box>
      </Section>

      <Section
        padding="80px 0 80px 0"
        md-padding="60px 0 60px 0"
        sm-justify-content="center"
        sm-padding="40px 0px 40px 0px"
        background="rgba(0, 0, 0, 0) url() 0% 0% /auto repeat scroll padding-box"
      >
        <Override
          slot="SectionContent"
          lg-margin="0px 60px 0px 60px"
          md-margin="0px 40px 0px 40px"
          sm-margin="0px 20px 0px 20px"
          sm-width="100%"
        />
        <Box
          display="grid"
          grid-template-columns="repeat(3, 1fr)"
          grid-gap="40px"
          md-grid-gap="28px"
          sm-grid-gap="22px"
          sm-grid-template-columns="repeat(1, 1fr)"
          md-grid-template-columns="repeat(2, 1fr)"
        >
          <LinkBox
            align-items="flex-start"
            height="fit-content"
            sm-grid-column="auto / auto"
            md-grid-column="auto / auto"
            id="9092800619"
            className="cust-w100"
          >
            <Reveal effect="fadeInUp">
              <Box
                height="fit-content"
                width="100%"
                position="relative"
                margin="0px 0px 6px 0px"
                overflow="hidden"
              >
                <Box
                  flex-direction="column"
                  justify-content="center"
                  padding="12px 12px 12px 12px"
                  background="linear-gradient(450deg,rgba(0, 0, 0, 0.45) 0%,rgba(0, 0, 0, 0.45) 100%) 0 0 no-repeat"
                  top="auto"
                  right="auto"
                  bottom="0px"
                  left="0px"
                  z-index="4"
                  position="absolute"
                  width="100%"
                  height="100%"
                  opacity="0"
                  hover-opacity="1"
                  transition="opacity 0.3s ease-in 0s"
                  // display="flex"
                  display="none"
                  align-items="center"
                >
                  <Box display="flex">
                    <Box
                      display="flex"
                      justify-content="center"
                      align-items="center"
                      margin="0px 20px 0px 0px"
                    >
                      <Text
                        margin="0px 4px 0px 0px"
                        font="--subheadline5"
                        color="--darkL1"
                        text-align="left"
                        sm-font="--subheadline6"
                        md-font="--subheadline5"
                        lg-font="--subheadline5"
                        id="5748486445"
                        border-color="--color-secondery"
                        letter-spacing="4px"
                        display="none"
                      >
                        28
                      </Text>
                      <Icon
                        category="io"
                        icon={IoMdHeartEmpty}
                        size="36px"
                        color="--darkL1"
                      />
                    </Box>
                    <Hr
                      margin="0px 20px 0px 0px"
                      display="flex"
                      width="1px"
                      border-width="0 1px 0 0"
                      border-color="#8a8a8a"
                    />
                    <Sharepopup />
                  </Box>
                  <Hr
                    margin="0px 20px 0px 0px"
                    display="flex"
                    width="1px"
                    border-width="0 1px 0 0"
                    border-color="#8a8a8a"
                  />
                  {/* <button data-target="#exampleModalCenter"
                      data-toggle="modal" type="button" className="btn btn-primary"> */}
                  {/* <Icon
                    category="io"
                    icon={IoMdShare}
                    size="36px"
                    color="--darkL1"
                  /> */}

                  {/* </button> */}
                </Box>

                <img
                  src="https://nimbuscluster.blob.core.windows.net/server01/black-frames-studio/blackframes/1.webp"
                  display="block"
                  alt="imagei"
                  className="hoverText imgcss-imp zoom"
                />
              </Box>
            </Reveal>
          </LinkBox>
          <LinkBox
            grid-column="2 / span 2"
            align-items="flex-start"
            height="fit-content"
            sm-grid-column="auto / auto"
            md-grid-column="auto / auto"
            id="9092800619"
            className="cust-w100"
          >
            <Reveal effect="fadeInUp">
              <Box
                height="fit-content"
                width="100%"
                position="relative"
                margin="0px 0px 6px 0px"
                overflow="hidden"
              >
                <Box
                  flex-direction="column"
                  justify-content="center"
                  padding="12px 12px 12px 12px"
                  background="linear-gradient(450deg,rgba(0, 0, 0, 0.45) 0%,rgba(0, 0, 0, 0.45) 100%) 0 0 no-repeat"
                  top="auto"
                  right="auto"
                  bottom="0px"
                  left="0px"
                  z-index="4"
                  position="absolute"
                  width="100%"
                  height="100%"
                  opacity="0"
                  hover-opacity="1"
                  transition="opacity 0.3s ease-in 0s"
                  // display="flex"
                  display="none"
                  align-items="center"
                >
                  <Box display="flex">
                    <Box
                      display="flex"
                      justify-content="center"
                      align-items="center"
                      margin="0px 20px 0px 0px"
                    >
                      <Text
                        margin="0px 4px 0px 0px"
                        font="--subheadline5"
                        color="--darkL1"
                        text-align="left"
                        sm-font="--subheadline6"
                        md-font="--subheadline5"
                        lg-font="--subheadline5"
                        id="5748486445"
                        border-color="--color-secondery"
                        letter-spacing="4px"
                        display="none"
                      >
                        28
                      </Text>
                      <Icon
                        category="io"
                        icon={IoMdHeartEmpty}
                        size="36px"
                        color="--darkL1"
                      />
                    </Box>
                    <Hr
                      margin="0px 20px 0px 0px"
                      display="flex"
                      width="1px"
                      border-width="0 1px 0 0"
                      border-color="#8a8a8a"
                    />
                    <Sharepopup />
                  </Box>
                </Box>
                <img
                  src="https://nimbuscluster.blob.core.windows.net/server01/black-frames-studio/blackframes/2.webp"
                  display="block"
                  alt="imagei"
                  className="hoverText imgcss-imp zoom"
                />
              </Box>
            </Reveal>
          </LinkBox>
          <LinkBox
            grid-column="1 / span 2"
            align-items="flex-start"
            height="fit-content"
            sm-grid-column="auto / auto"
            md-grid-column="auto / auto"
            id="9092800619"
            className="cust-w100"
          >
            <Reveal effect="fadeInUp">
              <Box
                height="fit-content"
                width="100%"
                position="relative"
                margin="0px 0px 6px 0px"
                overflow="hidden"
              >
                <Box
                  flex-direction="column"
                  justify-content="center"
                  padding="12px 12px 12px 12px"
                  background="linear-gradient(450deg,rgba(0, 0, 0, 0.45) 0%,rgba(0, 0, 0, 0.45) 100%) 0 0 no-repeat"
                  top="auto"
                  right="auto"
                  bottom="0px"
                  left="0px"
                  z-index="4"
                  position="absolute"
                  width="100%"
                  height="100%"
                  opacity="0"
                  hover-opacity="1"
                  transition="opacity 0.3s ease-in 0s"
                  // display="flex"
                  display="none"
                  align-items="center"
                >
                  <Box display="flex">
                    <Box
                      display="flex"
                      justify-content="center"
                      align-items="center"
                      margin="0px 20px 0px 0px"
                    >
                      <Text
                        margin="0px 4px 0px 0px"
                        font="--subheadline5"
                        color="--darkL1"
                        text-align="left"
                        sm-font="--subheadline6"
                        md-font="--subheadline5"
                        lg-font="--subheadline5"
                        id="5748486445"
                        border-color="--color-secondery"
                        letter-spacing="4px"
                        display="none"
                      >
                        28
                      </Text>
                      <Icon
                        category="io"
                        icon={IoMdHeartEmpty}
                        size="36px"
                        color="--darkL1"
                      />
                    </Box>
                    <Hr
                      margin="0px 20px 0px 0px"
                      display="flex"
                      width="1px"
                      border-width="0 1px 0 0"
                      border-color="#8a8a8a"
                    />
                    <Sharepopup />
                  </Box>
                </Box>
                <img
                  src="https://nimbuscluster.blob.core.windows.net/server01/black-frames-studio/blackframes/3.webp"
                  display="block"
                  alt="imagei"
                  className="hoverText imgcss-imp zoom"
                />
              </Box>
            </Reveal>
          </LinkBox>
          <LinkBox
            align-items="flex-start"
            height="fit-content"
            sm-grid-column="auto / auto"
            md-grid-column="auto / auto"
            id="9092800619"
            className="cust-w100"
          >
            <Reveal effect="fadeInUp">
              <Box
                height="fit-content"
                width="100%"
                position="relative"
                margin="0px 0px 6px 0px"
                overflow="hidden"
              >
                <Box
                  flex-direction="column"
                  justify-content="center"
                  padding="12px 12px 12px 12px"
                  background="linear-gradient(450deg,rgba(0, 0, 0, 0.45) 0%,rgba(0, 0, 0, 0.45) 100%) 0 0 no-repeat"
                  top="auto"
                  right="auto"
                  bottom="0px"
                  left="0px"
                  z-index="4"
                  position="absolute"
                  width="100%"
                  height="100%"
                  opacity="0"
                  hover-opacity="1"
                  transition="opacity 0.3s ease-in 0s"
                  // display="flex"
                  display="none"
                  align-items="center"
                >
                  <Box display="flex">
                    <Box
                      display="flex"
                      justify-content="center"
                      align-items="center"
                      margin="0px 20px 0px 0px"
                    >
                      <Text
                        margin="0px 4px 0px 0px"
                        font="--subheadline5"
                        color="--darkL1"
                        text-align="left"
                        sm-font="--subheadline6"
                        md-font="--subheadline5"
                        lg-font="--subheadline5"
                        id="5748486445"
                        border-color="--color-secondery"
                        letter-spacing="4px"
                        display="none"
                      >
                        28
                      </Text>
                      <Icon
                        category="io"
                        icon={IoMdHeartEmpty}
                        size="36px"
                        color="--darkL1"
                      />
                    </Box>
                    <Hr
                      margin="0px 20px 0px 0px"
                      display="flex"
                      width="1px"
                      border-width="0 1px 0 0"
                      border-color="#8a8a8a"
                    />
                    <Sharepopup />
                  </Box>
                </Box>
                <img
                  src="https://nimbuscluster.blob.core.windows.net/server01/black-frames-studio/blackframes/4.webp"
                  display="block"
                  alt="imagei"
                  className="hoverText imgcss-imp zoom"
                />
              </Box>
            </Reveal>
          </LinkBox>
          <LinkBox
            align-items="flex-start"
            height="fit-content"
            sm-grid-column="auto / auto"
            md-grid-column="auto / auto"
            id="9092800619"
            className="cust-w100"
          >
            <Reveal effect="fadeInUp">
              <Box
                height="fit-content"
                width="100%"
                position="relative"
                margin="0px 0px 6px 0px"
                overflow="hidden"
              >
                <Box
                  flex-direction="column"
                  justify-content="center"
                  padding="12px 12px 12px 12px"
                  background="linear-gradient(450deg,rgba(0, 0, 0, 0.45) 0%,rgba(0, 0, 0, 0.45) 100%) 0 0 no-repeat"
                  top="auto"
                  right="auto"
                  bottom="0px"
                  left="0px"
                  z-index="4"
                  position="absolute"
                  width="100%"
                  height="100%"
                  opacity="0"
                  hover-opacity="1"
                  transition="opacity 0.3s ease-in 0s"
                  // display="flex"
                  display="none"
                  align-items="center"
                >
                  <Box display="flex">
                    <Box
                      display="flex"
                      justify-content="center"
                      align-items="center"
                      margin="0px 20px 0px 0px"
                    >
                      <Text
                        margin="0px 4px 0px 0px"
                        font="--subheadline5"
                        color="--darkL1"
                        text-align="left"
                        sm-font="--subheadline6"
                        md-font="--subheadline5"
                        lg-font="--subheadline5"
                        id="5748486445"
                        border-color="--color-secondery"
                        letter-spacing="4px"
                        display="none"
                      >
                        28
                      </Text>
                      <Icon
                        category="io"
                        icon={IoMdHeartEmpty}
                        size="36px"
                        color="--darkL1"
                      />
                    </Box>
                    <Hr
                      margin="0px 20px 0px 0px"
                      display="flex"
                      width="1px"
                      border-width="0 1px 0 0"
                      border-color="#8a8a8a"
                    />
                    <Sharepopup />
                  </Box>
                </Box>
                <img
                  src="https://nimbuscluster.blob.core.windows.net/server01/black-frames-studio/blackframes/5.webp"
                  display="block"
                  alt="imagei"
                  className="hoverText imgcss-imp zoom"
                />
              </Box>
            </Reveal>
          </LinkBox>
          <LinkBox
            grid-column="2 / span 2"
            align-items="flex-start"
            height="fit-content"
            sm-grid-column="auto / auto"
            md-grid-column="auto / auto"
            id="9092800619"
            className="cust-w100"
          >
            <Reveal effect="fadeInUp">
              <Box
                height="fit-content"
                width="100%"
                position="relative"
                margin="0px 0px 6px 0px"
                overflow="hidden"
              >
                <Box
                  flex-direction="column"
                  justify-content="center"
                  padding="12px 12px 12px 12px"
                  background="linear-gradient(450deg,rgba(0, 0, 0, 0.45) 0%,rgba(0, 0, 0, 0.45) 100%) 0 0 no-repeat"
                  top="auto"
                  right="auto"
                  bottom="0px"
                  left="0px"
                  z-index="4"
                  position="absolute"
                  width="100%"
                  height="100%"
                  opacity="0"
                  hover-opacity="1"
                  transition="opacity 0.3s ease-in 0s"
                  // display="flex"
                  display="none"
                  align-items="center"
                >
                  <Box display="flex">
                    <Box
                      display="flex"
                      justify-content="center"
                      align-items="center"
                      margin="0px 20px 0px 0px"
                    >
                      <Text
                        margin="0px 4px 0px 0px"
                        font="--subheadline5"
                        color="--darkL1"
                        text-align="left"
                        sm-font="--subheadline6"
                        md-font="--subheadline5"
                        lg-font="--subheadline5"
                        id="5748486445"
                        border-color="--color-secondery"
                        letter-spacing="4px"
                        display="none"
                      >
                        28
                      </Text>
                      <Icon
                        category="io"
                        icon={IoMdHeartEmpty}
                        size="36px"
                        color="--darkL1"
                      />
                    </Box>
                    <Hr
                      margin="0px 20px 0px 0px"
                      display="flex"
                      width="1px"
                      border-width="0 1px 0 0"
                      border-color="#8a8a8a"
                    />
                    <Sharepopup />
                  </Box>
                </Box>
                <img
                  src="https://nimbuscluster.blob.core.windows.net/server01/black-frames-studio/blackframes/6.webp"
                  display="block"
                  alt="imagei"
                  className="hoverText imgcss-imp zoom"
                />
              </Box>
            </Reveal>
          </LinkBox>
          <LinkBox
            grid-column="1 / span 2"
            align-items="flex-start"
            height="fit-content"
            sm-grid-column="auto / auto"
            md-grid-column="auto / auto"
            id="9092800619"
            className="cust-w100"
          >
            <Reveal effect="fadeInUp">
              <Box
                height="fit-content"
                width="100%"
                position="relative"
                margin="0px 0px 6px 0px"
                overflow="hidden"
              >
                <Box
                  flex-direction="column"
                  justify-content="center"
                  padding="12px 12px 12px 12px"
                  background="linear-gradient(450deg,rgba(0, 0, 0, 0.45) 0%,rgba(0, 0, 0, 0.45) 100%) 0 0 no-repeat"
                  top="auto"
                  right="auto"
                  bottom="0px"
                  left="0px"
                  z-index="4"
                  position="absolute"
                  width="100%"
                  height="100%"
                  opacity="0"
                  hover-opacity="1"
                  transition="opacity 0.3s ease-in 0s"
                  // display="flex"
                  display="none"
                  align-items="center"
                >
                  <Box display="flex">
                    <Box
                      display="flex"
                      justify-content="center"
                      align-items="center"
                      margin="0px 20px 0px 0px"
                    >
                      <Text
                        margin="0px 4px 0px 0px"
                        font="--subheadline5"
                        color="--darkL1"
                        text-align="left"
                        sm-font="--subheadline6"
                        md-font="--subheadline5"
                        lg-font="--subheadline5"
                        id="5748486445"
                        border-color="--color-secondery"
                        letter-spacing="4px"
                        display="none"
                      >
                        28
                      </Text>
                      <Icon
                        category="io"
                        icon={IoMdHeartEmpty}
                        size="36px"
                        color="--darkL1"
                      />
                    </Box>
                    <Hr
                      margin="0px 20px 0px 0px"
                      display="flex"
                      width="1px"
                      border-width="0 1px 0 0"
                      border-color="#8a8a8a"
                    />
                    <Sharepopup />
                  </Box>
                </Box>
                <img
                  src="https://nimbuscluster.blob.core.windows.net/server01/black-frames-studio/blackframes/7.webp"
                  display="block"
                  alt="imagei"
                  className="hoverText imgcss-imp zoom"
                />
              </Box>
            </Reveal>
          </LinkBox>
          <LinkBox
            align-items="flex-start"
            height="fit-content"
            sm-grid-column="auto / auto"
            md-grid-column="auto / auto"
            id="9092800619"
            className="cust-w100"
          >
            <Reveal effect="fadeInUp">
              <Box
                height="fit-content"
                width="100%"
                position="relative"
                margin="0px 0px 6px 0px"
                overflow="hidden"
              >
                <Box
                  flex-direction="column"
                  justify-content="center"
                  padding="12px 12px 12px 12px"
                  background="linear-gradient(450deg,rgba(0, 0, 0, 0.45) 0%,rgba(0, 0, 0, 0.45) 100%) 0 0 no-repeat"
                  top="auto"
                  right="auto"
                  bottom="0px"
                  left="0px"
                  z-index="4"
                  position="absolute"
                  width="100%"
                  height="100%"
                  opacity="0"
                  hover-opacity="1"
                  transition="opacity 0.3s ease-in 0s"
                  // display="flex"
                  display="none"
                  align-items="center"
                >
                  <Box display="flex">
                    <Box
                      display="flex"
                      justify-content="center"
                      align-items="center"
                      margin="0px 20px 0px 0px"
                    >
                      <Text
                        margin="0px 4px 0px 0px"
                        font="--subheadline5"
                        color="--darkL1"
                        text-align="left"
                        sm-font="--subheadline6"
                        md-font="--subheadline5"
                        lg-font="--subheadline5"
                        id="5748486445"
                        border-color="--color-secondery"
                        letter-spacing="4px"
                        display="none"
                      >
                        28
                      </Text>
                      <Icon
                        category="io"
                        icon={IoMdHeartEmpty}
                        size="36px"
                        color="--darkL1"
                      />
                    </Box>
                    <Hr
                      margin="0px 20px 0px 0px"
                      display="flex"
                      width="1px"
                      border-width="0 1px 0 0"
                      border-color="#8a8a8a"
                    />
                    <Sharepopup />
                  </Box>
                </Box>
                <img
                  src="https://nimbuscluster.blob.core.windows.net/server01/black-frames-studio/blackframes/8.webp"
                  display="block"
                  alt="imagei"
                  className="hoverText imgcss-imp zoom"
                />
              </Box>
            </Reveal>
          </LinkBox>
        </Box>
      </Section>
      <RecentWork />

      <Section
        align-items="center"
        justify-content="center"
        lg-padding="58px 0 58px 0"
        sm-padding="58px 0px 58px 0px"
        display="none"
      >
        <Override
          slot="SectionContent"
          align-items="center"
          flex-direction="row"
          margin="80px 80px 80px 80px"
          justify-content="space-between"
          sm-flex-direction="column"
          sm-height="max-content"
          md-margin="40px auto 16px auto"
          md-flex-direction="column"
          lg-margin="40px auto 16px auto"
          lg-flex-direction="column"
          sm-margin="40px 20px 16px 20px"
        />
        {/* <Swiper
          slidesPerView={1}
          observer={true}
          observeParents={true}
          modules={[Navigation]}
          className="mySwiper px-8 py-12"
          autoplay={{
            delay: "1000",
          }}
          navigation={{
            nextEl: ".image-swiper-button-next",
            prevEl: ".image-swiper-button-prev",
          }}
          breakpoints={{
            1536: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            1280: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            991: {
              slidesPerView: 1,
          
            },
            640: {
              slidesPerView: 1,
         
            },
            425: {
              slidesPerView: 1,
              
            },
            325: {
              slidesPerView: 1,
             
            },
          }}
        >
          <div class="swiper-button image-swiper-button-prev  rounded-full p-1 cursor-pointer z-10">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
            </svg>
          </div>
          <div class="swiper-button image-swiper-button-next rounded-full p-1 cursor-pointer z-10">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
            </svg>
          </div>
          {testimonalData.result
            ? testimonalData.result.map((testimonial) => {
                return (
                  <SwiperSlide>
                    <Box
                      display="flex"
                      height="650px"
                      position="relative"
                      width="40%"
                      flex-direction="column"
                      align-items="center"
                      margin="0px 40 0px 0px"
                      sm-width="100%"
                      sm-margin="0px 0 24px 0px"
                      sm-height="300px"
                      md-margin="0px 0px 40px 0px"
                      md-width="70%"
                      md-height="550px"
                      lg-width="60%"
                      lg-height="550px"
                      lg-margin="0px 0px 38px 0px"
                      id="4251246912"
                    >
                      <Box
                        width="100%"
                        position="absolute"
                        top="auto"
                        right="auto"
                        bottom="auto"
                        left="auto"
                        height="650px"
                        sm-height="max-content"
                        md-bottom="98.57142857142856px"
                        md-height="551.4285714285714px"
                        md-top="0px"
                        lg-height="552px"
                        lg-bottom="98px"
                        lg-top="0px"
                      >
                        <Box
                          min-height="100px"
                          width="100%"
                          background="#fdfdfd url() 0 0 no-repeat"
                          padding="24px 24px 24px 24px"
                          box-shadow="0 0px 50px -12px rgba(0, 0, 0, 0.25)"
                          position="absolute"
                          transform="rotate(0deg)"
                          bottom="6px"
                          top="0px"
                          left="0px"
                          right="auto"
                          sm-height="342.95px"
                          sm-padding="18px 18px 18px 18px"
                          height="610px"
                          lg-bottom="108.0001220703125px"
                          lg-height="545.9998779296875px"
                          lg-top="0px"
                          sm-bottom="-300px"
                          sm-left="68px"
                          sm-right="auto"
                          sm-top="auto"
                          sm-width="366.75197941706733px"
                        >
                          <Image
                            src={
                              process.env.REACT_APP_STORAGE_URL_2 +
                              testimonial.image
                            }

                            display="block"
                            width="100%"
                            height="500px"
                            object-fit="cover"
                            sm-height="80%"
                            md-height="400px"
                            lg-height="400px"
                            className="hoverText"
                          />
                          <Box
                            min-width="100px"
                            min-height="100px"
                            display="flex"
                            align-items="center"
                            justify-content="center"
                            sm-min-height="max-content"
                          >
                            <Text
                              margin="0px 0px 0px 0px"
                              font="--signatureFont"
                              sm-font="--signatureFont2"
                              sm-margin="16px 0px 16px 0px"
                              sm-padding="0px 0px 0 0px"
                            >
                              <Span
                                font-weight="normal"
                                sm-font="--p2"
                                className="hoverText"
                              >
                                {testimonial.name}
                              </Span>
                            </Text>
                          </Box>
                        </Box>
                        <Box
                          min-height="100px"
                          width="100%"
                          background="#fdfdfd url() 0 0 no-repeat"
                          padding="24px 24px 24px 24px"
                          box-shadow="0 0px 50px -12px rgba(0, 0, 0, 0.25)"
                          position="absolute"
                          transform="rotate(12deg)"
                          bottom="0px"
                          top="auto"
                          left="-10px"
                          right="auto"
                          z-index="-1"
                          sm-display="none"
                          height="648px"
                          lg-bottom="-29px"
                          lg-left="auto"
                          lg-right="-17px"
                          lg-top="auto"
                          lg-height="593.987px"
                          md-bottom="-32px"
                          md-left="auto"
                          md-right="-22px"
                          md-top="auto"
                        >
                          <Image
                            src="https://images.unsplash.com/photo-1535579710123-3c0f261c474e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
                            display="block"
                            width="100%"
                            height="500px"
                            object-fit="cover"
                            md-height="400px"
                            lg-height="400px"
                          />
                          <Box
                            min-width="100px"
                            min-height="100px"
                            display="flex"
                            align-items="center"
                            justify-content="center"
                          >
                            <Text
                              margin="0px 0px 0px 0px"
                              font="--signatureFont"
                            >
                              {testimonial.title}
                            </Text>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      width="50%"
                      margin="0px 0px 0px 100px"
                      sm-width="100%"
                      sm-margin="0px 8px 0px 8px"
                      sm-height="max-content"
                      md-width="80%"
                      md-margin="0px 0px 0px 0px"
                      lg-margin="0px 0px 0px 0"
                      lg-width="80%"
                    >
                      <Icon
                        category="fa"
                        icon="FaQuoteLeft"
                        size="40px"
                        margin="0px 0px 56px 0px"
                        color="--primary"
                        sm-margin="0px 0px 16px 0px"
                        id="2787951992"
                        border-color="#ffd000"
                      />
                      <Text
                        margin="0px 0px 40px 0px"
                        font="--p2"
                        color="--dark"
                        sm-font="--p5"
                        sm-margin="0px 0px 16px 0px"
                        id="4903090226"
                        className="hoverText"
                      >
                        {testimonial.testimonial}
                      </Text>
                      <Box
                        display="flex"
                        sm-align-items="center"
                        sm-justify-content="center"
                        md-align-items="center"
                        md-justify-content="center"
                      >
                        <Icon
                          category="fi"
                          icon="FiArrowLeftCircle"
                          size="40px"
                          margin="0px 16px 0px 0px"
                          color="--lightD2"
                          sm-margin="0px 8px 0px 0px"
                          id="1381364429"
                          className="hoverText"
                        />
                        <Icon
                          category="fi"
                          icon="FiArrowRightCircle"
                          size="40px"
                          color="--primary"
                          border-color="#ffd000"
                          id="9203685316"
                          className="hoverText"
                        />
                      </Box>
                    </Box>
                  </SwiperSlide>
                );
              })
            : "no-data"}
        </Swiper> */}
        {/* <Box
          display="flex"
          height="650px"
          position="relative"
          width="40%"
          flex-direction="column"
          align-items="center"
          margin="0px 40 0px 0px"
          sm-width="100%"
          sm-margin="0px 0 24px 0px"
          sm-height="300px"
          md-margin="0px 0px 40px 0px"
          md-width="70%"
          md-height="550px"
          lg-width="60%"
          lg-height="550px"
          lg-margin="0px 0px 38px 0px"
          id="4251246912"
        >
          <Box
            width="100%"
            position="absolute"
            top="auto"
            right="auto"
            bottom="auto"
            left="auto"
            height="650px"
            sm-height="max-content"
            md-bottom="98.57142857142856px"
            md-height="551.4285714285714px"
            md-top="0px"
            lg-height="552px"
            lg-bottom="98px"
            lg-top="0px"
          >
            <Box
              min-height="100px"
              width="100%"
              background="#fdfdfd url() 0 0 no-repeat"
              padding="24px 24px 24px 24px"
              box-shadow="0 0px 50px -12px rgba(0, 0, 0, 0.25)"
              position="absolute"
              transform="rotate(0deg)"
              bottom="6px"
              top="0px"
              left="0px"
              right="auto"
              sm-height="342.95px"
              sm-padding="18px 18px 18px 18px"
              height="648px"
              lg-bottom="108.0001220703125px"
              lg-height="545.9998779296875px"
              lg-top="0px"
              sm-bottom="-300px"
              sm-left="68px"
              sm-right="auto"
              sm-top="auto"
              sm-width="366.75197941706733px"
            >
              <Image
                src="https://images.unsplash.com/photo-1535579710123-3c0f261c474e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
                display="block"
                width="100%"
                height="500px"
                object-fit="cover"
                sm-height="80%"
                md-height="400px"
                lg-height="400px"
                className="hoverText"
              />
              <Box
                min-width="100px"
                min-height="100px"
                display="flex"
                align-items="center"
                justify-content="center"
                sm-min-height="max-content"
              >
                <Text
                  margin="0px 0px 0px 0px"
                  font="--signatureFont"
                  sm-font="--signatureFont2"
                  sm-margin="16px 0px 16px 0px"
                  sm-padding="0px 0px 0 0px"
                >
                  <Span
                    font-weight="normal"
                    sm-font="--p2"
                    className="hoverText"
                  >
                    KARISHMA PAHADI
                  </Span>
                </Text>
              </Box>
            </Box>
            <Box
              min-height="100px"
              width="100%"
              background="#fdfdfd url() 0 0 no-repeat"
              padding="24px 24px 24px 24px"
              box-shadow="0 0px 50px -12px rgba(0, 0, 0, 0.25)"
              position="absolute"
              transform="rotate(12deg)"
              bottom="0px"
              top="auto"
              left="-10px"
              right="auto"
              z-index="-1"
              sm-display="none"
              height="648px"
              lg-bottom="-29px"
              lg-left="auto"
              lg-right="-17px"
              lg-top="auto"
              lg-height="593.987px"
              md-bottom="-32px"
              md-left="auto"
              md-right="-22px"
              md-top="auto"
            >
              <Image
                src="https://images.unsplash.com/photo-1535579710123-3c0f261c474e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
                display="block"
                width="100%"
                height="500px"
                object-fit="cover"
                md-height="400px"
                lg-height="400px"
              />
              <Box
                min-width="100px"
                min-height="100px"
                display="flex"
                align-items="center"
                justify-content="center"
              >
                <Text margin="0px 0px 0px 0px" font="--signatureFont">
                  Karishma pahadi
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          width="50%"
          margin="0px 0px 0px 100px"
          sm-width="100%"
          sm-margin="0px 8px 0px 8px"
          sm-height="max-content"
          md-width="80%"
          md-margin="0px 0px 0px 0px"
          lg-margin="0px 0px 0px 0"
          lg-width="80%"
        >
          <Icon
            category="fa"
            icon="FaQuoteLeft"
            size="40px"
            margin="0px 0px 56px 0px"
            color="--primary"
            sm-margin="0px 0px 16px 0px"
            id="2787951992"
            border-color="#ffd000"
          />
          <Text
            margin="0px 0px 40px 0px"
            font="--p2"
            color="--dark"
            sm-font="--p5"
            sm-margin="0px 0px 16px 0px"
            id="4903090226"
            className="hoverText"
          >
            WORKING WITH  KEVIN WAS AN ABSOLUTE DELIGHT. AS A WOMAN, I FELT
            INSTANTLY COMFORTABLE AND EMPOWERED DURING THE SESSION.
            [PHOTOGRAPHER'S NAME] CAPTURED MY ESSENCE BEAUTIFULLY, CREATING
            STUNNING IMAGES THAT REFLECT MY UNIQUE BEAUTY AND PERSONALITY. I
            HIGHLY RECOMMEND THEIR PHOTOGRAPHY SERVICES FOR AN UNFORGETTABLE
            EXPERIENCE!
          </Text>
          <Box
            display="flex"
            sm-align-items="center"
            sm-justify-content="center"
            md-align-items="center"
            md-justify-content="center"
          >
            <Icon
              category="fi"
              icon="FiArrowLeftCircle"
              size="40px"
              margin="0px 16px 0px 0px"
              color="--lightD2"
              sm-margin="0px 8px 0px 0px"
              id="1381364429"
              className="hoverText"
            />
            <Icon
              category="fi"
              icon="FiArrowRightCircle"
              size="40px"
              color="--primary"
              border-color="#ffd000"
              id="9203685316"
              className="hoverText"
            />
          </Box>
        </Box> */}
      </Section>
      {/* <Section
        align-items="center"
        justify-content="center"
        lg-padding="58px 0 58px 0"
        sm-padding="58px 0px 58px 0px"
      >
        <Override
          slot="SectionContent"
          align-items="center"
          margin="80px 80px 80px 80px"
          sm-height="max-content"
          md-margin="40px auto 16px auto"
          lg-margin="40px auto 16px auto"
          sm-margin="40px 20px 16px 20px"
          className="cust-w100"
        />
        <Reveal>
          <div className="txt-center w-full mb-4">
            <Text font="--subheadline2" color="--dark">
              Testimonials
            </Text>
            <Text color="--grey" font="--subheadline5" className="font-normal">
              What customers say about us
            </Text>
          </div>
        </Reveal>
        <Reveal>
          <Swiper
            slidesPerView={3}
            observer={true}
            observeParents={true}
            modules={[Navigation]}
            className="mySwiper"
            autoplay={{
              delay: "1000",
            }}
            loop
            navigation={{
              nextEl: ".image-swiper-button-next",
              prevEl: ".image-swiper-button-prev",
            }}
            breakpoints={{
              1536: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1280: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              991: {
                slidesPerView: 2.5,
                // spaceBetween: 30,
              },
              640: {
                slidesPerView: 2.5,
                // spaceBetween: 30,
              },
              425: {
                slidesPerView: 1.2,
                // spaceBetween: 50,
              },
              325: {
                slidesPerView: 1.2,
                // spaceBetween: 50,
              },
            }}
          >
            <div class="swiper-button image-swiper-button-prev  rounded-full p-1 cursor-pointer z-10">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div class="swiper-button image-swiper-button-next rounded-full p-1 cursor-pointer z-10">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>
            {testimonalData.result
              ? testimonalData.result.map((testimonial) => {
                  return (
                    <SwiperSlide>
                      <div className="m-2 py-4 px-4 elshadow rounded-lg w-full">
                        <img
                          src={QuoteIcon}
                          alt="quote"
                          className="w-12 h-12 mx-auto mb-2"
                        />
                        <Text
                          className="pb-4 my-0 text-center lh-28px"
                          font="--p2"
                          color="#9191919e"
                          sm-font="--p5"
                        >
                          {testimonial.testimonial}
                        </Text>
                        <div className="hr"></div>
                        <div className="mt-3 text-center">
                          <div className="img mx-auto  text-center border-c8c8c861 w-20 h-20 rounded-full">
                            <img
                              src={
                                process.env.REACT_APP_STORAGE_URL_2 +
                                testimonial.image
                              }
                              alt=""
                              className="w-20 h-20 rounded-full"
                            />
                          </div>
                          <div>
                            <Text
                              className="my-2"
                              color="--grey"
                              font="--subheadline5"
                            >
                              {testimonial.name}
                            </Text>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })
              : "no-data"}
          </Swiper>
        </Reveal>
      </Section> */}
      <Section
        background="--backgroundDark"
        position="relative"
        z-index="0"
        align-items="center"
        justify-content="center"
        sm-padding="0px"
      >
        <Override
          slot="SectionContent"
          align-items="center"
          justify-content="center"
          margin="40px 0 40px 0"
          max-width="100%"
          padding="0px 0px 0px 0px"
          md-margin="40px 20px 40px 20px"
        />
        <Box
          min-height="100px"
          display="flex"
          justify-content="space-between"
          sm-flex-direction="column"
          align-items="center"
          width="70%"
          md-width="100%"
          className="cust-w100"
        >
          <Reveal>
            <Box
              width="100%"
              sm-width="100%"
              display="flex"
              flex-direction="column"
            >
              <Text
                margin="0px 0px 38px 0px"
                font="--subheadline4"
                color="--darkL1"
                padding="0px 0px 0 0px"
                letter-spacing="4px"
                sm-font="--subheadline4"
                md-font="--subheadline4"
                id="5186761521"
                className="hoverText uppercase"
                text-align="center"
                onClick={() => isSideBarOpen("5186761521")}
              >
                {data ? data["5186761521"] : "CONNECT US"}
              </Text>
              <Box
                display="flex"
                align-items="center"
                margin="0 0 42px 0"
                sm-margin="0px 0px 16px 0px"
                lg-margin="0px 0px 24px 0px"
              >
                <Box display="content" width="100%">
                  <Input
                    display="block"
                    placeholder-color="rgba(211, 211, 211, 0.41)"
                    width="100%"
                    margin="0px 0px 0px 0px"
                    height="60px"
                    sm-height="40px"
                    border-width="0 0 1px 0"
                    border-style="solid"
                    placeholder="FULL NAME"
                    id="3174789627"
                    border-color="--color-darkL1"
                    background="rgba(255, 255, 255, 0)"
                    sm-font="--base1"
                    font="--base"
                    color="--darkL1"
                    value={name}
                    onChange={handleNameChange}
                  />
                  {errName === "" ? (
                    ""
                  ) : (
                    <div
                      style={{
                        color: "red",
                        margin: "0",

                        padding: "0",
                      }}
                      className="errClass"
                    >
                      {errName}
                    </div>
                  )}
                </Box>
              </Box>
              <Box
                display="flex"
                align-items="center"
                margin="0 0 42px 0"
                sm-display="flex"
                sm-flex-direction="column"
                sm-margin="0px 0px 16px 0px"
                lg-margin="0px 0px 24px 0px"
              >
                <Box display="content" width="100%">
                  <Input
                    placeholder-color="rgba(211, 211, 211, 0.41)"
                    width="100%"
                    margin="0px 24px 0px 0px"
                    height="60px"
                    sm-height="40px"
                    border-width="0 0 1px 0"
                    border-style="solid"
                    placeholder="PHONE NUMBER"
                    display="flex"
                    sm-font="--base1"
                    sm-margin="0px 0 16px 0px"
                    id="4868732435"
                    border-color="--color-darkL1"
                    background="rgba(255, 255, 255, 0)"
                    color="--darkL1"
                    value={phone}
                    onChange={handlephoneChange}
                  />
                  {errPhone === "" ? (
                    ""
                  ) : (
                    <div
                      style={{
                        color: "red",
                        margin: "0",

                        padding: "0",
                      }}
                      className="errClass"
                    >
                      {errPhone}
                    </div>
                  )}
                </Box>
                <Box display="block" width="100%">
                  <Input
                    display="block"
                    placeholder-color="rgba(211, 211, 211, 0.41)"
                    width="100%"
                    height="60px"
                    sm-height="40px"
                    border-width="0 0 1px 0"
                    border-style="solid"
                    placeholder="EMAIL "
                    id="2926016427"
                    border-color="--color-darkL1"
                    background="rgba(255, 255, 255, 0)"
                    color="--darkL1"
                    sm-font="--base1"
                    value={email}
                    type="email"
                    onChange={handleemailChange}
                  />
                  {errEmail === "" ? (
                    ""
                  ) : (
                    <div
                      style={{
                        color: "red",
                        margin: "0",

                        padding: "0",
                      }}
                      className="errClass"
                    >
                      {errEmail}
                    </div>
                  )}
                </Box>
              </Box>
              <Box
                display="flex"
                align-items="center"
                margin="0px 0px 48px 0px"
                lg-margin="0px 0px 24px 0px"
                md-margin="0px 0px 60px 0px"
                sm-margin="0px 0px 40px 0px"
              >
                <Input
                  display="block"
                  placeholder-color="rgba(211, 211, 211, 0.41)"
                  width="100%"
                  margin="0px 0px 0px 0px"
                  height="60px"
                  sm-height="40px"
                  border-width="0 0 1px 0"
                  border-style="solid"
                  placeholder="ENTER MESSAGE"
                  id="5791018233"
                  border-color="--color-darkL1"
                  background="rgba(255, 255, 255, 0)"
                  color="--darkL1"
                  sm-font="--base1"
                  value={message}
                  required="true"
                  onChange={handlemessageChange}
                />
                {errMsg === "" ? (
                  ""
                ) : (
                  <div
                    style={{
                      position: "absolute",
                      color: "red",
                      margin: "0",
                      marginTop: "100px",
                      padding: "0",
                    }}
                    className="errClass"
                  >
                    {errMsg}
                  </div>
                )}
              </Box>

              <Box display="flex" align-items="center" justify-content="center">
                <Button
                  Id="5748094947"
                  color="--dark"
                  font="--p2"
                  padding="20px 24px 20px 24px"
                  sm-font="--bt2"
                  sm-display="flex"
                  sm-flex-direction="row"
                  sm-margin="0px 0px 0px 0px"
                  md-display="block"
                  width="24%"
                  md-padding="16px 24px 16px 24px"
                  sm-align-items="center"
                  sm-justify-content="center"
                  className="hoverText skinButton uppercase"
                  id="9076209734"
                  lg-padding="16px 24px 16px 24px"
                  sm-padding="8px 12px 8px 12px"
                  background="--color-primary"
                  lg-width="50%"
                  // onClick={() => isSideBarOpen("9076209734")}
                  onClick={handleSubmit}
                >
                  <Strong>{data ? data["9076209734"] : "ENQUIRE NOW"}</Strong>
                </Button>
              </Box>
            </Box>
          </Reveal>
        </Box>
        <Image
          src="https://uploads.quarkly.io/6459d5984b38c40020065812/images/13802681_5337446-ai%20%281%29.png?v=2023-05-11T06:00:07.833Z"
          display="none"
          position="absolute"
          width="100%"
          height="100%"
          object-fit="cover"
          z-index="-1"
          opacity="0.05"
          filter="invert(500%)"
        />
      </Section>
      <Footer />
      {isShow ? (
        <ContentUpdate
          selectedElement={selectedData}
          isShow={isShow}
          closeSidebar={closeSidebar}
        />
      ) : (
        ""
      )}
      <RawHtml>
        <style place={"endOfHead"} rawKey={"6459d5984b38c40020065810"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
};
